/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

const Select = ({
  name,
  value,
  onChange,
  children,
  caption,
  columnWidth,
  className,
  inputRef,
}) => {
  const colWidth = `col-md-${columnWidth}`;
  return (
    <div className={colWidth}>
      <div className="form-group">
        <label className="control-label" htmlFor={name}>
          {caption}
        </label>
        <select
          title={caption}
          onChange={onChange}
          value={value}
          name={name}
          className={`form-control ${className}`}
          ref={inputRef}
        >
          {children}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  caption: PropTypes.string,
  columnWidth: PropTypes.number.isRequired,
  inputRef: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
};

Select.defaultProps = {
  caption: '',
  inputRef: null,
  className: '',
  name: '',
};

export default Select;

import UserConstants from '../constants/user';

let initialState = {};
if (typeof Storage !== 'undefined') {
  const userObject = window.localStorage.getItem('nyumbani');
  if (userObject) {
    initialState = JSON.parse(userObject);
  }
}

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case UserConstants.LOGIN_REQUEST:
      return {
        user: action.user,
      };
    case UserConstants.LOGIN_SUCCESS:
      return {
        user: action.user,
      };
    case UserConstants.LOGIN_FAILURE:
      return {};
    case UserConstants.LOGOUT:
      return {};
    default:
      return state;
  }
};

export default authentication;

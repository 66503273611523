/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';

import Currency from '../../data/Currency';
import Platforms from '../../data/Platforms';
import BankCodes from '../../data/BankCodes';
import UpdateModal from '../Modals/UpdateModal';
import IziToast from '../IziToast';
import TextInput from '../TextInput';
import Select from '../Select';
import CheckBox from '../CheckBox';
import FilePreview from '../FileUploader/FilePreview';

const UpdateProperty = ({ requestConfig, reLoadTable, selectedProperty }) => {
  // state
  const bankNameRef = React.useRef(null);
  const bankCodeRef = React.useRef(null);
  // state
  const [bankAccountName, setBankAccountName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankTelephoneNumber, setBankTelephoneNumber] = useState("");
  const [isEquityAccount, setIsEquityAccount] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [propertyCode, setPropertyCode] = useState('');
  const [description, setDescription] = useState('');
  const [paymentPlatform, setPaymentPlatform] = useState(Platforms[0].id);
  const [locationAddress, setLocationAddress] = useState('');
  const [propertyUnits, setPropertyUnits] = useState('');
  const [currency, setCurrency] = useState(Currency[0].id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentFile, setCurrentFile] = useState('');

  // copying state from props is anti-pattern but in our case we're not relying on props update
  useEffect(() => {
    if (selectedProperty) {
      let currentAccount = '';
      let currentPlatform = Platforms[0].id;
      let currentBankName = '';
      let currentBankAccountNumber = '';
      let currentBankCode = '';
      let currentBankPhone = '';
      let currentBankIsEquityAccount = false;
      if (selectedProperty.tenant_payment_details) {
        if (selectedProperty.tenant_payment_details[0].payment_info) {
          currentPlatform =
            selectedProperty.tenant_payment_details[0].payment_info.platform;
          currentAccount =
            selectedProperty.tenant_payment_details[0].payment_info.account;
        }
      }

      if (selectedProperty.landlord_payment_details) {
        if (selectedProperty.landlord_payment_details[0].payment_info) {
          currentBankName =
            selectedProperty.landlord_payment_details[0].payment_info.bank_name;
          currentBankAccountNumber =
            selectedProperty.landlord_payment_details[0].payment_info.account;
          currentBankCode =
            selectedProperty.landlord_payment_details[0].payment_info.code;
          currentBankPhone =
            selectedProperty.landlord_payment_details[0].payment_info.phone;
          currentBankIsEquityAccount =
            selectedProperty.landlord_payment_details[0].payment_info.is_equity_account;
        }
      }

      setPropertyName(selectedProperty.name);
      setPropertyCode(selectedProperty.property_code);
      setDescription(selectedProperty.description);
      setLocationAddress(selectedProperty.address);
      setPropertyUnits(selectedProperty.units);
      setAccountNumber(currentAccount);
      setPaymentPlatform(currentPlatform);
      setCurrency(
        selectedProperty.other_details &&
        selectedProperty.other_details.currency,
      );
      setBankAccountName(currentBankName);
      setBankAccountNumber(currentBankAccountNumber);
      setBankCode(currentBankCode);
      setBankTelephoneNumber(currentBankPhone);
      setIsEquityAccount(currentBankIsEquityAccount);
      if (selectedProperty.images) {
        const { images } = selectedProperty;
        if (images && images.length) {
          setCurrentFile(images[0].link);
        }
      }
    }
  }, [selectedProperty]);

  const updateProperty = async () => {
    setIsSubmitting(true);

    let isValid = false;
    let message;
    if (
      propertyName &&
      propertyCode &&
      description &&
      propertyUnits &&
      locationAddress &&
      currency &&
      accountNumber &&
      bankAccountName &&
      bankAccountNumber &&
      bankCode &&
      bankTelephoneNumber
    ) {
      isValid = true;
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (isValid) {
      const paymentType = 'rent';
      const tenantPaymentInfo = [
        {
          payment_info: { account: accountNumber, platform: paymentPlatform },
          payment_type: paymentType,
        },
      ];
      const landlordPaymentInfo = [
        {
          payment_info: {
            account: bankAccountNumber,
            platform: 'pesalink',
            bank_name: bankAccountName,
            code: bankCode,
            phone: bankTelephoneNumber,
            is_equity_account: isEquityAccount,
          },
          payment_type: paymentType,
        },
      ];
      const formData = new FormData();
      formData.append('id', selectedProperty.id);
      formData.append('name', propertyName);
      formData.append('property_code', propertyCode);
      formData.append('description', description);
      formData.append('address', locationAddress);
      formData.append('units', propertyUnits);
      formData.append('other_details', `{ "currency": "${currency}" }`);
      formData.append('tenant_payment_details', JSON.stringify(tenantPaymentInfo));
      formData.append('landlord_payment_details', JSON.stringify(landlordPaymentInfo));
      // attach image if any
      if (currentFile && typeof currentFile === 'object') {
        formData.append(currentFile.name, currentFile, currentFile.name);
      }

      IziToast('Updating...');
      try {
        const {
          data: { response },
        } = await axios.put(
          `${process.env.REACT_APP_API_URL}properties`,
          formData,
          requestConfig,
        );
        if (response.propertyUpdated.id) {
          IziToast('Property successfully updated');
          $('#propertyModal').modal('hide');
          if (reLoadTable) reLoadTable();
        }
        setIsLoading(!isLoading);
      } catch (error) {
        if (error) {
          if (error.response) {
            if (error.response.data) {
              IziToast(error.response.data.error);
            }
          } else {
            IziToast(error.message);
          }
        } else {
          IziToast('An error occurred');
        }
      }
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const { type /* name */ /* size */ } = file;
      const validTypes = ['image/jpeg', 'image/png'];
      if (validTypes.includes(type)) {
        /**
         * TODO : validate duplicate upload
         * message : 'Looks like you have already uploaded this image'
         */
        setCurrentFile(file);
      } else {
        IziToast('Only .jpeg and .png files allowed');
      }
    }
  };

  return (
    <UpdateModal
      modalId="propertyModal"
      onClick={updateProperty}
      header="Update property details"
    >
      <div className="row">
        <div className="col-xs-3 bm-responsive-div">
          <div className="row">
            <FilePreview
              currentFile={currentFile}
              onChange={onFileChange}
              inputId="imgUpdateProperty"
              onFileRemove={() => {
                setCurrentFile('');
              }}
              columnWidth={12}
            />
          </div>
        </div>
        <div className="col-xs-9 bm-responsive-div">
          <div className="row">
            <TextInput
              type="text"
              placeholder="Enter property name"
              caption="Property Name"
              onChange={(event) => {
                setPropertyName(event.target.value);
              }}
              value={propertyName}
              columnWidth={12}
              className={isSubmitting && !propertyName ? 'error' : ''}
            />
          </div>
          <div className="row">
            <TextInput
              type="text"
              placeholder="Enter property code"
              caption="Property Code"
              onChange={(event) => {
                setPropertyCode(event.target.value);
              }}
              value={propertyCode}
              columnWidth={6}
              className={isSubmitting && !propertyCode ? 'error' : ''}
            />
          </div>
          <div className="row">
            <TextInput
              type="text"
              placeholder="Enter property description"
              caption="Property Description"
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              value={description}
              columnWidth={12}
              className={isSubmitting && !description ? 'error' : ''}
            />
          </div>
          <div className="row">
            <TextInput
              type="text"
              placeholder="Enter area, town"
              caption="Area, Town"
              onChange={(event) => {
                setLocationAddress(event.target.value);
              }}
              value={locationAddress}
              columnWidth={12}
              className={isSubmitting && !locationAddress ? 'error' : ''}
            />
          </div>
          <div className="row">
            <TextInput
              type="text"
              placeholder="Enter units"
              caption="Units"
              onChange={(e) => {
                const value = /^[0-9\b]+$/;
                // allows number only
                if (e.target.value === '' || value.test(e.target.value)) {
                  setPropertyUnits(e.target.value);
                }
              }}
              value={propertyUnits}
              columnWidth={4}
              className={isSubmitting && !propertyUnits ? 'error' : ''}
            />
            <Select
              caption="Currency"
              value={currency}
              onChange={(event) => {
                setCurrency(event.target.value);
              }}
              columnWidth={4}
              className={isSubmitting && !currency ? 'error' : ''}
            >
              {Currency.map((item) => {
                return <option key={item.id}>{item.name}</option>;
              })}
            </Select>
          </div>
          <div className="row">
            <Select
              caption="Payment Platform"
              value={paymentPlatform}
              onChange={(event) => {
                setPaymentPlatform(event.target.value);
              }}
              columnWidth={4}
              className={isSubmitting && !paymentPlatform ? 'error' : ''}
            >
              {Platforms.map((item) => {
                return <option key={item.id}>{item.name}</option>;
              })}
            </Select>
            <TextInput
              type="text"
              placeholder="Enter paybill number"
              caption="Paybill Number"
              onChange={(e) => {
                const value = /^[0-9\b]+$/;
                // allows number only
                if (e.target.value === '' || value.test(e.target.value)) {
                  setAccountNumber(e.target.value);
                }
              }}
              value={accountNumber}
              columnWidth={8}
              className={isSubmitting && !accountNumber ? 'error' : ''}
            />
          </div>
          <div className="row">
            <Select
              caption="Enter bank account name"
              value={bankAccountName}
              onChange={(event) => {
                setBankAccountName(event.target.value);
              }}
              columnWidth={6}
              className={isSubmitting && !bankCode ? 'error' : ''}
              inputRef={bankNameRef}
            >
              <option value="">Select bank account name</option>
              {BankCodes.map((item) => {
                return (
                  <option value={item.name} key={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
            <Select
              caption="Bank Code"
              value={bankCode}
              onChange={(event) => {
                setBankCode(event.target.value);
              }}
              columnWidth={6}
              className={isSubmitting && !bankCode ? 'error' : ''}
              inputRef={bankCodeRef}
            >
              <option value="">Select bank code</option>
              {BankCodes.map((item) => {
                return (
                  <option value={item.code} key={item.code}>
                    { `${item.code} - ${item.name}`}
                  </option>
                );
              })}
            </Select>
            <TextInput
              type="text"
              placeholder="Enter bank account number"
              caption="Bank Account Number"
              onChange={(e) => {
                const value = /^[0-9\b]+$/;
                // allows number only
                if (e.target.value === '' || value.test(e.target.value)) {
                  setBankAccountNumber(e.target.value);
                }
              }}
              value={bankAccountNumber}
              columnWidth={6}
              className={isSubmitting && !bankAccountNumber ? 'error' : ''}
            />
            <TextInput
              type="text"
              placeholder="Enter telephone number linked to account"
              caption="Linked Telephone Number"
              onChange={(e) => {
                setBankTelephoneNumber(e.target.value);
              }}
              value={bankTelephoneNumber}
              columnWidth={6}
              className={isSubmitting && !bankTelephoneNumber ? 'error' : ''}
            />
            <div className="col-md-6">
              <label className="control-label" htmlFor="">
                Is it an Equity Bank account?
                <div
                  className="row"
                  style={{ marginLeft: '3px', paddingTop: '10px' }}
                >
                  <CheckBox
                    caption="YES"
                    checked={isEquityAccount}
                    onClick={() => {
                      setIsEquityAccount(true);
                    }}
                  />
                  <CheckBox
                    caption="NO"
                    checked={!isEquityAccount}
                    onClick={() => {
                      setIsEquityAccount(!isEquityAccount);
                    }}
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </UpdateModal>
  );
};

UpdateProperty.propTypes = {
  requestConfig: PropTypes.objectOf(PropTypes.object),
  selectedProperty: PropTypes.oneOfType([PropTypes.object]),
  reLoadTable: PropTypes.func.isRequired,
};

UpdateProperty.defaultProps = {
  requestConfig: {},
  selectedProperty: {},
};

export default UpdateProperty;

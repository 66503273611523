import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const StatsTile = ({ icon, amount, caption, bodyText }) => {
  return (
    <div className="col-lg-3">
      <div className="hpanel">
        <div className="panel-body h-200">
          <h1 className="tile-amount">{amount}</h1>
          <div className="card-row row">
            <h1 className="pull-left card-body-text">{bodyText}</h1>
            <div className="pull-right">
              <i className={`pe-7s-${icon} fa-4x`} />
            </div>
          </div>
          <div className="row card-row card-footer">
            <span className="stats-tile-caption">{caption}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

StatsTile.propTypes = {
  icon: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  caption: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
};

export default StatsTile;

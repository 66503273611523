const Currency = [
  {
    id: 'KES',
    name: 'KES',
  },
  {
    id: 'USD',
    name: 'USD',
  },
];

export default Currency;

import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  type,
  disabled,
  onClick,
  caption,
  cssClass,
  isBlack,
  minWidth = 200,
}) => {
  const buttonColor = isBlack ? '#333333' : '#FD6360';
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      className={`btn nyumbani_button ${cssClass}`}
      disabled={disabled}
      onClick={onClick}
      style={{
        backgroundColor: buttonColor,
        minWidth: `${minWidth}px`,
        minHeight: '40px',
      }}
    >
      {caption}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  caption: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  isBlack: PropTypes.bool,
  minWidth: PropTypes.number,
};

Button.defaultProps = {
  disabled: false,
  onClick: undefined,
  cssClass: '',
  isBlack: true,
  minWidth: 200,
};

export default Button;

import AlertConstants from '../constants/alert';

const success = (message) => {
  return {
    type: AlertConstants.SUCCESS,
    message,
  };
};

const error = (message) => {
  return {
    type: AlertConstants.ERROR,
    message,
  };
};

const info = (message) => {
  return {
    type: AlertConstants.INFO,
    message,
  };
};

const clear = () => {
  return {
    type: AlertConstants.CLEAR,
  };
};

const AlertActions = {
  success,
  error,
  info,
  clear,
};

export default AlertActions;

const YesNo = [
  {
    id: 'Yes',
    name: 'YES',
  },
  {
    id: 'No',
    name: 'NO',
  },
];

export default YesNo;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import $ from 'jquery';

const Collapsible = ({ children, caption, columnWidth, controlId }) => {
  const anchorRef = useRef(null);
  const panelId = `panel_${controlId}`;
  const colWidth = `col-lg-${columnWidth}`;

  function showHidePanel() {
    if (anchorRef) {
      const icon = anchorRef.current.firstChild;
      // Toggle icon from up to down
      if (icon) {
        icon.classList.toggle('fa-chevron-up');
        icon.classList.toggle('fa-chevron-down');
      }
    }
    const hpanel = $(`#${panelId}`);
    const footer = hpanel.find('div.panel-footer');
    const body = hpanel.find('div.panel-body');
    body.slideToggle(300);
    footer.slideToggle(200);
    hpanel.toggleClass('').toggleClass('panel-collapse');
    window.setTimeout(() => {
      hpanel.resize();
      hpanel.find('[id^=map-]').resize();
    }, 50);
  }

  return (
    <div className={colWidth}>
      <div className="hpanel" id={panelId}>
        <div className="panel-heading">
          <div className="panel-tools">
            <a ref={anchorRef} className="showhide" onClick={showHidePanel}>
              <i className="fa fa-chevron-up" />
            </a>
          </div>
          {caption}
        </div>
        <div className="panel-body">{children}</div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  caption: PropTypes.string,
  columnWidth: PropTypes.number,
  controlId: PropTypes.string.isRequired,
};

Collapsible.defaultProps = {
  caption: '',
  children: '',
  columnWidth: 3,
};

export default Collapsible;

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const CheckBox = ({ caption, checked, onClick }) => {
  return (
    /*eslint-disable */
    <label className="checkbox-container">
      {caption}
      <input type="checkbox" checked={checked} onChange={onClick} />
      <span className="checkmark" />
    </label>
  );
};

CheckBox.propTypes = {
  caption: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

CheckBox.defaultProps = {
  caption: '',
  checked: false,
  onClick: undefined,
};

export default CheckBox;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DropDown = ({ caption, children, name, isActive, onClick }) => {
  const [active, setActive] = useState(false);
  const ulStyle = isActive
    ? 'nav nav-second-level in'
    : 'nav nav-second-level collapse';
  return (
    <li
      title={name}
      className={active || isActive ? 'active main-menu' : 'main-menu'}
    >
      <a
        href={null}
        name={name}
        onClick={() => {
          setActive(!active);
          onClick();
        }}
        aria-expanded={isActive}
      >
        <span className="nav-label">{caption}</span>
        <span className="fa arrow menu-toggle" />
      </a>
      <ul aria-expanded={isActive} className={ulStyle}>
        {children}
      </ul>
    </li>
  );
};

DropDown.propTypes = {
  caption: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

DropDown.defaultProps = {
  isActive: false,
};

export default DropDown;

const bankCodes = [
  {
    code: "01",
    name: "Kenya Commercial Bank(KCB)",
  },
  {
    code: "02",
    name: "Standard Chartered Bank",
  },
  {
    code: "03",
    name: "Barclays Bank of Kenya (BBK)",
  },
  {
    code: "07",
    name: "Commercial Bank of Africa(CBA)",
  },
  {
    code: 10,
    name: "Prime Bank",
  },
  {
    code: 11,
    name: "Co-operative Bank of Kenya",
  },
  {
    code: 12,
    name: "National Bank",
  },
  {
    code: 16,
    name: "Citibank",
  },
  {
    code: 17,
    name: "Habib A.G Zurich Bank",
  },
  {
    code: 18,
    name: "Middle East Bank",
  },
  {
    code: 19,
    name: "Bank of Africa",
  },
  {
    code: 23,
    name: "Consolidated Bank",
  },
  {
    code: 25,
    name: "Credit Bank",
  },
  {
    code: 31,
    name: "Stanbic Bank",
  },
  {
    code: 35,
    name: "ABC Bank (Out of service until further notice)",
  },
  {
    code: 41,
    name: "NIC Bank",
  },
  {
    code: 49,
    name: "Spire Bank (Out of service until further notice)",
  },
  {
    code: 50,
    name: "Paramount Universal",
  },
  {
    code: 51,
    name: "Jamii Bora Bank",
  },
  {
    code: 53,
    name: "Guaranty Trust Bank",
  },
  {
    code: 54,
    name: "Victoria Bank (Out of service until further notice)",
  },
  {
    code: 55,
    name: "Guardian Bank",
  },
  {
    code: 57,
    name: "I&M Bank",
  },
  {
    code: 63,
    name: "Diamond Trust Bank",
  },
  {
    code: 66,
    name: "Sidian Bank",
  },
  {
    code: 68,
    name: "Equity Bank",
  },
  {
    code: 70,
    name: "Family Bank",
  },
  {
    code: 72,
    name: "Gulf African Bank",
  },
  {
    code: 74,
    name: "First Community Bank",
  },
  {
    code: 78,
    name: "KWFT",
  },
  {
    code: 14,
    name: "M ORIENTAL BANK (Pending Approval)",
  },
  {
    code: 43,
    name: "ECO BANK  (Pending Approval)",
  },
  {
    code: 61,
    name: "HOUSING FINANCE (HF)  (Pending Approval)",
  },
  {
    code: 65,
    name: "MAYFAIR BANK (Pending Approval)",
  },
  {
    code: 75,
    name: "DIB BANK (Pending Approval)",
  },
  {
    code: 99,
    name: "POST BANK (Pending Approval)",
  },
];

export default bankCodes;

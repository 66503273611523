import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import IziToast from '../IziToast';
import Master from '../Master';
import TextInput from '../TextInput';
import Select from '../Select';
import Button from '../Buttons';
import FilePreview from '../FileUploader/FilePreview';
import Paragraph from '../Paragraph';
import DatePicker from '../DatePicker';
import { ValidateEmail } from '../../utils/Functions';

const NewTenant = ({ user, location: { property, source } }) => {
  // get details from local storage
  if (typeof Storage !== 'undefined') {
    if (!property) {
      const propStr = window.localStorage.getItem('property');
      const sourceStr = window.localStorage.getItem('source');
      // eslint-disable-next-line no-param-reassign
      if (propStr) property = JSON.parse(propStr);
      // eslint-disable-next-line no-param-reassign
      if (sourceStr) source = sourceStr;
    }
  }
  const history = useHistory();
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  // refs
  const propertyRef = useRef(null);
  // state
  const [unitNumber, setUnitNumber] = useState('');
  const [unitId, setUnitId] = useState('');
  const [phone, setPhone] = useState('');
  const [idOrPassport, setIdOrPassport] = useState('');
  const [nationality, setNationality] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentFile, setCurrentFile] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [units, setUnits] = useState([]);
  const propertyName = property ? property.name : '';
  const [startDate, setStartDate] = useState(new Date());
  const [email, setEmail] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const where = [{ property_id: property.id, status: 'available' }];
        const url = `${process.env.REACT_APP_API_URL
          }units?where=${JSON.stringify(where)}`;
        const {
          data: { response },
        } = await axios.get(url, requestConfig);
        setUnits(response);
      } catch (error) {
        //
      }
    })();
  }, []);

  const saveTenant = async () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (
      unitId &&
      firstName &&
      lastName &&
      phone &&
      nationality &&
      idOrPassport &&
      startDate &&
      unitNumber
    ) {
      if (email !== '') {
        isValid = ValidateEmail(email);
        message = 'Enter a valid email address';
        if(!isValid) { IziToast(message) };
      } else {
        isValid = true;
      }
      if (phone.length !== 12) {
        message = 'Enter a valid phone number';
        IziToast(message);
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (isValid) {
      const otherDetails = {
        unit_number: unitNumber,
      };
      const stayDuration = 'N/A';
      const formData = new FormData();
      formData.append('unit_id', unitId);
      formData.append('id_or_passport', idOrPassport);
      formData.append('phone', `+${phone}`);
      formData.append('last_name', lastName);
      formData.append('first_name', firstName);
      formData.append(
        'start_date',
        Math.round(new Date(startDate).getTime() / 1000.0),
      );
      formData.append('nationality', nationality);
      formData.append('other_details', JSON.stringify(otherDetails));
      formData.append('stay_duration', stayDuration);
      formData.append('email', email);
      // attach image if any
      if (currentFile) {
        formData.append('idphoto', currentFile, currentFile.name);
      }
      const formConfig = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      IziToast('Saving...');
      try {
        const {
          data: { response },
        } = await axios.post(
          `${process.env.REACT_APP_API_URL}tenants`,
          formData,
          formConfig,
        );
        if (response.id) {
          IziToast('Tenant successfully saved');
          resetFields();
        }
        setIsLoading(!isLoading);
      } catch (error) {
        if (error) {
          if (error.response) {
            if (error.response.data) {
              IziToast(error.response.data.error);
            }
          } else {
            IziToast(error.message);
          }
        } else {
          IziToast('An error occurred');
        }
      }
    }
  };

  const resetFields = () => {
    setUnitNumber('');
    setUnitId('');
    setPhone('');
    setFirstName('');
    setLastName('');
    setIdOrPassport('');
    setNationality('');
    setIsSubmitting(false);
    setCurrentFile(null);
    setStartDate(new Date());
    setEmail('');
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const { type /* name */ /* size */ } = file;
      const validTypes = ['image/jpeg', 'image/png'];
      if (validTypes.includes(type)) {
        /**
         * TODO : validate duplicate upload
         * message : 'Looks like you have already uploaded this image'
         */
        setCurrentFile(file);
      } else {
        IziToast('Only .jpeg and .png files allowed');
      }
    }
  };

  return (
    <Master
      title="Property Management"
      redirectLink="newtenant"
      source="Add New Tenant"
    >
      <div className="col-xs-3 bm-responsive-div">
        <FilePreview
          currentFile={currentFile}
          onChange={onFileChange}
          inputId="imgTenant"
          onFileRemove={() => {
            setCurrentFile(null);
          }}
          columnWidth={12}
          caption="Upload ID/Passport Image"
        />
        {propertyName && (
          <Paragraph
            columnWidth={12}
            content={propertyName || ''}
            caption="Property Name"
          />
        )}
      </div>
      <div className="col-xs-9 bm-responsive-div">
        <div className="row">
          <Select
            caption="Select Unit Description"
            value={unitId}
            onChange={(event) => {
              setUnitId(event.target.value);
            }}
            columnWidth={8}
            className={isSubmitting && !unitId ? 'error' : ''}
            inputRef={propertyRef}
          >
            <option value="">Select unit description</option>
            {units.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {`${item.type} - ${item.description}`}
                </option>
              );
            })}
          </Select>
          <TextInput
            type="text"
            placeholder="Enter unit number"
            caption="Unit Number"
            onChange={(e) => {
              setUnitNumber(e.target.value);
            }}
            value={unitNumber}
            columnWidth={4}
            className={isSubmitting && !unitNumber ? 'error' : ''}
          />
        </div>
        <div className="row">
          <TextInput
            type="text"
            placeholder="Enter first name"
            caption="First Name"
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
            value={firstName}
            columnWidth={4}
            className={isSubmitting && !firstName ? 'error' : ''}
          />
          <TextInput
            type="text"
            placeholder="Enter last name"
            caption="Last Name"
            onChange={(event) => {
              setLastName(event.target.value);
            }}
            value={lastName}
            columnWidth={4}
            className={isSubmitting && !lastName ? 'error' : ''}
          />
          <TextInput
            type="text"
            placeholder="254712345678"
            caption="Telephone Number (254712345678)"
            pattern="[0-9]*"
            onChange={(e) => {
              const numbers = /^[0-9\b]+$/;
              const { value } = e.target;
              // allows number only
              if (value === '' || numbers.test(value)) {
                setPhone(value);
              }
            }}
            value={phone}
            columnWidth={4}
            className={isSubmitting && phone.length !== 12 ? 'error' : ''}
          />
        </div>
        <div className="row">
          <TextInput
            type="text"
            placeholder="Enter ID/Passport number"
            caption="ID/Passport Number"
            onChange={(e) => {
              // const value = /^[0-9\b]+$/;
              // if (e.target.value === '' || value.test(e.target.value)) {
              setIdOrPassport(e.target.value);
              // }
            }}
            value={idOrPassport}
            columnWidth={4}
            className={isSubmitting && !idOrPassport ? 'error' : ''}
          />
          <TextInput
            type="text"
            placeholder="Enter nationality"
            caption="Nationality"
            onChange={(e) => {
              setNationality(e.target.value);
            }}
            value={nationality}
            columnWidth={4}
            className={isSubmitting && !nationality ? 'error' : ''}
          />
          <TextInput
            type="email"
            placeholder="Enter email"
            caption="Email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            value={email}
            name="email"
            columnWidth={4}
          />
        </div>
        <div className="row">
          <DatePicker
            placeholder="Select start date"
            caption="Start Date"
            onChange={(date) => {
              return setStartDate(date);
            }}
            columnWidth={4}
            selected={startDate}
          />
        </div>
      </div>
      <div className="right">
        <Button
          caption="<< Back To Tenants"
          cssClass="btn-success"
          onClick={() => {
            history.push({
              pathname: source,
              property,
            });
            if (typeof Storage !== 'undefined') {
              window.localStorage.removeItem('property');
            }
          }}
          type="button"
          isBlack
        />
        <Button
          caption="Create New Tenant"
          cssClass="btn-success"
          onClick={saveTenant}
          type="button"
          isBlack={false}
        />
      </div>
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

NewTenant.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

NewTenant.defaultProps = {
  user: {},
  location: {},
};

export default connect(mapStateToProps)(NewTenant);

import iziToast from 'izitoast';

const iziResendInvoice = (message, onSMS, onEmail, onCancel) => {
  iziToast.question({
    timeout: false,
    close: false,
    overlay: true,
    displayMode: 'once',
    id: 'question',
    zindex: 999,
    title: '',
    message,
    position: 'center',
    progressBar: false,
    buttons: [
      [
        '<button>SMS</button>',
        (instance, toast) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          onSMS();
        },
        true,
      ],
      [
        '<button>Email</button>',
        (instance, toast) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          onEmail();
        },
        false,
      ],
      [
        '<button>Cancel</button>',
        (instance, toast) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        },
        false,
      ],
    ],
  });
};

export default iziResendInvoice;

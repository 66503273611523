import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import $ from 'jquery';

import Master from '../Master';
import Select from '../Select';
import TextInput from '../TextInput';
import IziToast from '../IziToast';
import Button from '../Buttons';
import Collapsible from '../Panels/Collapsible';
import ChargesTable from './ChargesTable';
import { FormatDate, DestroyTable } from '../../utils/Functions';
import CheckBox from '../CheckBox';

const ExtraCharge = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };

  //   refs
  const chargeRef = useRef(null);
  const amountRef = useRef(null);
  const tenantRef = useRef(null);
  const propertyRef = useRef(null);

  // state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [chargeAmount, setChargeAmount] = useState('');
  const [tenants, setTenants] = useState([]);
  const [tenantId, setTenantId] = useState('');
  const [units, setUnits] = useState([]);
  const [extraCharges, setExtraCharges] = useState([]);
  const [extraChargesList, setExtraChargesList] = useState([]);
  const [chargeId, setChargeId] = useState('');
  const [description, setDescription] = useState('');
  const [charges, setCharges] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [propertyId, setPropertyId] = useState('');
  const [tenantsList, setTenantsList] = useState([]);
  const [chargeList, setChargeList] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendSms, setSendSms] = useState(false);

  useEffect(() => {
    DestroyTable('charges_table');
    if (propertyId) {
      const filtered = tenantsList.filter((item) => {
        return item.unit.property_id === propertyId;
      });
      setTenants(filtered);
      const transaction = chargeList.filter((item) => {
        if (item && item.property) {
          return item.property.id === propertyId;
        }
        return undefined;
      });
      setCharges(transaction);
    } else {
      setTenants(tenantsList);
      setCharges(chargeList);
    }
    setTenantId('');
  }, [propertyId]);

  useEffect(() => {
    let urlProperty = `/byuser?status=active&user_id=${user.id}`;
    if (user && user.type === 'admin') {
      urlProperty = '?take=1000&where={"status":"active"}&order={"name":"ASC"}';
    }
    (async () => {
      try {
        // get properties
        const {
          data: { response: property },
        } = await axios.get(
          `${process.env.REACT_APP_API_URL}properties${urlProperty}`,
          requestConfig,
        );
        setProperties(property);
        $(propertyRef.current)
          .select2()
          .on('change', (event) => {
            setPropertyId(event.val);
          });
        // get units
        const propertyIds = property.map((item) => {
          return item.id;
        });
        let urlUnits;
        if (user.type === 'admin') {
          urlUnits = `${process.env.REACT_APP_API_URL}units?take=10000`;
        } else {
          urlUnits = `${
            process.env.REACT_APP_API_URL
          }units?take=10000&where={"property_id":"in::${propertyIds.join()}"}`;
        }
        const {
          data: { response: unitsInfo },
        } = await axios.get(urlUnits, requestConfig);
        setUnits(unitsInfo);
        // get tenats
        const unitIds = unitsInfo.map((item) => {
          return item.id;
        });
        let urlTenants;
        if (user.type === 'admin') {
          urlTenants = `${process.env.REACT_APP_API_URL}tenants?take=10000`;
        } else {
          urlTenants = `${
            process.env.REACT_APP_API_URL
          }tenants?take=10000&where={"unit_id":"in::${unitIds.join()}"}`;
        }
        const {
          data: { response: tenantsInfo },
        } = await axios.get(urlTenants, requestConfig);
        const searchUnit = (id) => {
          return unitsInfo.find((item) => {
            return item.id === id;
          });
        };
        const mappedTenants = tenantsInfo.map((item) => {
          const element = item;
          element.unit = searchUnit(element.unit_id);
          return element;
        });
        setTenants(mappedTenants);
        setTenantsList(mappedTenants);
        $(tenantRef.current)
          .select2()
          .on('change', (event) => {
            setTenantId(event.val);
          });
        // get extra charges
        let urlCharges;
        if (user.type === 'admin') {
          urlCharges = `${process.env.REACT_APP_API_URL}paymentcodes?take=1000&order={"name":"ASC"}`;
        } else {
          urlCharges = `${
            process.env.REACT_APP_API_URL
          }paymentcodes?take=1000&where={"property_id":"in::${propertyIds.join()}"}&order={"name":"ASC"}`;
        }
        const {
          data: { response: exCharges },
        } = await axios.get(urlCharges, requestConfig);
        const mapped = exCharges.map((item) => {
          const element = item;
          element.unit = searchUnit(element.unit_id);
          return element;
        });
        setExtraCharges(mapped);
        setExtraChargesList(mapped);
        $(chargeRef.current)
          .select2()
          .on('change', (event) => {
            setChargeId(event.val);
          });
        // charges table
        loadCharges(property, tenantsInfo);
      } catch (error) {
        //
      }
    })();
  }, []);

  useEffect(() => {
    if (tenantId) {
      const tenant = tenants.find((item) => {
        return item.id === tenantId;
      });
      const unit = units.find((item) => {
        return item.id === tenant.unit_id;
      });
      const propId = unit.property_id;
      const charge = extraChargesList.filter((item) => {
        return item.property_id === propId;
      });
      setExtraCharges(charge);
    } else {
      setExtraCharges(extraChargesList);
    }
  }, [tenantId]);

  /**
   *
   * @param {[]} propertyInfo
   * @param {[]} tenantsInfo
   */
  async function loadCharges(propertyInfo, tenantsInfo) {
    setIsLoading(true);
    try {
      let filter;
      if (user.type === 'admin') {
        filter = '"status":"unpaid","invoice_type":"charges"';
      } else {
        const propertyIds = propertyInfo.map((item) => {
          return item.id;
        });
        filter = `"status":"unpaid","invoice_type":"charges","payee_id":"in::${propertyIds.join()}"`;
      }
      const urlInvoices = `${process.env.REACT_APP_API_URL}invoices?where={${filter}}`;
      const {
        data: { response },
      } = await axios.get(urlInvoices, requestConfig);
      if (response) {
        const mapped = response.map((elem) => {
          const element = elem;
          element.tenant = findTenant(element.payer_id);
          element.property = findProperty(element.payee_id);
          return element;
        });
        groupCharges(mapped);
        setIsLoading(false);
      }
    } catch (error) {
      IziToast('An error occurred');
      setHasError(true);
      setIsLoading(false);
    }
    function findProperty(id) {
      return propertyInfo.find((property) => {
        return property.id === id;
      });
    }

    function findTenant(id) {
      return tenantsInfo.find((item) => {
        return item.id === id;
      });
    }
  }

  function groupCharges(records) {
    setCharges(records);
    setChargeList(records);
  }

  const saveCharges = async () => {
    setIsSubmitting(true);
    let message;
    if (!tenantId) {
      message = 'Select tenant details';
      IziToast(message);
      tenantRef.current.focus();
      return;
    }
    if (!chargeId) {
      message = 'Select extra charge details';
      IziToast(message);
      chargeRef.current.focus();
      return;
    }
    if (!chargeAmount || !parseFloat(chargeAmount) > 0) {
      message = 'Enter extra charge amount';
      amountRef.current.focus();
      IziToast(message);
      return;
    }
    const tenant = tenants.find((item) => {
      return item.id === tenantId;
    });
    const unit = units.find((item) => {
      return item.id === tenant.unit_id;
    });
    const propId = unit.property_id;
    const chargeName = extraCharges.find((item) => {
      return item.id === chargeId;
    });
    // check if it exists
    const isFound = charges.find((item) => {
      return item.payer_id === tenantId;
    });
    if (isFound) {
      // update
    }
    const changeLog = [
      {
        user: {
          id: user.id,
          type: user.type,
        },
        action: 'Creating invoice',
        timestamp: Math.round(new Date().getTime() / 1000.0),
      },
    ];
    const referenceData = {
      info: chargeName.name || '',
      deduct: false,
      description,
      payment_code: chargeName.code,
    };
    const amount = parseFloat(chargeAmount);
    const chargeData = [
      {
        data: { amount },
        type: chargeName.name || '',
      },
    ];
    const chargesObject = {
      payer_id: tenantId,
      payee_id: propId,
      payer_type: 'tenant',
      payee_type: 'property',
      invoice_type: 'charges',
      invoice_date: FormatDate(new Date()),
      total_amount: amount,
      amount_due: amount,
      balance: amount,
      status: 'unpaid',
      include_charges: false,
      interval: 'month',
      send_email: sendEmail,
      reference_data: referenceData,
      changelog: changeLog,
      send_sms: sendSms,
      charges: chargeData,
    };
    IziToast('Saving...');
    try {
      const {
        data: { response },
      } = await axios.post(
        `${process.env.REACT_APP_API_URL}invoices`,
        chargesObject,
        requestConfig,
      );
      if (response.id) {
        IziToast('Extra charge successfully saved');
        resetFields();
      }
      onReload();
    } catch (error) {
      if (error) {
        if (error.response) {
          if (error.response.data) {
            IziToast(error.response.data.error);
          }
        }
      } else {
        IziToast('An error occurred');
      }
    }
  };

  const resetFields = () => {
    setTenantId('');
    setChargeId('');
    setChargeAmount('');
    setIsSubmitting(false);
    setDescription('');
  };

  function onReload() {
    loadCharges(properties, tenants);
  }

  return (
    <Master redirectLink="extracharge" title="Finance" source="Extra Charges">
      <div className="row">
        <Select
          caption="Property Name"
          value={propertyId}
          onChange={(event) => {
            setPropertyId(event.target.value);
          }}
          columnWidth={3}
          inputRef={propertyRef}
        >
          <option value="">Select property name</option>
          {properties.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </Select>
        <Select
          caption="Tenant"
          value={tenantId}
          onChange={(e) => {
            setTenantId(e.target.value);
          }}
          columnWidth={4}
          className={isSubmitting && !tenantId ? 'error' : ''}
          inputRef={tenantRef}
        >
          <option value="">Select tenant</option>
          {tenants.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {`${item.first_name} ${item.last_name} | ${item.other_details ? item.other_details.unit_number : '--' }`}
              </option>
            );
          })}
        </Select>
        <Select
          caption="Extra Charge Name"
          value={chargeId}
          onChange={(e) => {
            setChargeId(e.target.value);
          }}
          columnWidth={3}
          className={isSubmitting && !chargeId ? 'error' : ''}
          inputRef={chargeRef}
        >
          <option value="">Select charge name</option>
          {extraCharges.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </Select>
        <TextInput
          type="text"
          placeholder="Enter amount"
          caption="Amount"
          onChange={(e) => {
            const pattern = /^[0-9\b]+$/;
            const { value } = e.target;
            // allows number only
            if (value === '' || pattern.test(value)) {
              setChargeAmount(value);
            }
          }}
          value={chargeAmount}
          columnWidth={2}
          className={
            (isSubmitting && !chargeAmount) || parseFloat(chargeAmount <= 0)
              ? 'error'
              : undefined
          }
          inputRef={amountRef}
        />
      </div>
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter description (optional)"
          caption="Description (optional)"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          value={description}
          columnWidth={7}
        />
        <div className="col-md-3" style={{ marginTop: '30px' }}>
          <CheckBox
            caption="Send Email"
            checked={sendEmail}
            onClick={() => {
              setSendEmail(!sendEmail);
            }}
          />
          <CheckBox
            caption="Send SMS"
            checked={sendSms}
            onClick={() => {
              setSendSms(!sendSms);
            }}
          />
        </div>
        <div className="col-md-2 inline-right-button">
          <Button
            caption="Create Charges"
            cssClass="btn-success"
            onClick={saveCharges}
            type="button"
            isBlack={false}
            minWidth={190}
          />
        </div>
      </div>
      {properties.length > 0 && tenants.length > 0 && (
        <Collapsible
          caption="Charges List"
          columnWidth={12}
          controlId="charges_list"
        >
          <ChargesTable
            isLoading={isLoading}
            requestConfig={requestConfig}
            onReload={onReload}
            charges={charges}
            hasError={hasError}
          />
        </Collapsible>
      )}
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

ExtraCharge.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

ExtraCharge.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(ExtraCharge);

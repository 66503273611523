/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Navigation = ({ caption, navigation, name, isActive, onClick }) => {
  return (
    <li
      onClick={onClick}
      title={name}
      className={isActive ? 'active main-menu' : 'main-menu'}
    >
      <Link to={navigation} name={name}>
        <span className="nav-label">{caption}</span>
      </Link>
    </li>
  );
};

Navigation.propTypes = {
  caption: PropTypes.string.isRequired,
  name: PropTypes.string,
  navigation: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  name: 'success',
  isActive: false,
};

export default Navigation;

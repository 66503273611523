/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import TextInput from '../TextInput';
import AlertActions from '../../actions/alert';
import Button from '../Buttons';
import AlertMessage from '../Alert';
import history from '../../helpers/history';

const PasswordReset = ({ dispatch, alert, location: { email, tokenMode } }) => {
  //   state
  const [resetToken, setResetToken] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirm, setConfirm] = useState('');

  const tokenRef = useRef(null);

  if (typeof Storage !== 'undefined') {
    if (!tokenMode || !email) {
      const tokenStr = window.localStorage.getItem('resetToken');
      if (tokenStr) {
        const { email: _email, tokenMode: _tokenMode } = JSON.parse(tokenStr);
        /* eslint-disable */
        email = _email;
        tokenMode = _tokenMode;
        /* eslint-enable */
      }
    }
  }

  useEffect(() => {
    if (tokenMode) {
      dispatch(
        AlertActions.info(`Enter reset token received via ${tokenMode}`),
      );
    }
  }, [dispatch]);

  function clearLocalStorage() {
    if (typeof Storage !== 'undefined') {
      window.localStorage.removeItem('resetToken');
    }
  }

  const onPasswordReset = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!resetToken) {
      dispatch(
        AlertActions.error(`Enter reset token received via ${tokenMode}`),
      );
      tokenRef.current.focus();
    } else if (!password) {
      dispatch(AlertActions.error('Enter new password'));
    } else if (!confirm) {
      dispatch(AlertActions.error('Confirm new password'));
    } else if (password !== confirm) {
      dispatch(AlertActions.error('New password not matching confirmation'));
    } else {
      dispatch(AlertActions.info('Verifying token...'));
      try {
        const url = `${process.env.REACT_APP_API_URL}passverify`;
        const {
          data: { response: verify },
        } = await axios.post(url, {
          email,
          passreset_token: resetToken,
        });
        let message;
        if (verify && verify.length) {
          const result = verify[0];
          if (result) {
            if (
              result.email === email &&
              result.passreset_token === resetToken
            ) {
              // update
              dispatch(AlertActions.info('Updating password...'));
              const urlReset = `${process.env.REACT_APP_API_URL}passreset`;
              const {
                data: { response: updated },
              } = await axios.post(urlReset, {
                email,
                passreset_token: resetToken,
                password,
              });
              if (updated) {
                const mesage = 'Password updated, Use it to login';
                dispatch(AlertActions.success(mesage));
                clearLocalStorage();
                // reset fields
                setResetToken('');
                setPassword('');
                setIsSubmitting(false);
                setConfirm('');
                // history.push('/signin');
              }
            }
          } else {
            message = 'Invalid token';
          }
        } else {
          message = 'Could not verify your token';
        }
        if (message) {
          dispatch(AlertActions.error(message));
        }
      } catch (error) {
        dispatch(AlertActions.error('An error occurred'));
      }
    }
  };

  return (
    <div className="login-container">
      <div className="row">
        <div className="col-md-12">
          <div className="text-center m-b-md">
            <h3>PASSWORD RESET</h3>
          </div>
          <div className="hpanel">
            <div className="panel-body">
              {alert && alert.message && (
                <AlertMessage
                  message={alert.message}
                  alertType={alert.type}
                  timeOut={10000}
                />
              )}
              <form onSubmit={onPasswordReset}>
                <TextInput
                  type="text"
                  placeholder="Enter token you received"
                  caption="Enter token you received"
                  onChange={(event) => {
                    setResetToken(event.target.value);
                  }}
                  value={resetToken}
                  columnWidth={12}
                  isWrapped={false}
                  className={isSubmitting && !resetToken ? 'error' : ''}
                  inputRef={tokenRef}
                />
                <TextInput
                  type="password"
                  caption="Enter new password"
                  placeholder="******"
                  required=""
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  columnWidth={12}
                  isWrapped={false}
                  className={isSubmitting && !password ? 'error' : ''}
                />
                <TextInput
                  type="password"
                  caption="Confirm password"
                  placeholder="******"
                  required=""
                  value={confirm}
                  onChange={(event) => {
                    setConfirm(event.target.value);
                  }}
                  columnWidth={12}
                  isWrapped={false}
                  className={isSubmitting && !confirm ? 'error' : ''}
                />
                <Button
                  type="submit"
                  cssClass="btn-success btn-block"
                  caption="Request Password Reset"
                />
                <button
                  onClick={() => {
                    clearLocalStorage();
                    history.push('/signin');
                  }}
                  type="button"
                  title="Back To Login Page"
                  style={{
                    color: '#2083FE',
                    float: 'right',
                    marginTop: '25px',
                    border: 'none',
                    backgroundColor: 'inherit',
                  }}
                >
                  Back To Login Page
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

PasswordReset.propTypes = {
  dispatch: PropTypes.func.isRequired,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

PasswordReset.defaultProps = {
  alert: PropTypes.object,
};

export default connect(mapStateToProps)(PasswordReset);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import { ExportTable, FormatDate } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import ActionButton from '../Buttons/ActionButton';
import IziToast from '../IziToast';
import iziQuestion from '../IziToast/question';

const TenantsTable = ({ user, property }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [occupancy, setOccupancy] = useState([]);
  const [error, setError] = useState(null);
  const [isReLoading, setIsReLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const endpoint = `${process.env.REACT_APP_API_URL}property/occupancy?where={"id":"in::${property.id}","status":"active"}&take=1000`;
    axios
      .get(endpoint, requestConfig)
      .then((res) => {
        const { response } = res.data;
        const items = Object.values(response);
        if (items && items.length) {
          setOccupancy(items[0].tenants);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        IziToast('Unable to load data');
        setError(err);
      });
  }, [isReLoading]);

  // life-cycle hooks
  useEffect(() => {
    ExportTable('tenants_table', 'tenants');
  }, [isLoading]);

  function onMoveOut(tenant) {
    const vacateObject = {
      id: tenant.id,
      unit_id: tenant.unit_id,
      status: 'vacated',
    };
    IziToast('Vacating...');
    axios
      .put(
        `${process.env.REACT_APP_API_URL}tenants/vacate`,
        vacateObject,
        requestConfig,
      )
      .then((resp) => {
        if (resp.data.response) {
          IziToast('Tenant successfully moved out');
          setIsReLoading(!isReLoading);
        } else {
          IziToast('Unable to move tenant out');
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response) {
            if (err.response.data) {
              IziToast(err.response.data.error);
            }
          }
        } else {
          IziToast('An error occurred');
        }
      });
  }

  const onConfirmVacate = (tenant) => {
    iziQuestion(
      `Move out tenant <i><b>${tenant.first_name} ${tenant.last_name} | ${
        tenant.email || ''
      }</b></i> ?`,
      () => {
        onMoveOut(tenant);
      },
      undefined,
    );
  };

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      {occupancy && occupancy.length ? (
        <Table tableId="tenants_table" marginTop={10}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Action</th>
              <th>Date</th>
              <th>Unit No.</th>
              <th>Tenant</th>
              <th>ID/Passport No.</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            {occupancy.map((tenant, id) => {
              let index = id;
              const otherDetails = tenant.other_details;
              let unitNumber;
              if (otherDetails) {
                unitNumber = otherDetails.unit_number;
              }
              return (
                <tr key={tenant.id}>
                  <td>{++index}</td>
                  <td>
                    <ActionButton
                      caption="Move Out"
                      onClick={() => {
                        onConfirmVacate(tenant);
                      }}
                    />
                  </td>
                  <td>
                    {FormatDate(
                      new Date(+tenant.start_date * 1000).toISOString(),
                    )}
                  </td>
                  <td>{unitNumber}</td>
                  <td>{`${tenant.first_name} ${tenant.last_name}`}</td>
                  <td>{tenant.id_or_passport}</td>
                  <td>{tenant.email}</td>
                  <td>{tenant.phone}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="Seems no tenants found" />
      )}
    </>
  );
};

TenantsTable.propTypes = {
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  user: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default connect((state) => {
  return state;
})(TenantsTable);

import React from 'react';
import PropTypes from 'prop-types';

const MiniButton = ({ caption, onClick, buttonType, minWidth = 50 }) => {
  let btnStyle;
  let bgColor;
  let frColor;
  if (buttonType === 'danger') {
    btnStyle = 'btn btn-danger2 btn-xs';
    bgColor = undefined;
  } else if (buttonType === 'info') {
    btnStyle = 'btn btn-info btn-xs';
    bgColor = '#333333';
  } else {
    btnStyle = 'btn btn-xs';
    frColor = '#d73836';
  }
  return (
    <button
      className={btnStyle}
      style={{
        backgroundColor: bgColor,
        color: frColor,
        minWidth: `${minWidth}px`,
      }}
      onClick={onClick}
      type="button"
    >
      {`  ${caption}`}
    </button>
  );
};

MiniButton.propTypes = {
  caption: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonType: PropTypes.string.isRequired,
  minWidth: PropTypes.number,
};

MiniButton.defaultProps = {
  minWidth: 50,
};

export default MiniButton;

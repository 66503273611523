import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import $ from 'jquery';

import Master from '../Master';
import Select from '../Select';
import TextInput from '../TextInput';
import IziToast from '../IziToast';
import Button from '../Buttons';
import Collapsible from '../Panels/Collapsible';
import ChargesTable from './ChargesTable';

const ExtraCharges = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };

  //   refs
  const propertyRef = useRef(null);
  const codeRef = useRef(null);
  // state
  const [propertyId, setPropertyId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [chargeName, setChargeName] = useState('');
  const [chargeCode, setChargeCode] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    let url = `/byuser?status=active&user_id=${user.id}`;
    if (user && user.type === 'admin') {
      // eslint-disable-next-line quotes
      url = `?take=1000&where={"status":"active"}&order={"name":"ASC"}`;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}properties${url}`, requestConfig)
      .then((res) => {
        setProperties(res.data.response);
        $(propertyRef.current)
          .select2()
          .on('change', (event) => {
            setPropertyId(event.val);
          });
      })
      .catch(() => {
        IziToast('Could not load your properties');
      });
  }, []);

  const saveCharges = () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (chargeName && chargeCode && propertyId && description) {
      if (parseFloat(chargeCode) <= 0) {
        message = 'Enter valid charge code';
        IziToast(message);
        isValid = false;
        codeRef.current.focus();
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (isValid) {
      const chargesObject = {
        description,
        property_id: propertyId,
        name: chargeName,
        code: chargeCode,
      };
      IziToast('Saving...');
      axios
        .post(
          `${process.env.REACT_APP_API_URL}paymentcodes`,
          chargesObject,
          requestConfig,
        )
        .then((response) => {
          if (response.data.response.id) {
            IziToast('Charge code successfully saved');
            resetFields();
          }
          setIsLoading(!isLoading);
        })
        .catch((error) => {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                IziToast(error.response.data.error);
              }
            }
          } else {
            IziToast('An error occurred');
          }
        });
    }
  };

  const resetFields = () => {
    setPropertyId('');
    setDescription('');
    setChargeName('');
    setChargeCode('');
    setIsSubmitting(false);
  };

  return (
    <Master
      redirectLink="extracharges"
      title="Settings & Configuration"
      source="Extra Charges"
    >
      <div className="row">
        <Select
          caption="Select Property"
          value={propertyId}
          onChange={(event) => {
            setPropertyId(event.target.value);
          }}
          columnWidth={5}
          className={isSubmitting && !propertyId ? 'error' : ''}
          inputRef={propertyRef}
        >
          <option value="">Select property name</option>
          {properties.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </Select>
        <TextInput
          type="text"
          placeholder="Enter charge name"
          caption="Charge Name"
          onChange={(e) => {
            setChargeName(e.target.value);
          }}
          value={chargeName}
          columnWidth={7}
          className={isSubmitting && !chargeName ? 'error' : ''}
        />
      </div>
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter charge description"
          caption="Charge Description"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          value={description}
          columnWidth={7}
          className={isSubmitting && !description ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="Enter charge code"
          caption="Charge Code"
          onChange={(e) => {
            const value = /^[0-9\b]+$/;
            // allows number only
            if (e.target.value === '' || value.test(e.target.value)) {
              setChargeCode(e.target.value);
            }
          }}
          value={chargeCode}
          columnWidth={2}
          className={
            (isSubmitting && !chargeCode) || parseFloat(chargeCode <= 0)
              ? 'error'
              : ''
          }
          inputRef={codeRef}
        />
        <div className="col-md-3 inline-right-button">
          <Button
            caption="Create Charges"
            cssClass="btn-success"
            onClick={saveCharges}
            type="button"
            isBlack={false}
          />
        </div>
      </div>

      <Collapsible
        caption="Charges List"
        columnWidth={12}
        controlId="charges_list"
      >
        <ChargesTable reLoadTable={isLoading} requestConfig={requestConfig} />
      </Collapsible>
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

ExtraCharges.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

ExtraCharges.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(ExtraCharges);

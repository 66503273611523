const UserTypes = [
  {
    id: 'admin',
    name: 'admin',
  },
  {
    id: 'agent',
    name: 'agent',
  },
  {
    id: 'landlord',
    name: 'landlord',
  },
  {
    id: 'caretaker',
    name: 'caretaker',
  },
];

export default UserTypes;

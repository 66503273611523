import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { DateTimeUTC, FormatDate, ExportTable } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import ButtonLink from '../Buttons/ButtonLink';
import AlertActions from '../../actions/alert';

const TransTable = ({
  isLoading,
  dispatch,
  error,
  transactions,
  userToken,
  urlTransactions,
  filterProperty,
  filterTenant,
  filterStatus,
  filterLowerDate,
  filterUpperDate,
}) => {
  // state
  const history = useHistory();
  // life-cycle hooks
  useEffect(() => {
    ExportTable('transactions_table', 'transactions');
  }, [isLoading, transactions]);

  // may remove later for debug purposes
  // useEffect(() => {
  //   if (error) {
  //     dispatch(AlertActions.error(`An error occurred: ${error}`));
  //   }
  // }, [error]);

  function saveLocalStorage(transaction) {
    const { property, tenant, unit } = transaction;
    const obj = {
      id: property.id,
      name: property.name,
    };
    if (typeof Storage !== 'undefined') {
      window.localStorage.setItem('property', JSON.stringify(obj));
      window.localStorage.setItem('source', 'rentroll');
      window.localStorage.setItem('tenant', JSON.stringify(tenant));
    }
    history.push({
      pathname: '/unitprofile',
      tenant,
      property: obj,
      userToken,
      source: 'rentroll',
      unit,
      urlTransactions,
      filterProperty,
      filterTenant,
      filterStatus,
      filterLowerDate,
      filterUpperDate,
    });
  }

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : (
    <>
      {transactions && transactions.length > 0 ? (
        <Table tableId="transactions_table" marginTop={10}>
          <thead>
            <tr>
              <th>No.</th>
              {/* <th>Date</th> */}
              {/* <th>Property</th> */}
              <th>Hse. Unit</th>
              {/* <th>MPESA-ID</th> */}
              <th>Rent</th>
              <th>Charges</th>
              <th>Utilities</th>
              <th>Arrears</th>
              <th>Amount Due</th>
              <th>Amount Paid</th>
              {/* <th>Bal C/F</th> */}
              <th>Balance</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, id) => {
              let index = id;
              /**
               * most logic here is working in
               * reverse due to the way the API is structured.
               */
              let propertyName = '';
              let unitNumber = '';
              let transactionStatus = '';
              const balanceBF = transaction.balanceBF !== '-' ? +transaction.balanceBF : transaction.balanceBF;
              const balanceCF = transaction.balanceCF !== '-' ? +transaction.balanceCF : transaction.balanceCF;
              // const { amount_due: amountDue } = transaction;
              const amountBF =
                balanceBF !== '-' && balanceBF < 0 ? `(${balanceBF * -1})` : balanceBF.toString();
              const amountCF =
                balanceCF !== '-' && balanceCF < 0 ? `(${Math.abs(balanceCF)})` : balanceCF.toString();
              const { amountPaid } = transaction;
              const paidAmount = amountPaid !== '-' && amountPaid > 0 ? `(${amountPaid})` : amountPaid;

              // if (balanceCF === 0) {
              //   transactionStatus = 'Paid';
              // } else if (balanceCF > 0) {
              //   transactionStatus = 'Overpayment';
              // } else {
              //   transactionStatus = balanceBF !== '-' ? 'Arrears' : '';
              // }

              transactionStatus = transaction.status;
              if (transaction.property) {
                const { property } = transaction;
                if (property) {
                  propertyName = property.name;
                }
              }
              if (transaction.tenant) {
                const { tenant } = transaction;
                if (tenant) {
                  if (tenant.other_details) {
                    unitNumber = tenant.other_details.unit_number || '';
                  }
                }
              }
              return (
                <tr key={index}>
                  <td>{++index}</td>
                  {/* <td>{FormatDate(transaction.invoice_date)}</td> */}
                  {/* <td>{propertyName}</td> */}
                  <td>
                    <ButtonLink
                      caption={unitNumber}
                      onClick={() => {
                        saveLocalStorage(transaction);
                      }}
                    />
                  </td>
                  {/* <td>{receiptNumber}</td> */}
                  <td>{transaction.rentAmount}</td>
                  <td>{transaction.charges}</td>
                  <td>{transaction.utilities}</td>
                  <td>{amountBF}</td>
                  <td>{transaction.amountDue}</td>
                  <td>{paidAmount}</td>
                  {/* <td>{amountCF}</td> */}
                  <td>{amountCF}</td>
                  <td>{transactionStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="Seems no transaction found" />
      )}
    </>
  );
};

TransTable.propTypes = {
  transactions: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  userToken: PropTypes.string.isRequired,
  urlTransactions: PropTypes.string,
  filterProperty: PropTypes.string,
  filterTenant: PropTypes.string,
  filterStatus: PropTypes.string,
  filterLowerDate: PropTypes.instanceOf(Date),
  filterUpperDate: PropTypes.instanceOf(Date),
};

TransTable.defaultProps = {
  error: null,
  urlTransactions: undefined,
  filterProperty: undefined,
  filterTenant: undefined,
  filterStatus: undefined,
  filterLowerDate: undefined,
  filterUpperDate: undefined,
};

export default TransTable;

/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const FilePreview = ({
  inputId,
  onChange,
  currentFile,
  onFileRemove,
  columnWidth,
  caption,
}) => {
  const fileName =
    currentFile && typeof currentFile === 'object' ? currentFile.name : caption;
  const colWidth = `col-xs-${columnWidth}`;
  const fontSize = currentFile ? '10px' : '18px';
  const spanStyle = currentFile
    ? 'bm-file-container bm-has-file'
    : 'bm-file-container';
  const shortenName = (name) => {
    const strName = String(name);
    if (strName.length > 25) {
      return `... ${strName.substring(strName.length - 25, strName.length)}`;
    }
    return strName;
  };
  const fileSource =
    currentFile && typeof currentFile === 'object'
      ? window.URL.createObjectURL(currentFile)
      : currentFile;

  return (
    <div className={colWidth}>
      <label className="bm-file-input bm-file-multiple">
        <input multiple="" type="file" id={inputId} onChange={onChange} />
        <span
          className={spanStyle}
          style={{
            fontSize,
          }}
          data-title={shortenName(fileName)}
        >
          <button
            style={{ display: currentFile ? 'block' : 'none' }}
            className="btn btn-circle remove-file"
            onClick={onFileRemove}
            type="button"
          >
            <i className="fa fa-times" />
          </button>
          <span
            className="bm-file-name"
            style={{
              fontSize,
            }}
            data-title={shortenName(fileName)}
          >
            {currentFile && (
              <img
                style={{
                  width: '200px',
                  height: '200px',
                }}
                src={fileSource}
                alt={caption}
              />
            )}
            <i className=" bm-icon bm-icon fa fa-picture-o" />
          </span>
        </span>
      </label>
    </div>
  );
};

FilePreview.propTypes = {
  inputId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  currentFile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onFileRemove: PropTypes.func.isRequired,
  columnWidth: PropTypes.number,
  caption: PropTypes.string,
};

FilePreview.defaultProps = {
  inputId: '',
  currentFile: 'Choose file',
  columnWidth: 3,
  caption: 'Click to choose image',
};

export default FilePreview;

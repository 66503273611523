/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Paragraph = ({ caption, columnWidth, content }) => {
  const colWidth = `col-md-${columnWidth}`;
  return (
    <div className={colWidth}>
      <div className="form-group">
        <label
          className="control-label"
          // style={{ color: '#d63835' }}
        >
          {caption}
        </label>
        <p className="bm-paragraph">{content || '-'}</p>
      </div>
    </div>
  );
};

Paragraph.propTypes = {
  caption: PropTypes.string,
  columnWidth: PropTypes.number.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

Paragraph.defaultProps = {
  caption: '',
  content: '',
};

export default Paragraph;

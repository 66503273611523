import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Buttons';

const UpdateModal = ({ modalId, header, children, onClick }) => {
  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              padding: '10px',
              textAlign: 'center',
              color: '#d63835',
              fontSize: '15px',
            }}
          >
            {header}
            <div className="pull-right">
              <button
                className="btn btn-circle"
                type="button"
                data-dismiss="modal"
              >
                <i className="fa fa-times" />
              </button>
            </div>
          </div>
          <div className="modal-body">{children}</div>
          <div
            className="modal-footer"
            style={{ padding: '5px', textAlign: 'center' }}
          >
            <Button
              caption="Update"
              cssClass="btn-success"
              onClick={onClick}
              type="button"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

UpdateModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  header: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
};

UpdateModal.defaultProps = {
  children: '',
  header: '',
};

export default UpdateModal;

export default function InvoiceStatus(status) {
  return [
    { id: 'fully_paid', name: 'Fully Paid' },
    { id: 'paid', name: 'Paid' },
    { id: 'unpaid', name: 'Unpaid' },
    { id: 'partially_paid', name: 'Partially Paid' },
    { id: 'cancelled', name: 'Cancelled' },
  ].find((item) => {
    return item.id === status;
  }).name;
}

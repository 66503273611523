/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';

import UpdateModal from '../Modals/UpdateModal';
import IziToast from '../IziToast';
import TextInput from '../TextInput';
import { ValidateEmail } from '../../utils/Functions';

const UpdateKin = ({
  requestConfig,
  reLoadTable,
  selectedKin,
  tenantId,
  nextOfKins,
}) => {
  // state

  const [phone, setPhone] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [relationship, setRelationship] = useState('');
  const [email, setEmail] = useState('');

  // copying state from props is anti-pattern but in our case we're not relying on props update
  useEffect(() => {
    if (selectedKin) {
      setName(selectedKin.name);
      setRelationship(selectedKin.relationship);
      setPhone(selectedKin.phone);
      setEmail(selectedKin.email);
    }
  }, [selectedKin]);

  const updateNextOfKin = () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (tenantId && name && relationship && phone && email) {
      if (!ValidateEmail(email)) {
        isValid = false;
        message = 'Enter a valid email address';
        IziToast(message);
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }

    if (isValid) {
      let kinData;
      const kinObj = {
        name,
        email,
        phone,
        relationship,
      };
      if (nextOfKins && nextOfKins.length) {
        const kins = nextOfKins.filter((kin) => {
          return kin.id !== selectedKin.id;
        });
        kinData = [...kins, kinObj];
      } else {
        kinData = [kinObj];
      }

      const formData = new FormData();
      formData.append('id', tenantId);
      formData.append('next_of_kin', JSON.stringify(kinData));
      IziToast('Deleting...');
      axios
        .put(`${process.env.REACT_APP_API_URL}tenants`, formData, requestConfig)
        .then((response) => {
          if (response.data.response.id) {
            IziToast('Next of kin successfully Updated');
            if (reLoadTable) reLoadTable();
            $('#kinsModal').modal('hide');
          }
        })
        .catch((error) => {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                IziToast(error.response.data.error);
              }
            } else {
              IziToast(error.message);
            }
          } else {
            IziToast('An error occurred');
          }
        });
    }
  };

  return (
    <UpdateModal
      modalId="kinsModal"
      onClick={updateNextOfKin}
      header="Update next of kins details"
    >
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter kin name"
          caption="Kin Name"
          onChange={(event) => {
            setName(event.target.value);
          }}
          value={name}
          columnWidth={3}
          className={isSubmitting && !name ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="Enter phone number"
          caption="Phone Number"
          onChange={(e) => {
            const value = /^[0-9\b]+$/;
            // allows number only
            if (e.target.value === '' || value.test(e.target.value)) {
              setPhone(e.target.value);
            }
          }}
          value={phone}
          columnWidth={3}
          className={isSubmitting && !phone ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="Enter email"
          caption="Email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          value={email}
          name="email"
          columnWidth={3}
          className={
            isSubmitting && (!email || !ValidateEmail(email)) ? 'error' : ''
          }
        />
        <TextInput
          type="text"
          placeholder="Enter relationship"
          caption="Relationship"
          onChange={(event) => {
            setRelationship(event.target.value);
          }}
          value={relationship}
          columnWidth={3}
          className={isSubmitting && !relationship ? 'error' : ''}
        />
      </div>
    </UpdateModal>
  );
};

UpdateKin.propTypes = {
  requestConfig: PropTypes.objectOf(PropTypes.object),
  selectedKin: PropTypes.oneOfType([PropTypes.object]),
  reLoadTable: PropTypes.func.isRequired,
  tenantId: PropTypes.string.isRequired,
  nextOfKins: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

UpdateKin.defaultProps = {
  requestConfig: {},
  selectedKin: {},
  nextOfKins: {},
};

export default UpdateKin;

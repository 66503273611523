import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { connect } from 'react-redux';

import Navigation from '../Menus/Navigation';
import DropDown from '../Menus/DropDown';
import NavigationLink from '../Menus/NavLink';

const Aside = React.memo(({ redirectLink, navRef, user }) => {
  const userType = user.type;
  // state
  const [activeLink, setActiveLink] = useState('');
  const [activeNav, setActiveNav] = useState('');
  // refs
  const menuRef = useRef(null);
  const linkRef = useRef(null);
  const sideMenuRef = useRef(null);

  useEffect(() => {
    if (redirectLink) {
      const element = window.document.querySelector(`a[name=${redirectLink}]`);
      if (element) {
        const { title } = element.parentElement.parentElement.parentElement;
        if (title) {
          setActiveNav(title);
        }
      }
      setActiveLink(redirectLink);
    }

    // Initialize metsiMenu plugin to sidebar menu
    if (sideMenuRef.current) {
      $(sideMenuRef.current).metisMenu();
    }

    if (navRef.current) {
      // Check if sidebar scroll is enabled
      if ($('body').hasClass('sidebar-scroll')) {
        $(navRef.current).slimScroll({
          height: '100%',
          opacity: 0.3,
          size: 0,
          wheelStep: 5,
        });
      }
    }
  }, [redirectLink]);

  const redirect = user.type === 'caretaker' ? 'tenantslist' : 'dashboard';
  const navigation = user.type === 'caretaker' ? '/tenantslist' : '/';
  const adminTab = ['admin', 'caretaker', 'landlord'];
  const usersTab = ['admin']; // , 'landlord'
  return (
    <aside id="menu">
      <div id="navigation" ref={navRef}>
        <ul className="nav" id="side-menu" ref={sideMenuRef}>
          <Navigation
            caption="Home"
            name="dashboard"
            navigation={navigation}
            isActive={activeLink === 'dashboard'}
            menuRef={menuRef}
            onClick={() => {
              setActiveNav(redirect);
            }}
          />
          {user.type !== 'caretaker' && (
            <DropDown
              caption="Property"
              name="properties"
              isActive={activeNav === 'properties'}
              onClick={() => {
                setActiveNav('properties');
              }}
            >
              <NavigationLink
                caption="Property"
                name="property"
                navigation="/property"
                isActive={activeLink === 'property'}
                linkRef={linkRef}
              />
              <NavigationLink
                caption="Units"
                name="units"
                navigation="/units"
                isActive={activeLink === 'units'}
              />
              <NavigationLink
                caption="Tenants"
                name="tenants"
                navigation="tenants"
                isActive={activeLink === 'tenants'}
              />
            </DropDown>
          )}
          <DropDown
            caption="Finance"
            name="finance"
            isActive={activeNav === 'finance'}
            onClick={() => {
              setActiveNav('finance');
            }}
          >
            <NavigationLink
              caption="Transactions"
              name="transactions"
              navigation="/transactions"
              isActive={activeLink === 'transactions'}
              linkRef={linkRef}
            />
            <NavigationLink
              caption="Rent Roll"
              name="rentroll"
              navigation="/rentroll"
              isActive={activeLink === 'rentroll'}
            />
            <NavigationLink
              caption="Invoices"
              name="invoices"
              navigation="/invoices"
              isActive={activeLink === 'invoices'}
            />
            <NavigationLink
              caption="Receipts"
              name="receipts"
              navigation="/receipts"
              isActive={activeLink === 'receipts'}
            />
            <NavigationLink
              caption="Extra Charges"
              name="extracharge"
              navigation="/extracharge"
              isActive={activeLink === 'extracharge'}
            />
            <NavigationLink
              caption="Utilities"
              name="utilities"
              navigation="/utilities"
              isActive={activeLink === 'utilities'}
              linkRef={linkRef}
            />
          </DropDown>
          <DropDown
            caption="Reports"
            name="reports"
            isActive={activeNav === 'reports'}
            onClick={() => {
              setActiveNav('reports');
            }}
          >
            <NavigationLink
              caption="Rent Arrears"
              name="rentarrears"
              navigation="/rentarrears"
              isActive={activeLink === 'rentarrears'}
              linkRef={linkRef}
            />
            <NavigationLink
              caption="Late Payments"
              name="latepayments"
              navigation="/latepayments"
              isActive={activeLink === 'latepayments'}
              linkRef={linkRef}
            />
            <NavigationLink
              caption="Account Statement"
              name="monthlyreport"
              navigation="/monthlyreport"
              isActive={activeLink === 'monthlyreport'}
              linkRef={linkRef}
            />
            <NavigationLink
              caption="Tenants List"
              name="tenantslist"
              navigation="/tenantslist"
              isActive={activeLink === 'tenantslist'}
              linkRef={linkRef}
            />
          </DropDown>
          {adminTab.includes(userType) && (
            <DropDown
              caption="Settings"
              name="settings"
              isActive={activeNav === 'settings'}
              onClick={() => {
                setActiveNav('settings');
              }}
            >
              {usersTab.includes(userType) && (
                <>
                  <NavigationLink
                    caption="Users"
                    name="users"
                    navigation="/users"
                    isActive={activeLink === 'users'}
                    linkRef={linkRef}
                  />
                </>
              )}
              {userType === 'admin' && (
                <>
                  <NavigationLink
                    caption="Property Access"
                    name="propertyaccess"
                    navigation="/propertyaccess"
                    isActive={activeLink === 'propertyaccess'}
                    linkRef={linkRef}
                  />
                  <NavigationLink
                    caption="Audit Logs"
                    name="auditlogs"
                    navigation="/auditlogs"
                    isActive={activeLink === 'auditlogs'}
                    linkRef={linkRef}
                  />
                </>
              )}
              <NavigationLink
                caption="Charges"
                name="charges"
                navigation="/charges"
                isActive={activeLink === 'charges'}
                linkRef={linkRef}
              />
              <NavigationLink
                caption="Extra Charges"
                name="extracharges"
                navigation="/extracharges"
                isActive={activeLink === 'extracharges'}
                linkRef={linkRef}
              />
            </DropDown>
          )}
        </ul>
      </div>
    </aside>
  );
});

Aside.propTypes = {
  redirectLink: PropTypes.string.isRequired,
  navRef: PropTypes.objectOf(PropTypes.object).isRequired,
  user: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Aside);

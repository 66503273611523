import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import $ from 'jquery';

import Master from '../Master';
import Select from '../Select';
import TextInput from '../TextInput';
import IziToast from '../IziToast';
import Button from '../Buttons';
import ChargesTable from './ChargesTable';

const Charges = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };

  //   refs
  const propertyRef = useRef(null);
  const unitRef = useRef(null);
  const amountRef = useRef(null);
  // state
  const [propertyId, setPropertyId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [chargeName, setChargeName] = useState('');
  const [chargeAmount, setChargeAmount] = useState('');
  const [units, setUnits] = useState([]);
  const [unitId, setUnitId] = useState('');
  const [chargeType, setChargeType] = useState('property');

  useEffect(() => {
    let url = `/byuser?status=active&user_id=${user.id}`;
    if (user && user.type === 'admin') {
      // eslint-disable-next-line quotes
      url = `?take=1000&where={"status":"active"}&order={"name": "ASC"}`;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}properties${url}`, requestConfig)
      .then(({ data: { response } }) => {
        setProperties(response);
        $(propertyRef.current)
          .select2()
          .on('change', (event) => {
            setPropertyId(event.val);
          });
      })
      .catch(() => {
        IziToast('Could not load your properties');
      });
  }, []);

  useEffect(() => {
    if (propertyId) {
      const where = [{ property_id: propertyId, status: 'available' }];
      const url = `${process.env.REACT_APP_API_URL}units?where=${JSON.stringify(
        where,
      )}`;
      axios
        .get(url, requestConfig)
        .then((res) => {
          setUnits(res.data.response);
          $(unitRef.current)
            .select2()
            .on('change', (event) => {
              setUnitId(event.val);
            });
        })
        .catch(() => {
          IziToast('Could not load your units');
        });
    }
  }, [propertyId]);

  const saveCharges = () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (chargeName && chargeAmount && chargeType) {
      if (chargeType === 'property' && !propertyId) {
        isValid = false;
        message = 'Select property details first';
        IziToast(message);
        propertyRef.current.focus();
      } else {
        isValid = true;
      }
      if (chargeType === 'unit' && !unitId) {
        isValid = false;
        message = 'Select unit details first';
        IziToast(message);
        unitRef.current.focus();
      } else {
        isValid = true;
      }
      if (parseFloat(chargeAmount) <= 0) {
        message = 'Enter valid charge amount';
        IziToast(message);
        isValid = false;
        amountRef.current.focus();
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }

    if (isValid) {
      const chargesObject = {
        charge_type: chargeType,
        reference_id: chargeType === 'property' ? propertyId : unitId,
        name: chargeName,
        amount: chargeAmount,
      };
      IziToast('Saving...');
      axios
        .post(
          `${process.env.REACT_APP_API_URL}charges`,
          chargesObject,
          requestConfig,
        )
        .then((response) => {
          if (response.data.response.id) {
            IziToast('Charge successfully saved');
            resetFields();
          }
          setIsLoading(!isLoading);
        })
        .catch((error) => {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                IziToast(error.response.data.error);
              }
            }
          } else {
            IziToast('An error occurred');
          }
        });
    }
  };

  const resetFields = () => {
    setPropertyId('');
    setChargeType('property');
    setUnitId('');
    setChargeName('');
    setChargeAmount('');
    setIsSubmitting(false);
  };

  return (
    <Master
      redirectLink="charges"
      title="Settings & Configuration"
      source="Charges"
    >
      <div className="row">
        <Select
          caption="Select Property"
          value={propertyId}
          onChange={(event) => {
            setPropertyId(event.target.value);
          }}
          columnWidth={5}
          className={
            isSubmitting && chargeType === 'property' && !propertyId
              ? 'error'
              : ''
          }
          inputRef={propertyRef}
        >
          <option value="">Select property name</option>
          {properties.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </Select>
        <Select
          caption="Apply Charge To"
          value={chargeType}
          onChange={(event) => {
            setChargeType(event.target.value);
          }}
          columnWidth={2}
          className={isSubmitting && !chargeType ? 'error' : ''}
        >
          <option value="property">Property</option>
          <option value="unit">Unit</option>
        </Select>
        <div className={chargeType === 'unit' ? '' : 'hidden'}>
          <Select
            caption="Select Unit"
            value={unitId}
            onChange={(event) => {
              setUnitId(event.target.value);
            }}
            columnWidth={5}
            className={
              isSubmitting && chargeType === 'unit' && !unitId ? 'error' : ''
            }
            inputRef={unitRef}
          >
            <option value="">Select unit description</option>
            {units.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {`${item.type} - ${item.description}`}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter charge name"
          caption="Charge Name"
          onChange={(e) => {
            setChargeName(e.target.value);
          }}
          value={chargeName}
          columnWidth={5}
          className={isSubmitting && !chargeName ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="Enter charge amount"
          caption="Charge Amount"
          onChange={(e) => {
            const value = /^[0-9\b]+$/;
            // allows number only
            if (e.target.value === '' || value.test(e.target.value)) {
              setChargeAmount(e.target.value);
            }
          }}
          value={chargeAmount}
          columnWidth={2}
          className={
            (isSubmitting && !chargeAmount) || parseFloat(chargeAmount <= 0)
              ? 'error'
              : ''
          }
          inputRef={amountRef}
        />
        <div className="inline-right-button col-md-3">
          <Button
            caption="Create Charges"
            cssClass="btn-success"
            onClick={saveCharges}
            type="button"
            isBlack={false}
          />
        </div>
      </div>
      {user && <ChargesTable reLoadTable={isLoading} user={user} />}
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

Charges.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Charges.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(Charges);

import React from 'react';
import PropTypes from 'prop-types';
import Paragraph from '../Paragraph';
import { MercuryID } from '../../utils/Functions';
import Platforms from '../../data/Platforms';
import Master from '../Master';
import NoImage from '../../img/no-image.png';
import Collapsible from '../Panels/Collapsible';
import TenantsTable from './TenantsTable';

const PropertyProfile = ({ location: { property } }) => {
  if (typeof Storage !== 'undefined') {
    if (!property) {
      const propStr = window.localStorage.getItem('property');
      // eslint-disable-next-line no-param-reassign
      if (propStr) property = JSON.parse(propStr);
    }
  }

  const paymentTitle = (id) => {
    return Platforms.find((item) => {
      return item.id === id;
    });
  };

  let imageLink = NoImage;
  if (property.images) {
    const image = property.images[0];
    imageLink = image.link;
  }

  return (
    <Master
      redirectLink="property"
      title="Property Management"
      source="Property"
    >
      {property ? (
        <>
          <div className="row">
            <div className="col-xs-3 bm-responsive-div">
              <div className="row">
                <div className="col-md-12">
                  <img
                    className="bordered_photo"
                    style={{
                      width: '200px',
                      height: '200px',
                      margin: '5px',
                    }}
                    src={imageLink}
                    alt={property.name}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-9 bm-responsive-div">
              <div className="row">
                <Paragraph
                  columnWidth={12}
                  content={property.name}
                  caption="Property Name"
                />
              </div>
              <div className="row">
                <Paragraph
                  columnWidth={12}
                  content={property.description}
                  caption="Property Description"
                />
              </div>
              <div className="row">
                <Paragraph
                  columnWidth={12}
                  content={property.address}
                  caption="Property Address"
                />
              </div>
              {property.payment_details && (
                <div className="row">
                  {property.payment_details.map((payment) => {
                    return (
                      <>
                        {payment.payment_info && (
                          <>
                            <Paragraph
                              columnWidth={3}
                              key={MercuryID()}
                              content={
                                paymentTitle(payment.payment_info.platform).name
                              }
                              caption="Payment Platform"
                            />
                            <Paragraph
                              columnWidth={3}
                              key={MercuryID()}
                              content={payment.payment_info.account}
                              caption="Paybill Number"
                            />
                          </>
                        )}
                      </>
                    );
                  })}
                  <Paragraph
                    columnWidth={3}
                    content={property.units}
                    caption="Property Units"
                  />
                  <Paragraph
                    columnWidth={3}
                    content={property.status}
                    caption="Property Status"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <Collapsible
              columnWidth={12}
              caption="Tenants List"
              controlId="tList"
            >
              <TenantsTable property={property} />
            </Collapsible>
          </div>
        </>
      ) : null}
    </Master>
  );
};

PropertyProfile.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

PropertyProfile.defaultProps = {
  location: {},
};

export default PropertyProfile;

/**
 * whatever am doing here is bad practice but i have to
 * api is not giving meaningful responses so i have
 * to use what is available for now.
 * please don't @me :(
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { ExportTable, FormatDate } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import TableButton from '../Buttons/TableButton';
import iziQuestion from '../IziToast/question';
import IziToast from '../IziToast';
import UpdateCharges from './UpdateCharges';
import ModalButton from '../Buttons/ModalButton';
import Collapsible from '../Panels/Collapsible';

const ChargesTable = ({ reLoadTable, user }) => {
  const userToken = user.token;
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  // state
  const [isReLoading, setIsReloading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [charges, setCharges] = useState([]);
  const [hasError, setHasError] = useState(false);

  // life-cycle hooks
  useEffect(() => {
    loadCharges(properties, units);
  }, [reLoadTable, isReLoading]);

  useEffect(() => {
    (async () => {
      try {
        const urlUnits = `${process.env.REACT_APP_API_URL}units?where=[{"status":"available"},{"status":"occupied"}]`;
        let urlProperty;
        if (user.type === 'admin') {
          urlProperty = `${process.env.REACT_APP_API_URL}properties?where={"status":"active"}&order={"name": "ASC"}`;
        } else {
          urlProperty = `${process.env.REACT_APP_API_URL}properties/byuser?user_id=${user.id}&take=1000&where={"status":"active"}&order={"name": "ASC"}`;
        }
        const {
          data: { response: propertyInfo },
        } = await axios.get(urlProperty, requestConfig);
        const {
          data: { response: unitInfo },
        } = await axios.get(urlUnits, requestConfig);
        loadCharges(propertyInfo, unitInfo);
        setUnits(unitInfo);
        setProperties(propertyInfo);
      } catch (error) {
        IziToast('An error occurred');
        setHasError(true);
        setIsLoading(false);
      }
    })();
  }, []);

  /**
   *
   * @param {[]} propertyInfo
   * @param {[]} unitInfo
   */
  function loadCharges(propertyInfo, unitInfo) {
    let url;
    if (user.type === 'admin') {
      url = `${process.env.REACT_APP_API_URL}charges?take=1000&order={"name":"ASC"}`;
    } else {
      const filtered = propertyInfo.concat(unitInfo).map((item) => {
        return item.id;
      });

      if (filtered.length > 0) {
        url = `${
          process.env.REACT_APP_API_URL
        }charges?take=1000&where={"reference_id":"in::${filtered.join()}"}&order={"name":"ASC"}`;
      } else {
        url = undefined;
      }
    }
    if (url) {
      getCharges(url);
    }
    function getCharges(_url) {
      axios
        .get(_url, requestConfig)
        .then(({ data: { response } }) => {
          const mapped = response.map((item) => {
            const charge = item;
            if (item.charge_type === 'property') {
              charge.property = findProperty(item.reference_id);
            }
            if (item.charge_type === 'unit') {
              const unit = findUnit(item.reference_id);
              charge.unit = unit;
              if (unit) {
                charge.property = findProperty(unit.property_id) || undefined;
              }
            }
            return charge;
          });
          setCharges(mapped);
          setIsLoading(false);
        })
        .catch(() => {
          setHasError(true);
          setIsLoading(false);
          IziToast('Could not load your charges');
        });
    }

    function findProperty(id) {
      return propertyInfo.find((property) => {
        return property.id === id;
      });
    }

    function findUnit(id) {
      return unitInfo.find((unit) => {
        return unit.id === id;
      });
    }
  }

  useEffect(() => {
    ExportTable('charges_table', 'charges');
  }, [isLoading]);

  const tableReload = () => {
    setIsReloading(!isReLoading);
  };

  const deleteCharge = (charge) => {
    if (charge && charge.id) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}charges?id=${charge.id}`,
          requestConfig,
        )
        .then((res) => {
          if (res.data.response) {
            setIsReloading(!isReLoading);
            IziToast('Selected charge successfully deleted');
          } else {
            IziToast('Selected charge not deleted');
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response) {
              if (err.response.data) {
                IziToast(err.response.data.error || 'An error occurred');
              }
            }
          } else {
            IziToast('An error occurred');
          }
        });
    } else {
      IziToast('Select charge to delete');
    }
  };

  const confirmDelete = (charge) => {
    iziQuestion(
      `Delete charge <i><b>${charge.name}</b></i> completely ?`,
      () => {
        deleteCharge(charge);
      },
      undefined,
    );
  };

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : hasError ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      <UpdateCharges
        requestConfig={requestConfig}
        reLoadTable={tableReload}
        selectedCharge={selectedCharge}
      />

      {charges.length > 0 && (
        <Collapsible
          caption="Charges List"
          columnWidth={12}
          controlId="charges_list"
        >
          <Table tableId="charges_table" marginTop={10}>
            <thead>
              <tr>
                <th>No.</th>
                <th>Edit</th>
                <th>Delete</th>
                <th>Property</th>
                <th>Unit Description</th>
                <th>Charge Name</th>
                <th>Charge Amount</th>
                <th>Created On</th>
                <th>Updated On</th>
              </tr>
            </thead>
            <tbody>
              {charges.map((charge, id) => {
                let index = id;
                return (
                  <tr key={charge.id}>
                    <td>{++index}</td>
                    <td>
                      <ModalButton
                        caption="Edit"
                        onClick={() => {
                          setSelectedCharge(charge);
                        }}
                        modalId="chargesModal"
                      />
                    </td>
                    <td>
                      <TableButton
                        caption="Delete"
                        onClick={() => {
                          confirmDelete(charge);
                        }}
                        buttonType="danger"
                      />
                    </td>
                    <td>{charge.property ? charge.property.name : '-'}</td>
                    <td>{charge.unit ? charge.unit.description : '-'}</td>
                    <td>{charge.name}</td>
                    <td>{charge.amount}</td>
                    <td>{FormatDate(charge.createdat)}</td>
                    <td>{FormatDate(charge.lastupdated)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Collapsible>
      )}
    </>
  );
};

ChargesTable.propTypes = {
  reLoadTable: PropTypes.bool.isRequired,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default ChargesTable;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import $ from 'jquery';
import Master from '../Master';
import Collapsible from '../Panels/Collapsible';
import Select from '../Select';
import TenantsTable from './TenantsTable';
import Button from '../Buttons';

const TenantsList = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  //   refs
  const propertyRef = useRef(null);
  const unitRef = useRef(null);
  // state
  const history = useHistory();
  const [propertyId, setPropertyId] = useState('');
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [unitId, setUnitId] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [selectedProperty, setSelectedProperty] = useState('');

  useEffect(() => {
    (async () => {
      try {
        let url = `/byuser?status=active&user_id=${user.id}&order={"name":"ASC"}`;
        if (user && user.type === 'admin') {
          url = '?take=1000&where={"status":"active"}&order={"name":"ASC"}';
        }
        const {
          data: { response },
        } = await axios.get(
          `${process.env.REACT_APP_API_URL}properties${url}`,
          requestConfig,
        );
        setProperties(response);
        $(propertyRef.current)
          .select2()
          .on('change', (event) => {
            const { val: property } = event;
            setPropertyId(property);
            setFilterBy('property');
            if (property) {
              setEndpoint(
                `${process.env.REACT_APP_API_URL}property/occupancy?where={"id":"in::${event.val}","status":"active"}`,
              );
            } else {
              setEndpoint(null);
            }
          });
        // caretaker
        if (user.type === 'caretaker') {
          if (response.length > 0) {
            const property = response[0];
            setSelectedProperty(property);
            setPropertyId(property.id);
            setFilterBy('property');
            setEndpoint(
              `${process.env.REACT_APP_API_URL}property/occupancy?where={"id":"in::${property.id}","status":"active"}`,
            );
          }
        }
      } catch (error) {
        //
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (propertyId) {
          const where = [{ property_id: propertyId, status: 'available' }];
          const url = `${
            process.env.REACT_APP_API_URL
          }units?where=${JSON.stringify(where)}`;
          const {
            data: { response },
          } = await axios.get(url, requestConfig);
          setUnits(response);
          $(unitRef.current)
            .select2()
            .on('change', (event) => {
              setUnitId(event.val);
              setFilterBy('unit');
              if (event.val) {
                setEndpoint(
                  `${process.env.REACT_APP_API_URL}tenants?unit_id=${event.val}&status=active`,
                );
              } else {
                setEndpoint(null);
              }
            });
        }
      } catch (error) {
        //
      }
    })();
  }, [propertyId]);

  function newTenant() {
    if (selectedProperty) {
      const obj = {
        id: selectedProperty.id,
        name: selectedProperty.name,
      };
      if (typeof Storage !== 'undefined') {
        window.localStorage.setItem('property', JSON.stringify(obj));
        window.localStorage.setItem('source', 'tenantslist');
      }
      history.push({
        pathname: '/newtenant',
        property: selectedProperty,
        source: 'tenantslist',
      });
    }
  }

  return (
    <Master redirectLink="tenantslist" title="Reports" source="Tenants List">
      <>
        {user.type === 'caretaker' && (
          <div style={{ textAlign: 'right' }}>
            <Button
              caption="Add New Tenant"
              cssClass="btn-success"
              onClick={newTenant}
              type="button"
              isBlack={false}
            />
          </div>
        )}
        <div className="row">
          {user.type !== 'caretaker' && (
            <>
              <Select
                caption="Filter By Property Name"
                value={propertyId}
                onChange={(event) => {
                  setPropertyId(event.target.value);
                }}
                columnWidth={6}
                inputRef={propertyRef}
              >
                <option value="">Select property name</option>
                {properties.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
              <Select
                caption="Filter By Unit Description"
                value={unitId}
                onChange={(event) => {
                  setUnitId(event.target.value);
                }}
                columnWidth={6}
                inputRef={unitRef}
              >
                <option value="">Select unit description</option>
                {units.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {`${item.type} - ${item.description}`}
                    </option>
                  );
                })}
              </Select>
            </>
          )}
        </div>
      </>
      {endpoint && (
        <div className="row">
          <Collapsible
            columnWidth={12}
            caption="Tenants List"
            controlId="tList"
          >
            <TenantsTable
              endpoint={endpoint}
              userToken={userToken}
              filterBy={filterBy}
            />
          </Collapsible>
        </div>
      )}
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

TenantsList.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

TenantsList.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(TenantsList);

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import $ from 'jquery';

import Master from '../Master';
import Select from '../Select';
import IziToast from '../IziToast';
import Button from '../Buttons';
import Collapsible from '../Panels/Collapsible';
import { ExportTable, FormatDate } from '../../utils/Functions';
import InfoPanel from '../Panels/InfoPanel';
import Table from '../Table';
import TableButton from '../Buttons/TableButton';
import iziQuestion from '../IziToast/question';

const Access = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };

  //   refs
  const propertyRef = useRef(null);
  const assigneeRef = useRef(null);
  // state
  const [propertyId, setPropertyId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [assigneeId, setAssigneeId] = useState('');
  const [assignees, setAssignee] = useState([]);
  const [propertyAccess, setPropertyAccess] = useState([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    (async () => {
      // properties
      let urlProperty = `/byuser?status=active&user_id=${user.id}`;
      if (user && user.type === 'admin') {
        urlProperty =
          '?take=1000&where={"status":"active"}&order={"name": "ASC"}';
      }
      try {
        const {
          data: { response: propertyInfo },
        } = await axios.get(
          `${process.env.REACT_APP_API_URL}properties${urlProperty}`,
          requestConfig,
        );
        setProperties(propertyInfo);
        $(propertyRef.current)
          .select2()
          .on('change', (event) => {
            setPropertyId(event.val);
          });
        // users
        const urlUsers = `${process.env.REACT_APP_API_URL}users?status=active`;
        const {
          data: { response: usersInfo },
        } = await axios.get(urlUsers, requestConfig);
        setAssignee(usersInfo);
        $(assigneeRef.current)
          .select2()
          .on('change', (event) => {
            const { val: userId } = event;
            setAssigneeId(userId);
            if (userId) {
              getPropertyAccess(userId, propertyInfo, usersInfo);
            } else {
              setPropertyAccess([]);
            }
          });
      } catch (error) {
        IziToast('Could not load your properties');
        setHasError(true);
      }
    })();
  }, []);

  useEffect(() => {
    window.setTimeout(() => {
      ExportTable('access_table', 'access');
    });
  }, [isLoading]);

  async function getPropertyAccess(userId, propertyInfo, userInfo) {
    try {
      setIsLoading(true);
      const url = `${process.env.REACT_APP_API_URL}propertyaccess?user_id=${userId}`;
      const {
        data: { response },
      } = await axios.get(url, requestConfig);
      // map other info
      response.map((item) => {
        const access = item;
        access.property = findProperty(item.property_id);
        access.user = findUser(item.user_id);
        return access;
      });
      setPropertyAccess(response);
    } catch (error) {
      IziToast('Could not load property access');
      setHasError(true);
    } finally {
      setIsLoading(false);
    }

    function findProperty(id) {
      return propertyInfo.find((item) => {
        return item.id === id;
      });
    }

    function findUser(id) {
      return userInfo.find((item) => {
        return item.id === id;
      });
    }
  }

  const savePropertyAccess = async () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (!propertyId || !assigneeId) {
      message = 'Select assignment details';
      isValid = false;
      IziToast(message);
    } else {
      isValid = true;
    }
    if (isValid) {
      const saveObject = {
        user_id: assigneeId,
        user_type: assignees.find((item) => {
          return item.id === assigneeId;
        }).type,
        property_id: propertyId,
      };
      IziToast('Saving...');
      try {
        const {
          data: { response },
        } = await axios.post(
          `${process.env.REACT_APP_API_URL}propertyaccess`,
          saveObject,
          requestConfig,
        );
        if (response.id) {
          IziToast('Property access successfully saved');
          getPropertyAccess(assigneeId, properties, assignees);
          resetFields();
        }
      } catch (error) {
        let errMessage = 'An error occurred';
        if (error) {
          if (error.response) {
            const { response } = error;
            if (response) {
              const { data } = response;
              if (data) {
                const { error: err } = data;
                errMessage = err;
              }
            }
          }
        }
        IziToast(errMessage);
      }
    }
  };

  const resetFields = () => {
    setAssigneeId('');
    setPropertyId('');
    setIsSubmitting(false);
  };

  const revokeAccess = async (access) => {
    try {
      if (access && access.id) {
        const {
          data: { response },
        } = await axios.delete(
          `${process.env.REACT_APP_API_URL}propertyaccess?id=${access.id}`,
          requestConfig,
        );
        if (response) {
          getPropertyAccess(access.user_id, properties, assignees);
          IziToast('Selected access successfully revoked');
        } else {
          IziToast('Selected access not revoked');
        }
      }
    } catch (error) {
      IziToast('An error occurred');
    }
  };

  const onConfirmRevoke = (access) => {
    let name;
    if (access) {
      if (access.property) {
        const { property } = access;
        if (property) {
          name = property.name;
        }
      }
    }
    iziQuestion(
      `Revoke access to <i><b>${name || '-'}</b></i> completely ?`,
      () => {
        revokeAccess(access);
      },
      undefined,
    );
  };

  return (
    <Master
      redirectLink="propertyaccess"
      title="Settings & Configuration"
      source="Property Access"
    >
      <div className="row">
        <Select
          caption="Select Property"
          value={propertyId}
          onChange={(event) => {
            setPropertyId(event.target.value);
          }}
          columnWidth={4}
          className={
            isSubmitting && assigneeId === 'property' && !propertyId
              ? 'error'
              : ''
          }
          inputRef={propertyRef}
        >
          <option value="">Select property name</option>
          {properties.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </Select>

        <Select
          caption="Select Assignee"
          value={assigneeId}
          onChange={(event) => {
            setAssigneeId(event.target.value);
          }}
          columnWidth={5}
          className={isSubmitting && !assigneeId ? 'error' : ''}
          inputRef={assigneeRef}
        >
          <option value="">Select Assignee</option>
          {assignees.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {`${item.first_name} ${item.last_name} - ${item.email || ''}`}
              </option>
            );
          })}
        </Select>
        <div className="col-md-3 inline-right-button">
          <Button
            caption="Save Access"
            cssClass="btn-success"
            onClick={savePropertyAccess}
            type="button"
            isBlack={false}
          />
        </div>
      </div>
      <Collapsible
        caption="Property Access List"
        columnWidth={12}
        controlId="access_list"
      >
        {isLoading ? (
          <InfoPanel message="Loading..." />
        ) : hasError ? (
          <InfoPanel message="An error occurred" hasError />
        ) : (
          <>
            {propertyAccess.length > 0 ? (
              <Table tableId="access_table" marginTop={10}>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Revoke</th>
                    <th>Property</th>
                    <th>Assignee</th>
                    <th>User Type</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>
                  {propertyAccess.map((access, id) => {
                    let index = id;
                    let property;
                    let assignee;
                    let userType;
                    let createdat;
                    if (access.property) {
                      const { property: _property } = access;
                      if (_property) {
                        property = _property.name;
                      }
                    }
                    if (access.user) {
                      const { user: _assignee } = access;
                      if (_assignee) {
                        assignee = `${_assignee.first_name} ${_assignee.last_name}`;
                        userType = _assignee.type;
                        createdat = _assignee.createdat;
                      }
                    }
                    return (
                      <tr key={access.id}>
                        <td>{++index}</td>
                        <td>
                          <TableButton
                            caption="Revoke"
                            onClick={() => {
                              onConfirmRevoke(access);
                            }}
                            buttonType="danger"
                          />
                        </td>
                        <td>{property}</td>
                        <td>{assignee}</td>
                        <td>{userType}</td>
                        <td>{FormatDate(createdat)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <InfoPanel message="No Access Granted" />
            )}
          </>
        )}
      </Collapsible>
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

Access.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Access.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(Access);

import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const RedCard = ({ icon, amount, caption, strongText }) => {
  return (
    <div className="col-lg-3">
      <div className="hpanel red-card">
        <div className="panel-body h-200 red-card">
          <h1 className="">{amount}</h1>
          <div className="card-row row">
            <h1 className="pull-left card-body-text">Rent</h1>
            <div className="pull-right">
              <i className={`pe-7s-${icon} fa-4x`} />
            </div>
          </div>
          <div className="row card-row card-footer">
            <span className="stats-tile-caption">
              {caption}
              <strong>{` ${strongText}`}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

RedCard.propTypes = {
  icon: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  caption: PropTypes.string.isRequired,
  strongText: PropTypes.string.isRequired,
};

export default RedCard;

import React, { useState, useRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ValidateEmail } from '../../utils/Functions';
import IziToast from '../IziToast';
import Master from '../Master';
import TextInput from '../TextInput';
import UserTypes from '../../data/UserTypes';
import Select from '../Select';
import Button from '../Buttons';
import Collapsible from '../Panels/Collapsible';

import UsersTable from './UsersTable';

const Users = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  // state
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [userType, setUserType] = useState(
    user && user.type === 'admin' ? '' : 'caretaker',
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // refs
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const userNameRef = useRef(null);
  const passwordRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const userTypeRef = useRef(null);

  const saveUser = async () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (
      firstName &&
      lastName &&
      phone &&
      email &&
      userType &&
      userName &&
      password
    ) {
      if (!ValidateEmail(email)) {
        isValid = false;
        message = 'Enter a valid email address';
        IziToast(message);
        emailRef.current.focus();
      } else {
        isValid = true;
      }
      if (phone.length !== 12) {
        message = 'Enter a valid phone number';
        IziToast(message);
        phoneRef.current.focus();
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (isValid) {
      const userObject = {
        type: userType,
        first_name: firstName,
        last_name: lastName,
        username: userName,
        email,
        phone: `+${phone}`,
        password,
      };
      IziToast('Saving...');
      try {
        const {
          data: { response },
        } = await axios.post(
          `${process.env.REACT_APP_API_URL}user`,
          userObject,
          requestConfig,
        );
        if (response.id) {
          IziToast('User successfully saved');
          resetFields();
        }
        setIsLoading(!isLoading);
      } catch (error) {
        if (error) {
          if (error.response) {
            if (error.response.data) {
              IziToast(error.response.data.error);
            }
          }
        } else {
          IziToast('An error occurred');
        }
      }
    }
  };

  const resetFields = () => {
    setUserName('');
    setFirstName('');
    setLastName('');
    setPassword('');
    setEmail('');
    setPhone('');
    setUserType('');
    setIsSubmitting(false);
  };

  return (
    <Master redirectLink="users" title="Settings & Configuration" source="User">
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter first name"
          caption="First Name"
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
          value={firstName}
          columnWidth={3}
          inputRef={firstNameRef}
          className={isSubmitting && !firstName ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="Enter last name"
          caption="Last Name"
          onChange={(event) => {
            setLastName(event.target.value);
          }}
          value={lastName}
          columnWidth={3}
          inputRef={lastNameRef}
          className={isSubmitting && !lastName ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="254712345678"
          caption="Telephone Number (254712345678)"
          pattern="[0-9]*"
          onChange={(e) => {
            const numbers = /^[0-9\b]+$/;
            const { value } = e.target;
            // allows number only
            if (value === '' || numbers.test(value)) {
              setPhone(value);
            }
          }}
          value={phone}
          columnWidth={3}
          inputRef={phoneRef}
          className={isSubmitting && phone.length !== 12 ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="Enter email"
          caption="Email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          value={email}
          columnWidth={3}
          inputRef={emailRef}
          className={
            isSubmitting && (!email || !ValidateEmail(email)) ? 'error' : ''
          }
        />
      </div>

      <div className="row">
        <Select
          caption="User Type"
          value={userType}
          onChange={(event) => {
            setUserType(event.target.value);
          }}
          columnWidth={3}
          inputRef={userTypeRef}
          className={isSubmitting && !userType ? 'error' : ''}
        >
          {user && user.type === 'admin' ? (
            <>
              <option value="">Select</option>
              {UserTypes.map((item) => {
                return <option key={item.id}>{item.name}</option>;
              })}
            </>
          ) : (
            <>
              <option value="caretaker">caretaker</option>
            </>
          )}
        </Select>

        <TextInput
          type="text"
          placeholder="Enter user name"
          caption="User Name"
          onChange={(event) => {
            setUserName(event.target.value);
          }}
          value={userName}
          columnWidth={3}
          inputRef={userNameRef}
          className={isSubmitting && !userName ? 'error' : ''}
        />
        <TextInput
          type="password"
          caption="Enter password"
          placeholder="******"
          required=""
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          columnWidth={3}
          inputRef={passwordRef}
          className={isSubmitting && !password ? 'error' : ''}
        />
        <div className="col-md-3 inline-right-button">
          <Button
            caption="Create User"
            cssClass="btn-success"
            onClick={saveUser}
            type="button"
            isBlack={false}
          />
        </div>
      </div>

      <Collapsible caption="Users List" columnWidth={12} controlId="users_list">
        <UsersTable reLoadUsers={isLoading} requestConfig={requestConfig} />
      </Collapsible>
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

Users.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Users.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(Users);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';

import UpdateModal from '../Modals/UpdateModal';
import IziToast from '../IziToast';
import TextInput from '../TextInput';

const UpdateCharges = ({ requestConfig, reLoadTable, selectedCharge }) => {
  // state
  const [chargeName, setChargeName] = useState('');
  const [chargeAmount, setChargeAmount] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // copying state from props is anti-pattern but in our case we're not relying on props update
  useEffect(() => {
    if (selectedCharge) {
      setChargeName(selectedCharge.name);
      setChargeAmount(selectedCharge.amount);
    }
  }, [selectedCharge]);

  const updateCharges = () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (chargeName && chargeAmount) {
      isValid = true;
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (parseFloat(chargeAmount) <= 0) {
      message = 'Enter valid charge amount';
      IziToast(message);
      isValid = false;
    } else {
      isValid = true;
    }
    if (isValid) {
      const chargesObject = {
        id: selectedCharge.id,
        name: chargeName,
        amount: chargeAmount,
      };
      IziToast('Updating...');
      axios
        .put(
          `${process.env.REACT_APP_API_URL}charges`,
          chargesObject,
          requestConfig,
        )
        .then(() => {
          // api doesn't give us status code or useful messages
          // if (response.data.code === 200) {
          IziToast('Charge successfully updated');
          $('#chargesModal').modal('hide');
          // }
          // setIsLoading(!isLoading);
          if (reLoadTable) reLoadTable();
        })
        .catch((error) => {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                IziToast(error.response.data.error);
              }
            }
          } else {
            IziToast('An error occurred');
          }
        });
    }
  };

  return (
    <UpdateModal
      modalId="chargesModal"
      onClick={updateCharges}
      header="Update charges"
    >
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter charge name"
          caption="Charge Name"
          onChange={(event) => {
            setChargeName(event.target.value);
          }}
          value={chargeName}
          columnWidth={8}
          className={isSubmitting && !chargeName ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="Enter charge amount"
          caption="Charge Amount"
          onChange={(e) => {
            const value = /^[0-9\b]+$/;
            // allows number only
            if (e.target.value === '' || value.test(e.target.value)) {
              setChargeAmount(e.target.value);
            }
          }}
          value={chargeAmount}
          columnWidth={4}
          className={
            (isSubmitting && !chargeAmount) || parseFloat(chargeAmount) <= 0
              ? 'error'
              : ''
          }
        />
      </div>
    </UpdateModal>
  );
};

UpdateCharges.propTypes = {
  requestConfig: PropTypes.objectOf(PropTypes.object),
  selectedCharge: PropTypes.oneOfType([PropTypes.object]),
  reLoadTable: PropTypes.func.isRequired,
};

UpdateCharges.defaultProps = {
  requestConfig: {},
  selectedCharge: {},
};

export default UpdateCharges;

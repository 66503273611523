/**
 *@name spoiler-alert
 *@description I have no idea what i am doing here don't @ me if something doesn't work :(
 *@author https://bwanamaina.com/
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';

import { MercuryID } from '../../utils/Functions';
import Master from '../Master';
import PropertyTile from './PropertyTile';
import IziToast from '../IziToast';
import InfoPanel from '../Panels/InfoPanel';
import Button from '../Buttons';
import PropertyAccess from '../../data/AccessRights';

const Properties = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  const history = useHistory();
  const tableRef = useRef(null);

  // state
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.setTimeout(() => {
      $(tableRef.current).DataTable();
    });
  }, [properties]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (user.type !== 'admin') {
        const url = `${process.env.REACT_APP_API_URL}properties/byuser?user_id=${user.id}&take=1000&where={"status":"active"}&order={"name": "ASC"}`;
        try {
          const {
            data: { response },
          } = await axios.get(url, requestConfig);
          if (response.length) {
            loadProperties(response);
          } else {
            setProperties([]);
            IziToast('Seems you have no properties');
            setIsLoading(false);
          }
        } catch (error) {
          //
        }
      } else {
        loadProperties();
      }
    })();
  }, []);

  async function loadProperties(userProperty = []) {
    let url;
    if (user.type === 'admin') {
      url = `${process.env.REACT_APP_API_URL}property/occupancy?take=1000&where={"status":"active"}&order={"name":"ASC"}`;
    } else {
      const filtered = userProperty
        .map((item) => {
          return item.id;
        })
        .join();
      url = `${process.env.REACT_APP_API_URL}property/occupancy?take=1000&where={"id":"in::${filtered}","status":"active"}&order={"name":"ASC"}`;
    }
    try {
      const {
        data: { response: property },
      } = await axios.get(url, requestConfig);
      const chunked = [];
      const size = 3;
      for (let i = 0, j = property.length; i < j; i += size) {
        const temporary = property.slice(i, i + size);
        if (temporary.length === 1) {
          temporary.push(undefined);
          temporary.push(undefined);
        }
        if (temporary.length === 2) {
          temporary.push(undefined);
        }
        chunked.push(temporary);
      }
      setProperties(chunked);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  return (
    <Master
      redirectLink="property"
      title="Property Management"
      source="Property"
    >
      {user && PropertyAccess.includes(user.type) && (
        <div style={{ marginBottom: '10px', textAlign: 'right' }}>
          <Button
            caption="Create New Property"
            cssClass="btn-success"
            onClick={() => {
              history.push({
                pathname: '/newproperty',
                // property: undefined,
              });
            }}
            type="button"
            isBlack={false}
          />
        </div>
      )}
      {isLoading ? (
        <InfoPanel message="Loading..." />
      ) : properties.length ? (
        <div className="table-responsive" style={{ marginTop: '10px' }}>
          <table className="table table-bordered" id="property" ref={tableRef}>
            <thead>
              <tr>
                <th className="hidden">{null}</th>
                <th className="hidden">{null}</th>
                <th className="hidden">{null}</th>
              </tr>
            </thead>
            <tbody>
              {properties.map((chunked) => {
                return (
                  <tr key={MercuryID()}>
                    {chunked.map((chunk) => {
                      return (
                        <td key={MercuryID()}>
                          <PropertyTile
                            userToken={userToken}
                            key={chunk ? chunk.id : MercuryID()}
                            property={chunk || {}}
                          />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <InfoPanel message="Seems you have no properties" />
      )}
    </Master>
  );
};

Properties.propTypes = {};

const mapStateToProps = (state) => {
  return state;
};

Properties.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default connect(mapStateToProps)(Properties);

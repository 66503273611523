/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import noImage from '../../img/no-image.png';
import './style.css';
import ButtonLink from '../Buttons/ButtonLink';

const PropertyTile = ({ property, userToken }) => {
  // variables
  let imageSource = noImage;
  let occupiedUnits = 0;
  let vacantUnits = 0;
  let occupancy = 0;
  let totalUnits = 0;
  const history = useHistory();

  // total units
  if (property && property.units) {
    totalUnits = property.units;
  }
  // occupied units
  if (property && property.occupied_units) {
    occupiedUnits = property.occupied_units;
  }

  // vacant units
  if (property && property.vacant_units) {
    vacantUnits = property.vacant_units;
  }

  // occupancy
  if (totalUnits > 0) {
    occupancy = 100 * (occupiedUnits / totalUnits).toFixed(2);
  }

  // profile image
  if (property && property.images) {
    const { images } = property;
    if (images && images.length) {
      imageSource = images[0].link;
    }
  }

  function saveLocalStorage() {
    const obj = {
      id: property.id,
      name: property.name,
    };
    if (typeof Storage !== 'undefined') {
      window.localStorage.setItem('property', JSON.stringify(obj));
    }
  }

  const shortenName = (name) => {
    const strName = String(name);
    const lengthEnd = 50;
    if (strName.length > lengthEnd) {
      return `${strName.substring(
        strName.length,
        strName.length - lengthEnd,
      )}...`;
    }
    return strName;
  };

  return property && property.id ? (
    <div className="col-md-12">
      <div className="hpanel tile-wrapper">
        <div className="panel-body" style={{ padding: '10px' }}>
          <div className="row">
            <div className="col-md-5 property-photo-row">
              <img
                alt={property.name}
                className="bordered_photo property-photo"
                src={imageSource}
              />
            </div>
            <div className="col-md-7 tile-content">
              <div className="property-name">
                <strong>
                  <ButtonLink
                    caption={shortenName(property.name)}
                    onClick={() => {
                      if (typeof Storage !== 'undefined') {
                        window.localStorage.setItem(
                          'property',
                          JSON.stringify(property),
                        );
                      }
                      history.push({
                        pathname: '/propertyprofile',
                        property,
                        userToken,
                      });
                    }}
                  />
                </strong>
              </div>
              <div>
                <i
                  className="pe-7s-map-marker"
                  style={{ marginRight: '10px' }}
                />
                <span>{shortenName(property.address)}</span>
              </div>
              <div>
                <span>Total Units : </span>
                <span className="label label-info">{property.units}</span>
              </div>
              <div className="progress m-t-xs full progress-striped active">
                <div
                  style={{ width: `${occupancy}%` }}
                  aria-valuemax="100"
                  aria-valuemin="0"
                  aria-valuenow={occupancy}
                  role="progressbar"
                  className=" progress-bar progress-bar-info"
                >
                  {`${occupancy}%`}
                </div>
              </div>
              <div>
                <span className="label label-success">{occupiedUnits}</span>
                <span style={{ marginLeft: '10px' }}>Occupied</span>
                <div className="pull-right">
                  <span className="label label-warning">{vacantUnits}</span>
                  <span style={{ marginLeft: '10px' }}>Vacant</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="panel-footer"
          style={{ paddingTop: '0', paddingBottom: '0' }}
        >
          <div className="row tile-footer">
            <div
              onClick={() => {
                saveLocalStorage();
                history.push({
                  pathname: '/occupancy',
                  property,
                  userToken,
                });
              }}
              className="col-md-6 border-right"
            >
              <div className="contact-stat">Tenants</div>
            </div>
            <div
              onClick={() => {
                saveLocalStorage();
                history.push({
                  pathname: '/unitslist',
                  property,
                  userToken,
                });
              }}
              className="col-md-6"
              style={{ paddingRight: '0', marginRight: '0' }}
            >
              <div className="contact-stat">Units</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="col-md-4" />
  );
};

PropertyTile.propTypes = {
  property: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  userToken: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
};

export default PropertyTile;

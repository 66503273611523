import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import $, { data } from 'jquery';

import Master from '../Master';
import AlertMessage from '../Alert';
import AlertActions from '../../actions/alert';
import DatePicker from '../DatePicker';
import Button from '../Buttons';
import Select from '../Select';
import Collapsible from '../Panels/Collapsible';

import MonthlyReportTable from './MonthlyReportTable';
import { FormatDate, DestroyTable } from '../../utils/Functions';
import IziToast from '../IziToast';
import { filter } from 'lodash';
 
const MonthlyReport = ({ user, dispatch, alert }) => {

//   refs
const propertyRef = useRef(null);
const invoiceNoRef = useRef(null);
const unitRef = useRef(null);
const descriptionRef = useRef(null);
const tenantRef = useRef(null);
const statementTypeRef = useRef(null);

// state
const today = new Date();
const [total, setTotal] = useState(0);
const firstDay = new Date(today.getFullYear(), today.getMonth() -1, 1);
const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
const [lowerDate, setLowerDate] = useState(firstDay);
const [upperDate, setUpperDate] = useState(lastDay);
const [properties, setProperties] = useState([]);
const [propertyId, setPropertyId] = useState('');
const [units, setUnits] = useState([]);
const [tenantsList, setTenantsList] = useState([]);
const [tenants, setTenants] = useState([]);
const [alltenants, setAlltenants] = useState([]);
const [tenantId, setTenantId] = useState('');
const [unitNo, setUnitNo] = useState();
const [arrears, setArrears] = useState([]);
const [arrears_all, setArrearsAll] = useState([]);
const [unitNumbers, setUnitNumbers] = useState([]);
const [statement_type, setStatementType] = useState(0);

const [invoiceNumbers, setInvoiceNumbers] = useState([]);
const [invoiceNo, setInvoiceNo] = useState('');

const [descriptions, setDescriptions] = useState([])
const [description, setDescription] = useState('')

// Table states
const [isLoading, setIsLoading] = useState(false);
const [error, setError] = useState(null);


const userToken = user ? user.token : {};
const statement_types = [
  {name: "Tenant Statement", value: 0}, 
  {name: "Landlord Statement", value: 1}
]

const requestConfig = {
headers: {
    Authorization: `Bearer ${userToken}`,
    'Content-Type': 'application/json',
},
};


useEffect(() => {
  dispatch(AlertActions.clear());
}, [dispatch]);

useEffect(()=> {
  filterLocally({
    invoiceNo: null,
    unitNo: unitNo,
    descText: null
  });
}, [unitNo]);

useEffect(() => {
    (async () => {
      try {
        let url = `/byuser?status=active&user_id=${user.id}`;
        if (user && user.type === 'admin') {
          url = '?take=1000&where={"status":"active"}&order={"name":"ASC"}';
        }
        const {
          data: { response },
        } = await axios.get(
          `${process.env.REACT_APP_API_URL}properties${url}`,
          requestConfig,
        );

        setProperties(response);
        loadUnits(response);

        $(propertyRef.current)
          .select2()
          .on('change', (event) => {
            setPropertyId(event.val);
        });
          
      } catch (_) {
        //
      }
    })();
  }, []);

useEffect(() => {
  filterUniqueUnits(propertyId)
},[propertyId]);


useEffect(()=> {
  invoiceFilter(invoiceNo)
}, [invoiceNo])

useEffect(()=> {
  descriptionFilter(description)
}, [description])

useEffect(() => {
  
  if (arrears.length > 0){
    setTotal(arrears[arrears.length-1].balance)
  }
   else {
    setTotal(0);
  }
}, [arrears]);

useEffect(()=> {
  $(statementTypeRef.current)
    .select2()
    .on('change', (event) => {
      if (event.val != 1){
        setStatementType(0)
      }else{
        setStatementType(event.val);
      }
  });
}, [statement_types])

async function filterUniqueUnits(property_id){

  (async () =>{
    try{
    const filtered =  alltenants.filter((item)=>{
      return item.unit.property_id == property_id && item.other_details;
    })

    setTenants(filtered);

    const unique =  [...new Set(filtered.map(item => item.other_details.unit_number))];
    setUnitNumbers(unique);

    $(unitRef.current)
      .select2()
      .on('change', (event) => {
        setUnitNo(event.val);
  });

  }catch(_){
  }
  })()

}

async function descriptionFilter(description_text){
  setDescription(description_text)
  filterLocally({invoiceNo:null, unitNo:null, descText:description_text})
}

async function onSearchInvoice(){
  setInvoiceNo('');
  setDescription('');
  setUnitNo('');
  setIsLoading(true);

  await loadArrears();
  await filterLocally({invoiceNo:null, unitNo:null, descText:null});
  setIsLoading(false);

}


async function invoiceFilter(invoiceNo){
  (async () => {
    try{
      filterLocally({invoiceNo:invoiceNo, unitNo:null, descText:null});
     
    }catch(_){

    }
  })()
}


async function filterLocally(
  object=null
  ){
  
  const invoiceNumber = object.invoiceNo;
  let unitNumber = object.unitNo;
  const descText = object.descText;

  DestroyTable('arrears_table')

  if(!unitNumber){
    unitNumber = unitNo;
  }

  if(unitNumber){

    if(invoiceNo || descText) {
   
    const filtered = arrears.filter((item)=>{
      return item.houseUnit === unitNumber
    })
    setArrears(filtered)

  } else {

    const filtered = arrears_all.filter((item)=>{
      return item.houseUnit === unitNumber
    })
    setArrears(filtered)

  }
  }


  if(invoiceNumber){
    if(unitNumber || descText){

      const filtered = arrears.filter((item)=>{
        return item.invoiceNo == invoiceNumber
      })
      setArrears(filtered);

    }
    else {
    
      const filtered = arrears_all.filter((item)=>{
        return item.invoiceNo == invoiceNumber
      })
      setArrears(filtered)
    }
  }


  if(descText){

    if(unitNumber || invoiceNo){

    console.log("Filtering by desc")
    const filtered = arrears.filter((item)=>{
      return item.description == descText
    })
    setArrears(filtered);

  } else {

    const filtered = arrears.filter((item)=>{
      return item.description == descText
    })
    setArrears(filtered);

  }
  }


}

/**
 * 
 *
 */
async function loadArrears(){
if(propertyId){
  let url = ``
  tenantId ? url = `invoices/statement?where={"invoice_date":"btwn::${FormatDate(lowerDate)},${FormatDate(upperDate)}", "payee_id":"${propertyId}", "payer_id":"${tenantId}"}` :
  url = `invoices/statement?where={"invoice_date":"btwn::${FormatDate(lowerDate)},${FormatDate(upperDate)}", "payee_id":"${propertyId}"}`
  let requestConfig = {}
  statement_type == 0 ? requestConfig = {
    headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
    },
  } : requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      withcommission: true,
      "Content-Type": "application/json",
  },

  }
  const {
    data: { 
      response 
    }
  } = await axios.get(
    `${process.env.REACT_APP_API_URL}${url}`,
    requestConfig,
  )

  setArrears(response);
  setArrearsAll(response);
  getDescAndInvoiceNumbers(response);

  }else{
    dispatch(AlertActions.info('Select property to continue'));
  }

}


async function getDescAndInvoiceNumbers(arrears){
  (async () => {
    try{
      const filtered =  arrears.filter((item)=>{
        return item.invoiceNo !=null;
      })

      const unique =  [...new Set(filtered.map(item => item.invoiceNo))]
      setInvoiceNumbers(unique);

      const filtered2 =  arrears.filter((item)=>{
        return item.description !=null;
      })

      const unique2 =  [...new Set(filtered2.map(item => item.description))]
      setDescriptions(unique2);
       
      $(invoiceNoRef.current)
        .select2()
        .on('change', (event) => {
          setInvoiceNo(event.val);
      });

      $(descriptionRef.current)
        .select2()
        .on('change', (event)=> {
          setDescription(event.val);
        })
  }catch(_){}
})()

}
 /**
   *
   * @param {[]} property
   */
  async function loadUnits(property) {
    const propertyIds = property.map((item) => {
      return item.id;
    });
    let urlUnits;
    // get units
    if (user.type === 'admin') {
      urlUnits = `${process.env.REACT_APP_API_URL}units?take=10000`;
    } else {
      urlUnits = `${
        process.env.REACT_APP_API_URL
      }units?take=10000&where={"property_id":"in::${propertyIds.join()}"}`;
    }
    try {
      const {
        data: { response: unitsInfo },
      } = await axios.get(urlUnits, requestConfig);
      setUnits(unitsInfo);
      loadTenants(unitsInfo);
        
      $(tenantRef.current)
        .select2()
        .on('change', (event) => {
          setTenantId(event.val);
      });

    } catch (_) {
      //
    }
  }

    /**
   *
   * @param {[]} unitsInfo
   */
  function loadTenants(unitsInfo) {
    function findUnit(id) {
      return unitsInfo.find((item) => {
        return item.id === id;
      });
    }
    (async () => {
      try {
        const unitIds = unitsInfo.map((item) => {
          return item.id;
        });
        let urlTenants;
        // get tenats
        if (user.type === 'admin') {
          urlTenants = `${process.env.REACT_APP_API_URL}tenants?take=10000`;
        } else {
          urlTenants = `${
            process.env.REACT_APP_API_URL
          }tenants?take=10000&where={"unit_id":"in::${unitIds.join()}"}`;
        }
        const {
          data: { response },
        } = await axios.get(urlTenants, requestConfig);
        const mapped = response.map((item) => {
          const element = item;
          element.unit = findUnit(element.unit_id);
          return element;
        });
        setTenants(mapped);
        setAlltenants(mapped);
        setTenantsList(mapped);
        
      } catch (_) {
        //
      }
    })();
 }

return (
    <Master redirectLink="monthlyreport" title="Report" source="Account Statement">
        <div className="row">
            <div className="col-md-12">
            {alert && alert.message && (
                <AlertMessage
                alertType={`alert ${alert.type}`}
                message={alert.message}
                />
            )}
            </div>
        </div>
        <div className="row">
        <div className="col-md-9">
          <div className="row">
            <Select
              caption="Property Name"
              value={propertyId}
              onChange={(event) => {
                setPropertyId(event.target.value);
              }}
              columnWidth={4}
              inputRef={propertyRef}
            >
              <option value="">Select property name</option>
              {properties.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
            <Select
              caption="Tenant"
              value={tenantId}
              onChange={(event) => {
                setTenantId(event.target.value);
              }}
              columnWidth={4}
              inputRef={tenantRef}
            >
              <option value="">Select tenant</option>
              {tenants.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {`${item.first_name} ${item.last_name} | ${item.other_details ? item.other_details.unit_number : '--' }`}
                  </option>
                );
              })}
            </Select>
            {/* <Select
              caption="Unit No."
              value={unitNo}
              onChange={(event) => {
                setUnitNo(event.target.value);
              }}
              columnWidth={4}
              inputRef={unitRef}
            >
              <option value="">Select Unit Number</option>
              {unitNumbers.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </Select> */}
            <Select
              caption="Statement Type"
              value={statement_type}
              onChange={(event) => {
                setStatementType(event.target.value)
              }}
              columnWidth={4}
              inputRef={statementTypeRef}
            >
             <option value={statement_types[0].name}>
               {statement_types[0].name}
               </option>
               {statement_types.map((item, index) => {
                 if(index != 0){
                    return(
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    )
                 }
               })}
            </Select>

            <Select
                caption="Invoice Number"
                value={invoiceNo}
                columnWidth={4}
                onChange={(event)=>{
                  setInvoiceNo(event.target.value);
                }}
                inputRef={invoiceNoRef}
                >
                    <option value="">Unit Number</option>
                    {invoiceNumbers.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                  );
              })}
            </Select>

            <Select
                caption="Description"
                value={description}
                columnWidth={4}
                onChange={(event)=>{
                  setDescription(event.target.value)
                }}
                inputRef={descriptionRef}
                >
                    <option value="">Description</option>
                    {descriptions.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                  );})}
            </Select>
             
          </div>
          <div className="row">
            <DatePicker
              placeholder="Select date from"
              caption="Date From"
              onChange={(date) => {
                return setLowerDate(date);
              }}
              columnWidth={2}
              selected={lowerDate}
            />
            <DatePicker
              placeholder="Select date to"
              caption="Date To  &nbsp; &nbsp;"
              onChange={(date) => {
                return setUpperDate(date);
              }}
              columnWidth={2}
              selected={upperDate}
            />
            <div className="col-md-3 invoice-button">
              <Button
                caption="Search"
                cssClass="btn-success"
                onClick={onSearchInvoice}
                type="button"
                isBlack={false}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="hpanel">
            <div className="panel-body">
              <span>Total Balance Due:</span>
              <br />
              <h1 className="primary-red">{ total < 0 ? `Ksh (${(-total).toLocaleString()})` : `Ksh ${total.toLocaleString()}`}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <Collapsible columnWidth={12} caption="Monthly Report Table" controlId="invoices">
          <MonthlyReportTable
            isLoading={isLoading}
            error={error}
            arrears={arrears}
          />
        </Collapsible>
      </div>
      
    </Master>
)


}
const mapStateToProps = (state) => {
    return state;
  };
  
  MonthlyReport.propTypes = {
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    dispatch: PropTypes.func.isRequired,
    alert: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };
  
  MonthlyReport.defaultProps = {
    user: {},
    alert: {},
  };
  
  export default connect(mapStateToProps)(MonthlyReport);
  
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './style.css';
import Logo from '../../img/nyumbani152.png';
import AlertActions from '../../actions/alert';
import UserActions from '../../actions/user';
import TextInput from '../TextInput';
import Button from '../Buttons';
import CheckBox from '../CheckBox';
import history from '../../helpers/history';
import AlertMessage from '../Alert';
import { ValidateEmail } from '../../utils/Functions';

const SignIn = ({ dispatch, alert }) => {
  // state
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  // refs
  const userNameRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    dispatch(AlertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    if (typeof Storage !== 'undefined') {
      window.localStorage.removeItem('nyumbani');
    }
  }, []);

  const authenticate = (event) => {
    event.preventDefault();
    if (!username) {
      dispatch(AlertActions.error('Enter email to continue'));
      userNameRef.current.focus();
      return;
    }
    if (!ValidateEmail(username)) {
      dispatch(AlertActions.error('Enter valid email to continue'));
      userNameRef.current.focus();
      return;
    }
    if (!password) {
      dispatch(AlertActions.error('Enter password to continue'));
      passwordRef.current.focus();
      return;
    }
    if (username && password) {
      dispatch(UserActions.login(username, password));
    }
  };

  return (
    <div>
      <div className="alert-parent">
        {alert && alert.message && (
          <AlertMessage
            alertType={`alert ${alert.type}`}
            message={alert.message}
          />
        )}
      </div>
      <div className="login-parent">
        <div className="login-left">
          <div className="text-center">
            <img src={Logo} className="logo" alt="nyumbani" />
            <h3>Welcome</h3>
          </div>
          <div className="hpanel">
            <div className="panel-body">
              <form onSubmit={authenticate}>
                <TextInput
                  type="text"
                  placeholder="Enter your email"
                  caption="Please enter your email"
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                  value={username}
                  columnWidth={12}
                  isWrapped={false}
                  inputRef={userNameRef}
                />
                <TextInput
                  type="password"
                  caption="Please enter your password"
                  placeholder="******"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  columnWidth={12}
                  isWrapped={false}
                  inputRef={passwordRef}
                />
                <div className="hidden">
                  <CheckBox
                    caption="Remember me"
                    checked={remember}
                    onClick={() => {
                      setRemember(!remember);
                    }}
                  />
                </div>
                <Button
                  type="submit"
                  cssClass="btn-success btn-block"
                  caption="Log In"
                />
                <button
                  onClick={() => {
                    history.push('/forgotpassword');
                  }}
                  type="button"
                  title="Reset Your Password"
                  style={{
                    color: '#2083FE',
                    float: 'right',
                    marginTop: '25px',
                    border: 'none',
                    backgroundColor: 'inherit',
                  }}
                >
                  Forgot Password
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="login-right">
          <div className="centered">Karibu!</div>
        </div>
      </div>
      <div className="login-footer">
        <div className="pull-left">
          Powered by
          <strong>Ubuni</strong>
          Works
        </div>
        <div className="pull-right">
          {`Copyright ${new Date().getFullYear()} | All Rights Reserved | Nyumbani`}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

SignIn.propTypes = {
  dispatch: PropTypes.func.isRequired,

  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

SignIn.defaultProps = {
  alert: PropTypes.object,
};

export default connect(mapStateToProps)(SignIn);

import React from 'react';
import PropTypes from 'prop-types';
import ViewModal from '../Modals/ViewModal';
import Paragraph from '../Paragraph';
import { FormatDate, MercuryID } from '../../utils/Functions';
import Platforms from '../../data/Platforms';

const ViewProperty = ({ property }) => {
  const paymentTitle = (id) => {
    return Platforms.find((item) => {
      return item.id === id;
    });
  };

  return (
    <ViewModal modalId="mdViewProperty" header="More property details">
      {property && (
        <>
          <div className="row">
            <div className="col-xs-3 bm-responsive-div">
              {property.images && (
                <div className="row">
                  <div className="col-md-12">
                    {property.images.map((image) => {
                      return (
                        <img
                          className="bordered_photo"
                          style={{
                            width: '200px',
                            height: '200px',
                            margin: '5px',
                          }}
                          key={MercuryID()}
                          src={image.link}
                          alt={property.name}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="col-xs-9 bm-responsive-div">
              <div className="row">
                <Paragraph
                  columnWidth={12}
                  content={property.name}
                  caption="Property Name"
                />
              </div>
              <div className="row">
                <Paragraph
                  columnWidth={12}
                  content={property.description}
                  caption="Property Description"
                />
              </div>
              <div className="row">
                <Paragraph
                  columnWidth={12}
                  content={property.address}
                  caption="Property Address"
                />
              </div>
              <div className="row">
                <Paragraph
                  columnWidth={4}
                  content={property.units}
                  caption="Property Units"
                />
              </div>
              <div className="row">
                <Paragraph
                  columnWidth={4}
                  content={FormatDate(property.createdat)}
                  caption="Created On"
                />
                <Paragraph
                  columnWidth={4}
                  content={FormatDate(property.lastupdated)}
                  caption="Updated On"
                />
                {property.other_details && property.other_details && (
                  <Paragraph
                    columnWidth={4}
                    content={property.other_details.currency}
                    caption="Currency"
                  />
                )}
              </div>
              {property.payment_details && (
                <div className="row">
                  {property.payment_details.map((payment) => {
                    return (
                      <>
                        {payment.payment_info && (
                          <>
                            <Paragraph
                              columnWidth={4}
                              key={MercuryID()}
                              content={
                                paymentTitle(payment.payment_info.platform).name
                              }
                              caption="Payment Platform"
                            />
                            <Paragraph
                              columnWidth={4}
                              key={MercuryID()}
                              content={payment.payment_info.account}
                              caption="Paybill Number"
                            />
                          </>
                        )}
                      </>
                    );
                  })}
                  <Paragraph
                    columnWidth={4}
                    content={property.status}
                    caption="Property Status"
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </ViewModal>
  );
};

ViewProperty.propTypes = {
  property: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

ViewProperty.defaultProps = {
  property: {},
};

export default ViewProperty;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import history from './helpers/history';
import AuthGuard from './utils/AuthGuard';
import SignIn from './components/SignIn';
import Dashboard from './components/Dashboard';
import Users from './components/Users';
import Tenants from './components/Tenants';
import Properties from './components/Properties';
import NotFound from './components/NotFound';
import TenantsList from './components/TenantsList';
import Units from './components/Units';
import NewUnit from './components/Units/NewUnit';
import UnitProfile from './components/Units/UnitProfile';
import NewProperty from './components/Properties/NewProperty';
import UnitsList from './components/Properties/UnitsList';
import Occupancy from './components/Properties/Occupancy';
import NewTenant from './components/Tenants/NewTenant';
import TenantProfile from './components/Tenants/TenantProfile';
import Charges from './components/Charges';
import Utilities from './components/Utilities';
import ExtraCharges from './components/ExtraCharges';
import Invoices from './components/Invoices';
import Transactions from './components/Transactions';
import RentRoll from './components/RentRoll';
import Access from './components/Access';
import IsPrivateMode from './utils/BrowserMode';
import RequestReset from './components/Password/RequestReset';
import { Unguarded } from './utils/Unguarded';
import PasswordReset from './components/Password/PasswordReset';
import Receipts from './components/Receipts';
import AuditLogs from './components/AuditLogs';
import PropertyProfile from './components/Properties/PropertyProfile';
import ExtraCharge from './components/ExtraCharge';
import RentArrears from './components/RentArrears';
import LatePayments from './components/LatePayments';
import MonthlyReport from './components/MonthlyReport';

const App = ({ user }) => {
  useEffect(() => {
    if (!Object.keys(user).length > 0) {
      const unguarded = ['/signin', '/forgotpassword', '/passwordreset'];
      const { pathname } = history.location;
      if (!unguarded.includes(pathname)) redirectSigIn();
    }
  });

  function redirectSigIn() {
    history.push('/signin');
  }

  useEffect(() => {
    IsPrivateMode().then((isPrivate) => {
      if (isPrivate) {
        if (typeof Storage !== 'undefined') {
          window.localStorage.removeItem('nyumbani');
          redirectSigIn();
        }
      }
    });
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <AuthGuard path="/" exact component={Dashboard} />
        <AuthGuard path="/users" component={Users} />
        <AuthGuard path="/tenants" component={Tenants} />
        <AuthGuard path="/property" component={Properties} />
        <AuthGuard path="/units" component={Units} />
        <AuthGuard path="/unitslist" component={UnitsList} />
        <AuthGuard path="/newunit" component={NewUnit} />
        <AuthGuard path="/newtenant" component={NewTenant} />
        <AuthGuard path="/occupancy" component={Occupancy} />
        <AuthGuard path="/unitprofile" component={UnitProfile} />
        <AuthGuard path="/tenantprofile" component={TenantProfile} />
        <AuthGuard path="/charges" component={Charges} />
        <AuthGuard path="/utilities" component={Utilities} />
        <AuthGuard path="/tenantslist" component={TenantsList} />
        <AuthGuard path="/extracharges" component={ExtraCharges} />
        <AuthGuard path="/newproperty" component={NewProperty} />
        <AuthGuard path="/invoices" component={Invoices} />
        <AuthGuard path="/transactions" component={Transactions} />
        <AuthGuard path="/latepayments" component={LatePayments} />
        <AuthGuard path="/rentroll" component={RentRoll} />
        <AuthGuard path="/receipts" component={Receipts} />
        <AuthGuard path="/propertyaccess" component={Access} />
        <AuthGuard path="/auditlogs" component={AuditLogs} />
        <AuthGuard path="/extracharge" component={ExtraCharge} />
        <AuthGuard path="/rentarrears" component={RentArrears} />
        <AuthGuard path="/propertyprofile" component={PropertyProfile} />
        <Unguarded path="/signin" component={SignIn} />
        <Unguarded path="/forgotpassword" component={RequestReset} />
        <Unguarded path="/passwordreset" component={PasswordReset} />
        <Unguarded path="/monthlyreport" component={MonthlyReport} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return state;
};

App.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

App.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(App);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useAxiosGet from '../../hooks/useAxiosGet';
import { ExportTable, FormatDate } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';

const TenantsHistory = ({ unit }) => {
  // state
  const unitId = unit ? unit.id : '';

  const url = `${process.env.REACT_APP_API_URL}tenants?unit_id=${unitId}`;
  const tenant_url = `${process.env.REACT_APP_API_URL}tenanthistory?unit_id=${unitId}`;

  const [data, error, isLoading] = useAxiosGet(url, []);
  const [tenant_history_data, tenant_error, isTenantHistoryLoading] = useAxiosGet(tenant_url, []);

  // life-cycle hooks
  useEffect(() => {
    ExportTable('tenants_table', 'units');
  }, [isLoading, isTenantHistoryLoading]);

  useEffect(() => {
    if( (data && data.response && data.response.length) 
      && (tenant_history_data && tenant_history_data.response && tenant_history_data.response.length)) 
      {
          data.response.map( t => {
            tenant_history_data.response.map( h => {
              if(t.id == h.tenant_id){
                const newProps = { moveout_date: h.moveout_date };
                return Object.assign(t, newProps);
              }
            });
          });
      }
  }, [])

  // eslint-disable-next-line no-unused-vars
  function saveLocalStorage() {
    const obj = {
      id: unit.id,
      name: unit.name,
    };
    if (typeof Storage !== 'undefined') {
      window.localStorage.setItem('unit', JSON.stringify(obj));
    }
  }

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      {data && data.response && data.response.length ? (
        <Table tableId="tenants_table" marginTop={10}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Unit No</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Nationality</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>Vacated Date</th>
            </tr>
          </thead>
          <tbody>
            {data.response.map((tenant, id) => {
              let index = id;
              let unitNumber = '';
              const startDate = new Date(+tenant.start_date * 1000);
              if (tenant.other_details) {
                const otherDetails = tenant.other_details;
                unitNumber = otherDetails.unit_number;
              }
              return (
                <tr key={tenant.id}>
                  <td>{++index}</td>
                  <td>{unitNumber}</td>
                  <td>{tenant.first_name}</td>
                  <td>{tenant.last_name}</td>
                  <td>{tenant.email}</td>
                  <td>{tenant.phone}</td>
                  <td>{tenant.nationality}</td>
                  <td>{tenant.status}</td>
                  <td>{FormatDate(startDate)}</td>
                  <td>{ (tenant.hasOwnProperty('moveout_date'))
                        ? FormatDate(new Date(+tenant.moveout_date * 1000)) 
                        : '-'  
                      }</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="Seems no tenants found" />
      )}
    </>
  );
};

TenantsHistory.propTypes = {
  unit: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

TenantsHistory.defaultProps = {
  unit: {},
};

export default TenantsHistory;

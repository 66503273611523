import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import InfoPanel from '../Panels/InfoPanel';
import { FormatDate } from '../../utils/Functions';
import './style.css';
import history from '../../helpers/history';

const TransLogs = ({ user, properties, tenants, units }) => {
  if (!user || !user.token) {
    history.push('/signin');
  }
  // token
  const userToken = user ? user.token : {};

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = React.useRef(null);

  useEffect(() => {
    // executed when component mounted
    isMounted.current = true;
    return () => {
      // executed when unmount
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (properties.length > 0) {
          const tenantsIds = tenants.map((item) => {
            return item.id;
          });
          let filter;
          if (user.type === 'admin') {
            filter = "";
          } else {
            filter = `&where={"tenant_id":"in::${tenantsIds.join()}"}`;
          }
          const url = `${process.env.REACT_APP_API_URL}payments?take=5${filter}&order={"createdat":"DESC"}`;
          const {
            data: { response },
          } = await axios.get(url, requestConfig);
          if (isMounted.current) {
            if (response) {
              const mapped = response.map((receipt) => {
                const element = receipt;
                element.tenant = findTenant(element.tenant_id);
                if (element.reference_data) {
                  const unitId = element.reference_data.unit_id;
                  const unit = findUnit(unitId);
                  if (unit) {
                    const propId = unit.property_id;
                    const property = findProperty(propId);
                    element.property = property;
                  }
                }
                return element;
              });
              setPayments(mapped);
            }
            setIsLoading(false);
          }
        }
      } catch (error) {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
      return () => { };
    })();
  }, []);

  function findTenant(id) {
    return tenants.find((item) => {
      return item.id === id;
    });
  }

  function findProperty(id) {
    return properties.find((property) => {
      return property.id === id;
    });
  }

  function findUnit(id) {
    return units.find((unit) => {
      return unit.id === id;
    });
  }

  let component = null;
  if (isLoading) {
    component = <InfoPanel message="Loading..." />;
  } else if (payments.length > 0) {
    component = (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Time/Date</th>
            <th>Activity</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((receipt) => {
            let propertyName = '';
            let unitNumber = '';
            let tenantName = '';
            if (receipt.property) {
              const { property } = receipt;
              if (property) {
                propertyName = property.name;
              }
            }
            if (receipt.tenant) {
              const { tenant } = receipt;
              if (tenant) {
                tenantName = `${tenant.first_name} ${tenant.last_name}`;
                if (tenant.other_details) {
                  unitNumber = tenant.other_details.unit_number || '';
                }
              }
            }
            return (
              <tr key={receipt.id}>
                <td>{FormatDate(receipt.createdat, true)}</td>
                <td>Payment Received</td>
                <td>{`${tenantName}-${unitNumber}/${propertyName}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  } else {
    component = <InfoPanel message="Seems no transaction found" />;
  }

  return (
    <div className="col-lg-6">
      <div className="hpanel">
        <div className="panel-heading">Transactions Log</div>
        <div className="panel-body list">
          <div className="table-responsive project-list">{component}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

TransLogs.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  tenants: PropTypes.arrayOf(PropTypes.object).isRequired,
  units: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps)(TransLogs);

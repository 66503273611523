import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import useAxiosGet from '../../hooks/useAxiosGet';
import { ExportTable } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import TableButton from '../Buttons/TableButton';
import iziQuestion from '../IziToast/question';
import IziToast from '../IziToast';
import UpdateUser from './UpdateUser';
import ModalButton from '../Buttons/ModalButton';

const UsersTable = ({ reLoadUsers, requestConfig }) => {
  // state
  const [isReLoading, setIsReloading] = useState(false);
  const url = `${process.env.REACT_APP_API_URL}users?take=1000&where={"status":"active"}&order={"first_name": "ASC"}`;
  const [data, error, isLoading] = useAxiosGet(url, [reLoadUsers, isReLoading]);
  const [selectedUser, setSelectedUser] = useState(null);
  // life-cycle hooks
  useEffect(() => {
    ExportTable('users_table', 'users');
  }, [isLoading]);

  const userReload = () => {
    setIsReloading(!isReLoading);
  };
  // const editUser = () => {};

  const deactivateUser = (user) => {
    if (user && user.id) {
      const userObject = {
        id: user.id,
        status: 'disabled',
      };
      axios
        .put(`${process.env.REACT_APP_API_URL}user`, userObject, requestConfig)
        .then((res) => {
          if (res.data.response) {
            setIsReloading(!isReLoading);
            IziToast('Selected user successfully deleted');
          } else {
            IziToast('Selected user not deleted');
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response) {
              if (err.response.data) {
                IziToast(err.response.data.error);
              }
            }
          } else {
            IziToast('An error occurred');
          }
        });
    } else {
      IziToast('Select user to delete');
    }
  };

  const confirmDeactivate = (user) => {
    iziQuestion(
      `Deactivate user <i><b>${user.email}</b></i> completely ?`,
      () => {
        deactivateUser(user);
      },
      undefined,
    );
  };

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      <UpdateUser
        requestConfig={requestConfig}
        reLoadUsers={userReload}
        selectedUser={selectedUser}
      />

      <Table tableId="users_table" marginTop={10}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Email Address</th>
            <th>User Type</th>
            <th>User Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.response &&
            data.response.map((userItem, id) => {
              let index = id;
              return (
                <tr key={userItem.id}>
                  <td>{++index}</td>
                  <td>
                    <ModalButton
                      caption="Edit"
                      onClick={() => {
                        setSelectedUser(userItem);
                      }}
                      modalId="userModal"
                    />
                  </td>
                  <td>
                    <TableButton
                      caption="Deactivate"
                      onClick={() => {
                        confirmDeactivate(userItem);
                      }}
                      buttonType="danger"
                    />
                  </td>
                  <td>{userItem.first_name}</td>
                  <td>{userItem.last_name}</td>
                  <td>{userItem.phone}</td>
                  <td>{userItem.email}</td>
                  <td>{userItem.type}</td>
                  <td>{userItem.username}</td>
                  <td>{userItem.status}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

UsersTable.propTypes = {
  reLoadUsers: PropTypes.bool.isRequired,
  requestConfig: PropTypes.objectOf(PropTypes.object),
};

UsersTable.defaultProps = {
  requestConfig: {},
};

export default UsersTable;

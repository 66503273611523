const PaymentStatus = [
  {
    id: 'paid',
    name: 'Paid',
  },
  {
    id: 'unpaid',
    name: 'Unpaid',
  },
  {
    id: 'partially_paid',
    name: 'Partially Paid',
  },
  {
    id: 'cancelled',
    name: 'Cancelled',
  },
];

export default PaymentStatus;

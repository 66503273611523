import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './style.css';
import AlertActions from '../../actions/alert';

const AlertMessage = ({ alertType, message, dispatch, timeOut }) => {
  useEffect(() => {
    const handle = window.setTimeout(onClearAlert, timeOut);
    return () => {
      clearTimeout(handle);
    };
  }, []);

  function onClearAlert() {
    dispatch(AlertActions.clear());
  }

  return alertType && message ? (
    <div className={`alert ${alertType}`}>
      <span className="alert-message">{message}</span>
      <span>
        <button className="btn btn-circle" type="button" onClick={onClearAlert}>
          <i className="fa fa-times" />
        </button>
      </span>
    </div>
  ) : null;
};

AlertMessage.propTypes = {
  alertType: PropTypes.string.isRequired,
  message: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  timeOut: PropTypes.number,
};

AlertMessage.defaultProps = {
  message: '',
  timeOut: 10000,
};

export default connect((state) => {
  return state;
})(AlertMessage);

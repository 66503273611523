import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import { ExportTable /* , FormatDate */ } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import MiniButton from '../Buttons/MiniButton';
import iziQuestion from '../IziToast/question';
import IziToast from '../IziToast';

const ArrearsTable = ({ isLoading, error, invoices, user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  // refs
  const tableRef = React.useRef(null);
  // life-cycle hooks
  React.useEffect(() => {
    if (invoices.length > 0) {
      window.setTimeout(() => {
        ExportTable('invoices_table', 'arreara');
      });
    }
  }, [isLoading, invoices]);

  function onInvoice(charge) {
    let tenantName;
    if (charge.tenant) {
      const { tenant } = charge;
      if (tenant) {
        tenantName = `${tenant.first_name} ${tenant.last_name}`;
      }
    }
    iziQuestion(
      `Remind tenant <i><b>${tenantName}</b> to pay rent</i> ?`,
      () => {
        invoiceTenant();
      },
      undefined,
    );
    async function invoiceTenant() {
      const changeLog = [
        {
          user: {
            id: user.id,
            type: user.type,
          },
          action: 'Reminding tenant',
          timestamp: Math.round(new Date().getTime() / 1000.0),
        },
      ];
      const chargesObject = {
        id: charge.id,
        send_sms: true,
        invoice_type: 'rent',
        changelog: changeLog,
      };
      IziToast('Reminding...');
      try {
        const {
          data: { response },
        } = await axios.put(
          `${process.env.REACT_APP_API_URL}invoices`,
          chargesObject,
          requestConfig,
        );
        if (response.id) {
          IziToast('Tenant successfully reminded');
        }
      } catch (err) {
        if (err) {
          if (err.response) {
            if (err.response.data) {
              IziToast(err.response.data.error);
            }
          }
        } else {
          IziToast('An error occurred');
        }
      }
    }
  }

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      {invoices && invoices.length ? (
        <Table tableId="invoices_table" marginTop={10} tableRef={tableRef}>
          <thead>
            <tr>
              <th>No.</th>
              <th style={{ maxWidth: '40px' }}>Rent Due Date</th>
              <th>Property</th>
              <th>Unit No.</th>
              <th>Name</th>
              <th>Amount</th>
              <th>Phone</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, id) => {
              let index = id;
              let propertyName = '';
              let unitNumber = '';
              let tenantName = '';
              let tenantPhone = '-';
              let rentDueDate = '-';
              const { summed } = invoice;
              const amount = `(${summed})`;
              if (invoice.property) {
                const { property } = invoice;
                if (property) {
                  propertyName = property.name;
                }
              }
              if (invoice.tenant) {
                const { tenant } = invoice;
                if (tenant) {
                  tenantName = `${tenant.first_name} ${tenant.last_name}`;
                  tenantPhone = tenant.phone;
                  if (tenant.other_details) {
                    unitNumber = tenant.other_details.unit_number || '';
                  }
                  if (tenant.unit) {
                    const { unit } = tenant;
                    if (unit.other_details) {
                      const { other_details: otherDetails } = unit;
                      if (otherDetails) {
                        rentDueDate = otherDetails.rent_due || '';
                      }
                    }
                  }
                }
              }
              return (
                <tr key={invoice.id}>
                  <td style={{ width: '5px' }}>{++index}</td>
                  {/* <td>{FormatDate(invoice.invoice_date)}</td> */}
                  <td>{rentDueDate}</td>
                  <td>{propertyName}</td>
                  <td>{unitNumber}</td>
                  <td>{tenantName}</td>
                  <td>{amount}</td>
                  <td>{tenantPhone}</td>
                  <td style={{ width: '5px' }}>
                    <MiniButton
                      caption="Remind"
                      onClick={() => {
                        onInvoice(invoice);
                      }}
                      buttonType="danger"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="Seems no records found" />
      )}
    </>
  );
};

ArrearsTable.propTypes = {
  invoices: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

ArrearsTable.defaultProps = {
  error: null,
};

export default connect((state) => {
  return state;
})(ArrearsTable);

import iziToast from 'izitoast';

const iziQuestion = (message, onConfrim, onCancel) => {
  iziToast.question({
    timeout: false,
    close: false,
    overlay: true,
    displayMode: 'once',
    id: 'question',
    zindex: 999,
    title: '',
    message,
    position: 'center',
    progressBar: false,
    buttons: [
      [
        '<button>YES</button>',
        (instance, toast) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          onConfrim();
        },
        true,
      ],
      [
        '<button>NO</button>',
        (instance, toast) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          if (onCancel) onCancel();
        },
      ],
    ],
  });
};

export default iziQuestion;

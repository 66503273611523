import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import useAxiosGet from '../../hooks/useAxiosGet';
import { ExportTable, FormatDate } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import TableButton from '../Buttons/TableButton';
import iziQuestion from '../IziToast/question';
import IziToast from '../IziToast';
import UpdateProperty from './UpdateProperty';
import ModalButton from '../Buttons/ModalButton';
import ViewProperty from './ViewProperty';
import Collapsible from '../Panels/Collapsible';

const PropertyTable = ({ reLoadTable, userInfo }) => {
  // state
  const [isReLoading, setIsReloading] = useState(false);
  let url;
  if (userInfo.type === 'admin') {
    url = `${process.env.REACT_APP_API_URL}properties?take=1000&where={"status":"active"}&order={"name": "ASC"}`;
  } else {
    url = `${process.env.REACT_APP_API_URL}properties/byuser?user_id=${userInfo.id}&take=1000&where={"status":"active"}&order={"name": "ASC"}`;
  }
  const [data, error, isLoading] = useAxiosGet(url, [reLoadTable, isReLoading]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [viewProperty, setViewProperty] = useState(null);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  // life-cycle hooks
  useEffect(() => {
    ExportTable('property_table', 'properties');
  }, [isLoading]);

  const tableReload = () => {
    setIsReloading(!isReLoading);
  };

  const deactivateProperty = (property) => {
    if (property && property.id) {
      const formData = new FormData();
      formData.append('id', property.id);
      formData.append('status', 'disabled');

      IziToast('Deleting...');
      axios
        .put(
          `${process.env.REACT_APP_API_URL}properties`,
          formData,
          requestConfig,
        )
        .then((response) => {
          if (response.data.response.propertyUpdated.id) {
            IziToast('Property successfully deleted');
          }
          setIsReloading(!isReLoading);
        })
        .catch((err) => {
          if (err) {
            if (err.response) {
              if (err.response.data) {
                IziToast(err.response.data.error);
              }
            } else {
              IziToast(err.message);
            }
          } else {
            IziToast('An error occurred');
          }
        });
    } else {
      IziToast('Select property to delete');
    }
  };

  const confirmDeactivate = (property) => {
    iziQuestion(
      `Deactivate property <i><b>${property.name}</b></i> completely ?`,
      () => {
        deactivateProperty(property);
      },
      undefined,
    );
  };

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      <UpdateProperty
        requestConfig={requestConfig}
        reLoadTable={tableReload}
        selectedProperty={selectedProperty}
      />
      <ViewProperty property={viewProperty} />
      {data && data.response && data.response.length ? (
        <Collapsible
          caption="Properties List"
          columnWidth={12}
          controlId="property_list"
        >
          <Table tableId="property_table" marginTop={10}>
            <thead>
              <tr>
                <th>No.</th>
                <th>Edit</th>
                <th>Delete</th>
                <th>More</th>
                <th>Name</th>
                <th>Description</th>
                <th>Area, Town</th>
                <th>Units</th>
                <th>Created On</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.response &&
                data.response.map((propItem, id) => {
                  let index = id;
                  return (
                    <tr key={propItem.id}>
                      <td>{++index}</td>
                      <td>
                        <ModalButton
                          caption="Edit"
                          onClick={() => {
                            setSelectedProperty(propItem);
                          }}
                          modalId="propertyModal"
                          data-backdrop="static"
                        />
                      </td>
                      <td>
                        <TableButton
                          caption="Deactivate"
                          onClick={() => {
                            confirmDeactivate(propItem);
                          }}
                          buttonType="danger"
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <button
                          className="btn btn-circle"
                          type="button"
                          data-toggle="modal"
                          onClick={() => {
                            setViewProperty(propItem);
                          }}
                          style={{
                            backgroundColor: '#333333',
                          }}
                          data-target="#mdViewProperty"
                        >
                          <i className="fa fa-info" style={{ color: '#fff' }} />
                        </button>
                      </td>
                      <td>{propItem.name}</td>
                      <td style={{ maxWidth: '300px' }}>
                        {propItem.description}
                      </td>
                      <td style={{ maxWidth: '200px' }}>{propItem.address}</td>
                      <td>{propItem.units}</td>
                      <td>{FormatDate(propItem.createdat)}</td>
                      <td>{propItem.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Collapsible>
      ) : (
        <InfoPanel message="Seems you have no properties" />
      )}
    </>
  );
};

PropertyTable.propTypes = {
  reLoadTable: PropTypes.bool.isRequired,
  userInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
};

export default PropertyTable;

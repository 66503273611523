/* eslint-disable */

import $ from 'jquery';
import moment from 'moment';

/**
 * @name ValidateEmail
 * @param {string} email email id to be validated
 * @description Validates email address to make sure user inputs a correct email.
 * @returns void
 * @throws undefined
 */
const ValidateEmail = (email) => {
  try {
    const valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return valid.test(email);
  } catch (error) {
    window.console.log(error);
  }
};

/**
 * @name CharacterOccurrences
 * @param {string} haystack String containing character to be scanned.
 * @param {string} needle String to be scanned;
 * @description Scans the string and returns number of occurrences in a given string.
 * @returns number
 * @throws undefined
 */
const CharacterOccurrences = (haystack, needle) => {
  return haystack.split(needle).length - 1;
};

/**
 * @name ValidateDate
 * @param {Date} date Date value to be validated.
 * @description Checks whether provided date is valid or not.
 * @returns Date or String
 * @throws undefined
 */
const ValidateDate = (date) => {
  const today = new Date(date);
  if (isNaN(today)) {
    return 'Invalid Date';
  }
  if (today === 'Invalid Date') {
    return 'Invalid Date';
  }
  if (date.length < 10) {
    return 'Invalid Date';
  }
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return `${yyyy}-${mm}-${dd}`;
};

const OpenExternalLink = (url) => {
  if (url) {
    const win = window.open(url, '_blank');
    win.opener = null;
    win.focus();
  }
};

/**
 * @name DestroyTable
 * @param {string} tableId The html table id to be destroyed.
 * @description Initializes jquery data table.
 * @returns void
 * @throws undefined
 */
const DestroyTable = (tableId) => {
  if (undefined === tableId || '' === tableId) return;
  if ($.fn.dataTable.isDataTable('#' + tableId)) {
    $('#' + tableId)
      .DataTable()
      .destroy();
  }
};

/**
 * @name DrawTable
 * @param {string} tableId The html table id to be formatted.
 * @param {string} options Export options.
 * @description Initializes jquery data table.
 * @returns void
 * @throws undefined
 */
const DrawTable = (tableId, options = '') => {
  if (undefined === tableId || '' === tableId) return;
  if ($.fn.dataTable.isDataTable('#' + tableId)) {
    return;
  }
  $('#' + tableId).DataTable({
    paging: false,
    bPaginate: false,
    bFilter: true,
    bInfo: false,
    ordering: false,
  });
};

/**
 * @name ExportTable
 * @param {string} tableId The html table id to be formatted.
 * @param {string} exportName The file name of the exported sheet.
 * @description Initializes jquery data table with excel export options.
 * @returns void
 * @throws undefined
 */
const ExportTable = (tableId, exportName) => {
  if (undefined === tableId || '' === tableId) return;
  if ($.fn.dataTable.isDataTable('#' + tableId)) {
    // $('#' + tableId)
    //   .DataTable()
    //   .destroy();
    return;
  }
  $('#' + tableId).DataTable({
    dom: "<'row'<'col-sm-4'l><'col-sm-4 text-center'B><'col-sm-4'f>>tp",
    lengthMenu: [
      [10, 25, 50, -1],
      [10, 25, 50, 'All'],
    ],
    // buttons: [
    //   { extend: 'copy', className: 'btn-sm' },
    //   { extend: 'csv', title: 'ExampleFile', className: 'btn-sm' },
    //   { extend: 'pdf', title: 'ExampleFile', className: 'btn-sm' },
    //   { extend: 'print', className: 'btn-sm' },
    // ],
    // dom: 'Blfrtip',
    paging: true,
    bPaginate: true,
    bFilter: true,
    bInfo: true,
    ordering: true,
    destroy: true,
    pageLength: 10,
    lengthChange: true,
    buttons: [
      {
        extend: 'copyHtml5',
        className: 'btn-sm',
        text: 'COPY',
        titleAttr: 'COPY',
        title: exportName,
        exportOptions: {
          columns: [':visible'],
        },
      },
      {
        extend: 'excelHtml5',
        text: 'EXCEL',
        title: exportName,
        titleAttr: 'EXCEL',
        exportOptions: {
          columns: [':visible'],
        },
      },
      {
        extend: 'pdfHtml5',
        text: 'PDF',
        titleAttr: 'PDF',
        className: 'hidden',
        title: exportName,
        exportOptions: {
          columns: [':visible'],
          stripHtml: true,
        },
        orientation: 'landscape',
        pageSize: 'LEGAL',
      },
      {
        className: 'btn-sm',
        extend: 'csvHtml5',
        text: 'CSV',
        titleAttr: 'CSV',
        title: exportName,
        exportOptions: {
          columns: ':visible',
        },
      },
      {
        className: 'btn-sm',
        extend: 'print',
        text: 'PRINT',
        titleAttr: 'PRINT',
        title: exportName,
        exportOptions: {
          columns: ':visible',
        },
      },
      //'colvis'
    ],
  });
};

const compareFiles = (file1, file2) => {
  // they don't have the same size, they are different
  if (file1.size !== file2.size) return Promise.resolve(false);
  // load both as ArrayBuffers
  return Promise.all([readAsArrayBuffer(file1), readAsArrayBuffer(file2)]).then(
    ([buf1, buf2]) => {
      // create views over our ArrayBuffers
      const arr1 = new Uint8Array(buf1);
      const arr2 = new Uint8Array(buf2);
      return !arr1.some(
        (val, i) => arr2[i] !== val, // search for diffs
      );
    },
  );
};

const readAsArrayBuffer = (file) => {
  // we could also have used a FileReader,
  // but Response is conveniently already Promise based
  return new Response(file).arrayBuffer();
};

/**
 * @name FormatDate
 * @param {Date} date Date value to be validated or formatted.
 * @param {boolean} showTime shows time as part of return date.
 * @description Checks whether provided date is valid or not.
 * @returns Date or String
 * @throws undefined
 */
const FormatDate = (date, showTime = false) => {
  const today = new Date(date);
  if (isNaN(today)) {
    return 'Invalid Date';
  }
  if (today === 'Invalid Date') {
    return 'Invalid Date';
  }
  if (date.length < 10) {
    return 'Invalid Date';
  }
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  let hours = today.getHours();
  let minutes = today.getMinutes();
  let seconds = today.getSeconds();
  if (minutes < 10) minutes = '0' + minutes;
  if (hours === 0) hours = hours + '0';
  if (hours <= 10) hours = '0' + hours;
  if (seconds < 10) seconds = '0' + seconds;
  if (showTime)
    return (
      yyyy + '-' + mm + '-' + dd + ' ' + hours + ':' + minutes + ':' + seconds
    );
  return yyyy + '-' + mm + '-' + dd;
};

/**
 * Returns a random generated string
 */
const MercuryID = (length = 5) => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

/**
 * @name ConsoleLog
 * @description Logs provided message to the console if running in localhost
 * @param {String} message object to print in the console
 */
const ConsoleLog = (message) => {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
      ),
  );
  if (isLocalhost) window.console.dir(message);
};

/**
 * @name CapitalizeFirstLetter
 * @description Capitalizes the first letter of a string
 * @param {string} string String which first letter will be capitalized
 * @returns String
 */
function CapitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * @name DateTimeUTC
 * @description Formats datetime using moment API
 * @param {string} date Date to be formatted
 * @param {boolean} endOfDay States whether it's start or end of day
 * @returns String
 */
function DateTimeUTC(date, endOfDay = false) {
  return endOfDay
    ? moment(date, 'YYYY-MM-DD').endOf('day').utc().format()
    : moment(date, 'YYYY-MM-DD').startOf('day').utc().format();
}

export {
  ValidateEmail,
  ValidateDate,
  CharacterOccurrences,
  OpenExternalLink,
  DestroyTable,
  DrawTable,
  ExportTable,
  compareFiles,
  FormatDate,
  MercuryID,
  ConsoleLog,
  CapitalizeFirstLetter,
  DateTimeUTC,
};

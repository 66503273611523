import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const SearchInput = ({
  name,
  value,
  onChange,
  placeholder,
  caption,
  columnWidth,
  inputRef,
}) => {
  const colWidth = `col-md-${columnWidth}`;
  return (
    <div className={colWidth}>
      <div className="form-group">
        <span className="input-icon">
          <input
            type="text"
            placeholder={placeholder}
            title={caption}
            onChange={onChange}
            value={value}
            name={name}
            ref={inputRef}
            className="form-control"
          />
          <i className="bm-icon fa fa-search blue" />
        </span>
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  caption: PropTypes.string,
  columnWidth: PropTypes.number.isRequired,
  inputRef: PropTypes.objectOf(PropTypes.object),
};

SearchInput.defaultProps = {
  placeholder: 'Search...',
  caption: '',
  inputRef: null,
  name: '',
};

export default SearchInput;

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import axios from 'axios';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import IsPrivateMode from './BrowserMode';
import history from '../helpers/history';

const AuthGuard = ({ component: Component, ...rest }) => {
  React.useEffect(() => {
    // validate token
    (async () => {
      try {
        const userString = window.localStorage.getItem('nyumbani');
        const userObject = JSON.parse(userString);
        if (userObject) {
          const userToken = userObject.token;
          const requestConfig = {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          };
          const {
            data: { response: userSession },
          } = await axios.get(
            `${process.env.REACT_APP_API_URL}user`,
            requestConfig,
          );
          if (!Object.keys(userSession).length > 0) {
            history.push('/signin');
          }
        } else {
          history.push('/signin');
        }
      } catch (error) {
        history.push('/signin');
      }
    })();
  }, [Component]);

  IsPrivateMode().then((isPrivate) => {
    if (isPrivate) {
      if (typeof Storage !== 'undefined') {
        const privateString = window.localStorage.getItem('isPrivate');
        if (!privateString) {
          window.localStorage.removeItem('nyumbani');
          history.push('/signin');
        }
      }
    }
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

AuthGuard.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
};

export default AuthGuard;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Aside from './Aside';
import ErrorBoundary from '../ErrorBoundary';
import useWindowSize from '../../hooks/useWindowSize';
import Spinner from '../Spinner';

const Master = ({ children, redirectLink, title, source }) => {
  // state
  const [component, setComponent] = useState(<Spinner />);
  const [width] = useWindowSize();
  const body = window.document.getElementsByTagName('BODY')[0];

  useEffect(() => {
    /**
     * Add some delays
     */
    window.setTimeout(() => {
      if (width < 769) {
        body.classList.add('page-small');
      } else {
        body.classList.remove('page-small');
        body.classList.remove('show-sidebar');
      }
    });

    // Set minimal height of #wrapper to fit the window
    window.setTimeout(() => {
      fixWrapperHeight();
    });
  });
  // refs
  const wrapperRef = useRef(null);
  const contentRef = useRef(null);
  const navRef = useRef(null);

  useEffect(() => {
    if (children) {
      setComponent(children);
    }
  }, [children]);

  function fixWrapperHeight() {
    if (!navRef.current || !contentRef.current) return;
    // Get and set current height
    const headerH = 62;
    const navigationH = navRef.current.height;
    const contentH = contentRef.current.height;

    // Set new height when contnet height is less then navigation
    if (contentH < navigationH) {
      wrapperRef.current.style.minHeight = `${navigationH}px`;
    }

    // Set new height when contnet height is less then navigation and navigation is less then window
    if (contentH < navigationH && navigationH < window.height) {
      wrapperRef.current.style.minHeight = `${window.height - headerH}px`;
    }

    // Set new height when contnet is higher then navigation but less then window
    if (contentH > navigationH && contentH < window.height) {
      wrapperRef.current.style.minHeight = `${window.height - headerH}px`;
    }
  }

  return (
    <ErrorBoundary>
      <Header />
      <Aside redirectLink={redirectLink} title={title} navRef={navRef} />
      <div
        id="wrapper"
        style={{
          minHeight: '100vh',
        }}
        ref={wrapperRef}
      >
        <div className="small-header" id="dashboard-header">
          <div className="hpanel master-header" id="dashboard-header-hpanel">
            <div className="panel-body" id="dashboard-header-panel">
              {title}
              <div id="hbreadcrumb" className="pull-right">
                {`${title} / ${source}`}
              </div>
            </div>
          </div>
        </div>
        <div className="content" ref={contentRef}>
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="hpanel-body">{component}</div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <span className="pull-right">
            {`Copyright ${new Date().getFullYear()} | All Rights Reserved | Nyumbani`}
          </span>
          Powered by
          <strong> Ubuni</strong>
          Works
        </footer>
      </div>
    </ErrorBoundary>
  );
};

Master.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  redirectLink: PropTypes.string.isRequired,
  title: PropTypes.string,
  source: PropTypes.string,
};

Master.defaultProps = {
  children: PropTypes.object,
  title: '',
  source: '',
};

export default Master;

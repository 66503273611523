/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';

import Master from '../Master';
import Paragraph from '../Paragraph';
import { FormatDate } from '../../utils/Functions';
import Button from '../Buttons';
import IziToast from '../IziToast';
import iziQuestion from '../IziToast/question';
import noImage from '../../img/no-image.png';
import UpdateUnit from './UpdateUnit';
import TenantsHistory from './TenantsHistory';
import Collapsible from '../Panels/Collapsible';
import PropertyAccess from '../../data/AccessRights';

const UnitProfile = ({
  location: {
    property,
    unit,
    userToken,
    source,
    user,
    urlTransactions,
    filterProperty,
    filterTenant,
    filterStatus,
    filterLowerDate,
    filterUpperDate,
  },
}) => {
  const history = useHistory();

  // get details from local storage
  if (typeof Storage !== 'undefined') {
    if (!unit || !user) {
      const unitStr = window.localStorage.getItem('unit');
      const propStr = window.localStorage.getItem('property');
      const userStr = window.localStorage.getItem('nyumbani');
      if (unitStr) unit = JSON.parse(unitStr);
      if (propStr) property = JSON.parse(propStr);
      if (userStr) {
        user = JSON.parse(userStr);
        userToken = user.token;
      }
      source = window.localStorage.getItem('source') || '/';
    }
  }

  const propertyName = (property && property.name) || '';
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  // state
  const [unitsNumber, setUnitsNumber] = useState('');
  const [description, setDescription] = useState('');
  const [unitType, setUnitType] = useState('');
  const [unitRent, setUnitRent] = useState('');
  const [currentFile, setCurrentFile] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [parking, setParking] = useState('');
  const [rentDeposit, setRentDeposit] = useState('');
  const [rentDueDate, setRentDueDate] = useState('');
  const [rentDeadline, setRentDeadline] = useState('');
  const [unitStatus, setUnitStatus] = useState('');
  const [buttonCaption, setButtonCaption] = useState('<< Back');
  const [selectedUnit, setSelectedUnit] = useState(unit);

  // // copying state from props is anti-pattern but in our case we're not relying on props update
  // useEffect(() => {
  //   if (unit) {
  //     let currentDeposit = '';
  //     let currentRentDue = '';
  //     let currentRentDeadline = '';
  //     let currentUnits = '';
  //     if (unit.other_details) {
  //       const otherDetails = unit.other_details;
  //       currentDeposit = otherDetails.deposit;
  //       currentRentDue = otherDetails.rent_due;
  //       currentRentDeadline = otherDetails.rent_deadline;
  //       currentUnits = otherDetails.number;
  //     }
  //     setUnitType(unit.type || '');
  //     setDescription(unit.description || '');
  //     setBathrooms(unit.bathrooms || '');
  //     setBedrooms(unit.bedrooms || '');
  //     setParking(unit.parking || '');
  //     setRentDeposit(currentDeposit || '');
  //     setRentDueDate(currentRentDue || '');
  //     setRentDeadline(currentRentDeadline || '');
  //     setUnitsNumber(currentUnits || '');
  //     setUnitRent(unit.rent || '');
  //     setUnitStatus(unit.status || '');
  //     if (unit.images) {
  //       const { images } = unit;
  //       if (images && images.length) {
  //         setCurrentFile(images[0].link);
  //       }
  //     }
  //   }
  // }, [unit]);
  useEffect(() => {
    reLoadTable();
  }, []);

  useEffect(() => {
    if (source) {
      switch (source) {
        case 'units':
          setButtonCaption('<< Back To Unit');
          break;
        case 'rentroll':
          setButtonCaption('<< Back To Rent Roll');
          break;
        default:
          setButtonCaption('<< Back');
          break;
      }
    }
  }, [source]);

  const reLoadTable = () => {
    const where = [{ id: unit && unit.id ? unit.id : '' }];
    const url = `${process.env.REACT_APP_API_URL}units?where=${JSON.stringify(
      where,
    )}`;
    axios
      .get(url, requestConfig)
      .then((resp) => {
        if (resp) {
          const result = resp.data;
          if (result) {
            const resUnits = result.response;
            if (resUnits && resUnits.length) {
              const data = resUnits[0];
              if (data) {
                setSelectedUnit(data);
                let currentDeposit = '';
                let currentRentDue = '';
                let currentRentDeadline = '';
                let currentUnits = '';
                if (data.other_details) {
                  const otherDetails = data.other_details;
                  currentDeposit = otherDetails.deposit;
                  currentRentDue = otherDetails.rent_due;
                  currentRentDeadline = otherDetails.rent_deadline;
                  currentUnits = otherDetails.number;
                }
                setUnitType(data.type || '');
                setDescription(data.description || '');
                setBathrooms(data.bathrooms || '');
                setBedrooms(data.bedrooms || '');
                setParking(data.parking || '');
                setRentDeposit(currentDeposit || '');
                setRentDueDate(currentRentDue || '');
                setRentDeadline(currentRentDeadline || '');
                setUnitsNumber(currentUnits || '');
                setUnitRent(data.rent || '');
                setUnitStatus(data.status || '');
                if (data.images) {
                  const { images } = unit;
                  if (images && images.length) {
                    setCurrentFile(images[0].link);
                  }
                }
              }
            }
          }
        }
      })
      .catch((err) => {
        IziToast(err.message || 'An error occurred');
      });
  };

  const deactivateUnit = () => {
    if (unit && unit.id) {
      const formData = new FormData();
      formData.append('id', unit.id);
      formData.append('status', 'disabled');
      IziToast('Deleting...');
      axios
        .put(`${process.env.REACT_APP_API_URL}units`, formData, requestConfig)
        .then((response) => {
          if (response.data.response.id) {
            IziToast('Unit successfully deleted');
          }
          history.push('/units');
        })
        .catch((err) => {
          if (err) {
            if (err.response) {
              if (err.response.data) {
                IziToast(err.message);
              }
            } else {
              IziToast(err.message);
            }
          } else {
            IziToast('An error occurred');
          }
        });
    } else {
      IziToast('Select unit to delete');
    }
  };

  const confirmDeactivate = () => {
    iziQuestion(
      `Deactivate unit type <i><b>${unit.type}</b></i> completely ?`,
      () => {
        deactivateUnit();
      },
      undefined,
    );
  };

  return (
    <Master
      title="Property Management"
      redirectLink="unitprofile"
      source="Unit Profile"
    >
      <UpdateUnit reLoadTable={reLoadTable} selectedUnit={selectedUnit} />
      <div className="row">
        <div className="col-xs-3 bm-responsive-div">
          <img
            src={currentFile || noImage}
            alt="unit"
            className="bordered_photo"
            style={{ width: '250px', height: '200px', margin: '10px' }}
          />

          {propertyName && (
            <Paragraph
              columnWidth={12}
              content={propertyName || ''}
              caption="Property Name"
            />
          )}
        </div>
        <div className="col-xs-9 bm-responsive-div">
          {unit && (
            <>
              <div className="row">
                <Paragraph
                  columnWidth={2}
                  content={parking}
                  caption="Parking ?"
                />
                <Paragraph
                  columnWidth={2}
                  content={bathrooms}
                  caption="Bathrooms"
                />
                <Paragraph columnWidth={2} content={unitRent} caption="Rent" />
                <Paragraph
                  columnWidth={2}
                  content={bedrooms}
                  caption="Bedrooms"
                />
                <Paragraph
                  columnWidth={2}
                  content={unitStatus}
                  caption="Unit Status"
                />
                <Paragraph
                  columnWidth={2}
                  content={FormatDate(unit.createdat)}
                  caption="Created On"
                />
              </div>
              <div className="row">
                <Paragraph
                  columnWidth={10}
                  content={description}
                  caption="Unit Description"
                />
                <Paragraph
                  columnWidth={2}
                  content={FormatDate(unit.lastupdated)}
                  caption="Updated On"
                />
              </div>
              <div className="row">
                <Paragraph
                  columnWidth={2}
                  content={rentDeposit}
                  caption="Deposit"
                />
                <Paragraph
                  columnWidth={2}
                  content={rentDeadline}
                  caption="Rent Deadline"
                />
                <Paragraph
                  columnWidth={2}
                  content={rentDueDate}
                  caption="Rent Due Date"
                />
                <Paragraph
                  columnWidth={2}
                  content={unitsNumber}
                  caption="Total Units"
                />
                <Paragraph
                  columnWidth={4}
                  content={unitType}
                  caption="Unit Type"
                />
              </div>
            </>
          )}
        </div>
        <div
          className="right"
          style={{ marginBottom: '20px', justifyContent: 'space-between' }}
        >
          <Button
            caption={buttonCaption}
            cssClass="btn-success"
            onClick={() => {
              history.push({
                pathname: `/${source}`,
                property,
                urlTransactions,
                filterProperty,
                filterTenant,
                filterStatus,
                filterLowerDate,
                filterUpperDate,
              });
              if (typeof Storage !== 'undefined') {
                window.localStorage.removeItem('unit');
                window.localStorage.removeItem('source');
              }
            }}
            type="button"
            isBlack
          />
          {unit && user && PropertyAccess.includes(user.type) && (
            <>
              <Button
                caption="Deactivate Unit"
                cssClass="btn-success"
                onClick={confirmDeactivate}
                type="button"
                isBlack={false}
              />
              <Button
                caption="Edit Unit"
                cssClass="btn-success"
                onClick={() => {
                  $('#unitsModal').modal({
                    backdrop: 'static',
                    keyboard: false,
                  });
                }}
                type="button"
                isBlack
              />
            </>
          )}
        </div>
      </div>
      <Collapsible
        columnWidth={12}
        caption="Tenant History"
        controlId="tenant_history"
      >
        <div className="row">{unit && <TenantsHistory unit={unit} />}</div>
      </Collapsible>
    </Master>
  );
};

UnitProfile.propTypes = {
  userToken: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

UnitProfile.defaultProps = {
  userToken: {},
  location: {},
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(UnitProfile);

/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import useWindowSize from '../../hooks/useWindowSize';
import Logo from '../../img/nyumbani384.png';
import UserActions from '../../actions/user';

const Header = ({ user }) => {
  const [width] = useWindowSize();
  const body = window.document.getElementsByTagName('BODY')[0];
  const [toggleStyle, setToggleStyle] = useState(
    'header-link hide-menu navbar-icons hidden',
  );

  useEffect(() => {
    if (width <= 769) {
      setToggleStyle('header-link hide-menu navbar-icons');
    } else {
      setToggleStyle('header-link hide-menu navbar-icons hidden');
    }
  }, [width]);

  /**
   * Handle minimize sidebar menu
   */
  const toggleSideBar = () => {
    if (width < 769) {
      body.classList.toggle('show-sidebar');
    } else {
      body.classList.toggle('hide-sidebar');
    }
  };

  const navigation = user.type === 'caretaker' ? '/tenantslist' : '/';

  return (
    <div id="header">
      <div className="color-line" />
      <div id="logo" className="light-version">
        <Link to={navigation}>
          <img src={Logo} alt="nyumbani" className="nyumbani-logo" />
        </Link>
      </div>
      <nav role="navigation">
        <div className={toggleStyle} onClick={toggleSideBar}>
          <i className="fa fa-bars" />
        </div>
        <div className="small-logo">
          <Link to="/">
            <span className="text-primary product-name">NYUMBANI</span>
          </Link>
        </div>

        <div className="mobile-menu">
          <button
            type="button"
            className="navbar-toggle mobile-menu-toggle"
            data-toggle="collapse"
            data-target="#mobile-collapse"
          >
            <i className="fa fa-chevron-down" />
          </button>
          <div className="collapse mobile-navbar" id="mobile-collapse">
            <ul className="nav navbar-nav">
              <li>
                <a onClick={UserActions.logout}>
                  <i
                    className="pe-7s-upload pe-rotate-90"
                    style={{ fontSize: '30px' }}
                  />
                  <span className="logout-span">Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-right">
          <ul className="nav navbar-nav no-borders">
            <li className="dropdown">
              <a
                className="dropdown-toggle "
                href={null}
                data-toggle="dropdown"
              >
                <i className="pe-7s-speaker" />
              </a>
              <ul className="dropdown-menu hdropdown notification animated flipInX">
                <li>
                  <a>
                    <span className="label label-success">0</span>
                    your notifications will be displayed here.
                  </a>
                </li>
                <li className="summary">
                  <span>See all notifications</span>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a onClick={UserActions.logout}>
                <i className="pe-7s-upload pe-rotate-90" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default connect((state) => {
  return state;
})(Header);

/* eslint-disable object-curly-newline */
import AlertConstants from '../constants/alert';

function alert(state = {}, action) {
  switch (action.type) {
    case AlertConstants.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message,
      };
    case AlertConstants.ERROR:
      return {
        type: 'alert-danger',
        message: action.message,
      };
    case AlertConstants.INFO:
      return {
        type: 'alert-info',
        message: action.message,
      };
    case AlertConstants.CLEAR:
      return {};
    default:
      return state;
  }
}

export default alert;

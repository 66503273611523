/**
 * whatever am doing here is bad practice but i have to
 * api is not giving meaningful responses so i have
 * to use what is available for now.
 * please don't @me :(
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import { ExportTable, FormatDate, DestroyTable } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import UpdateUtility from './UpdateUtility';
import ModalButton from '../Buttons/ModalButton';
import TableButton from '../Buttons/TableButton';
import iziQuestion from '../IziToast/question';
import IziToast from '../IziToast';

const UtilityTable = ({ reLoadTable, requestConfig, user }) => {
  // state
  const [isReLoading, setIsReloading] = useState(false);
  const [selectedUtility, setSelectedUtility] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [utilities, setUtilities] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);

  // life-cycle hooks
  useEffect(() => {
    if (tenants.length > 0) {
      getUtilities(tenants, units, properties);
    }
  }, [reLoadTable, isReLoading]);

  useEffect(() => {
    (async () => {
      DestroyTable('utilities_table');
      try {
        let urlProperty;
        let urlUnits;
        let urlTenant;
        // get properties
        if (user.type === 'admin') {
          urlProperty = `${process.env.REACT_APP_API_URL}properties?where={"status":"active"}&order={"name": "ASC"}`;
        } else {
          urlProperty = `${process.env.REACT_APP_API_URL}properties/byuser?user_id=${user.id}&take=1000&where={"status":"active"}&order={"name": "ASC"}`;
        }
        const {
          data: { response: propertyInfo },
        } = await axios.get(urlProperty, requestConfig);
        const propertyIds = propertyInfo.map((item) => {
          return item.id;
        });
        // get units
        if (user.type === 'admin') {
          urlUnits = `${process.env.REACT_APP_API_URL}units?where=[{"status":"available"},{"status":"occupied"}]`;
        } else {
          urlUnits = `${
            process.env.REACT_APP_API_URL
          }units?where={"property_id":"in::${propertyIds.join()}"}`;
        }
        const {
          data: { response: unitsInfo },
        } = await axios.get(urlUnits, requestConfig);
        const unitIds = unitsInfo.map((item) => {
          return item.id;
        });
        // get tenants
        if (user.type === 'admin') {
          urlTenant = `${process.env.REACT_APP_API_URL}tenants?where={"status":"active"}`;
        } else {
          urlTenant = `${
            process.env.REACT_APP_API_URL
          }tenants?where={"unit_id":"in::${unitIds.join()}","status":"active"}`;
        }
        const {
          data: { response: tenantInfo },
        } = await axios.get(urlTenant, requestConfig);
        setTenants(tenantInfo);
        setProperties(propertyInfo);
        setUnits(unitsInfo);
        getUtilities(tenantInfo, unitsInfo, propertyInfo);
      } catch (error) {
        IziToast('An error occurred');
        setHasError(true);
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    ExportTable('utilities_table', 'utilities');
  }, [isLoading]);

  const tableReload = () => {
    setIsReloading(!isReLoading);
  };

  async function getUtilities(tenantInfo, unitsInfo, propertyInfo) {
    try {
      let urlUtility;
      const tenantIds = tenantInfo.map((item) => {
        return item.id;
      });
      // get utilities
      if (user.type === 'admin') {
        urlUtility = `${process.env.REACT_APP_API_URL}utilities?status=unpaid&take=1000&order={"name":"ASC"}`;
      } else {
        urlUtility = `${
          process.env.REACT_APP_API_URL
        }utilities?status=unpaid&take=1000&order={"name":"ASC"}&where={"tenant_id":"in::${tenantIds.join()}"}`;
      }
      const {
        data: { response: utilityInfo },
      } = await axios.get(urlUtility, requestConfig);
      // find tenant details
      const findTenant = (id) => {
        return tenantInfo.find((tenant) => {
          return tenant.id === id;
        });
      };
      // find unit details
      const findUnit = (id) => {
        return unitsInfo.find((unit) => {
          return unit.id === id;
        });
      };
      // find property details
      const findProperty = (id) => {
        return propertyInfo.find((property) => {
          return property.id === id;
        });
      };
      // map tenant to utility
      const mapped = utilityInfo.map((item) => {
        const utility = item;
        const tenant = findTenant(item.tenant_id);
        const unit = tenant ? findUnit(tenant.unit_id) : undefined;
        const property = unit ? findProperty(unit.property_id) : undefined;
        utility.tenant = tenant || undefined;
        utility.unit = unit;
        utility.property = property;
        return utility;
      });
      const cleanUtility = mapped.filter((item) => {
        return item.tenant !== undefined;
      });
      setUtilities(cleanUtility);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const deleteUtility = (utility) => {
    if (utility && utility.id) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}utilities?id=${utility.id}`,
          requestConfig,
        )
        .then((res) => {
          if (res.data.response) {
            DestroyTable('utilities_table');
            setIsReloading(!isReLoading);
            IziToast('Selected utility successfully deleted');
          } else {
            IziToast('Selected utility not deleted');
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response) {
              if (err.response.data) {
                IziToast(err.response.data.error || 'An error occurred');
              }
            }
          } else {
            IziToast('An error occurred');
          }
        });
    } else {
      IziToast('Select utility to delete');
    }
  };

  const confirmDelete = (utility) => {
    iziQuestion(
      `Delete utility <i><b>${utility.name}</b></i> completely?
      Note: Utility can only be deleted if not associated with a paid invoice.`,
      () => {
        deleteUtility(utility);
      },
      undefined,
    );
  };

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : hasError ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      <UpdateUtility
        requestConfig={requestConfig}
        reLoadTable={tableReload}
        selectedUtility={selectedUtility}
      />

      <Table tableId="utilities_table" marginTop={10}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Property</th>
            <th>Hse.Unit</th>
            <th>Tenant</th>
            <th>Date Applied</th>
            <th>Utility Name</th>
            <th>Utility Amount</th>
            <th>Created On</th>
            <th>Updated on</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {utilities.map((utility, id) => {
            let index = id;
            let tenantInfo = '-';
            let propertyInfo = '-';
            let unitInfo = '-';
            const dateApplied = FormatDate(
              new Date(+utility.date).toISOString(),
            );
            if (utility.tenant) {
              const { tenant } = utility;
              tenantInfo = `${tenant.first_name} ${tenant.last_name}`;
              if (tenant) {
                const { other_details: otherDetails } = tenant;
                if (otherDetails) {
                  unitInfo = otherDetails.unit_number;
                }
              }
            }
            if (utility.property) {
              const { property } = utility;
              propertyInfo = property.name;
            }
            return (
              <tr key={utility.id}>
                <td>{++index}</td>
                <td>{propertyInfo}</td>
                <td>{unitInfo}</td>
                <td>{tenantInfo}</td>
                <td>{dateApplied}</td>
                <td>{utility.name}</td>
                <td>{utility.amount}</td>
                <td>{FormatDate(utility.createdat)}</td>
                <td>{FormatDate(utility.lastupdated)}</td>
                <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <ModalButton
                    caption="Edit"
                    onClick={() => {
                      setSelectedUtility(utility);
                    }}
                    modalId="utilityModal"
                  />
                  <TableButton
                    caption="Delete"
                    onClick={() => {
                      confirmDelete(utility);
                    }}
                    buttonType="danger"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

UtilityTable.propTypes = {
  reLoadTable: PropTypes.bool.isRequired,
  requestConfig: PropTypes.objectOf(PropTypes.object),
  user: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

UtilityTable.defaultProps = {
  requestConfig: {},
};

export default connect((state) => {
  return state;
})(UtilityTable);

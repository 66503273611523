import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ExportTable, FormatDate } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';

const PayTable = ({ isLoading, error, receipts }) => {
  // life-cycle hooks
  useEffect(() => {
    ExportTable('receipts_table', 'receipts');
  }, [receipts, isLoading]);

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      {receipts && receipts.length ? (
        <Table tableId="receipts_table" marginTop={10}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Date</th>
              <th>Property</th>
              <th>Hse. Unit</th>
              <th>Name</th>
              <th>Amount</th>
              <th>Receipt No.</th>
              <th>Late by (Days)</th>
            </tr>
          </thead>
          <tbody>
            {receipts.map((receipt, id) => {
              let index = id;
              let propertyName = '';
              let unitNumber = '';
              let tenantName = '';
              const amount = `(${receipt.amount})`;
              const { days } = receipt;
              const receiptNo = `RCT-${String(receipt.id)
                .substring(0, 10)
                .toUpperCase()}`;
              if (receipt.property) {
                const { property } = receipt;
                if (property) {
                  propertyName = property.name;
                }
              }
              if (receipt.tenant) {
                const { tenant } = receipt;
                if (tenant) {
                  tenantName = `${tenant.first_name} ${tenant.last_name}`;
                  if (tenant.other_details) {
                    unitNumber = tenant.other_details.unit_number || '';
                  }
                }
              }
              return (
                <tr key={receipt.id}>
                  <td>{++index}</td>
                  <td>{FormatDate(receipt.createdat)}</td>
                  <td>{propertyName}</td>
                  <td>{unitNumber}</td>
                  <td>{tenantName}</td>
                  <td>{amount}</td>
                  <td>{receiptNo}</td>
                  <td>{days || 0}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="Seems no receipt found" />
      )}
    </>
  );
};

PayTable.propTypes = {
  receipts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

PayTable.defaultProps = {
  error: null,
};

export default PayTable;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';

import UpdateModal from '../Modals/UpdateModal';
import IziToast from '../IziToast';
import TextInput from '../TextInput';
import FilePreview from '../FileUploader/FilePreview';
import { ValidateEmail } from '../../utils/Functions';
import DatePicker from '../DatePicker';

const UpdateTenant = ({ userToken, reLoadTable, selectedTenant }) => {
  // state
  const [unitNumber, setUnitNumber] = useState('');
  const [phone, setPhone] = useState('');
  const [idOrPassport, setIdOrPassport] = useState('');
  const [nationality, setNationality] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentFile, setCurrentFile] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [email, setEmail] = useState('');
  const [oldUnitNumber, setOldUnitNumber] = useState('');

  // copying state from props is anti-pattern but in our case we're not relying on props update
  useEffect(() => {
    if (selectedTenant) {
      let currentUnitNumber = '';
      if (selectedTenant.other_details) {
        const otherDetails = selectedTenant.other_details;
        currentUnitNumber = otherDetails.unit_number;
      }
      setOldUnitNumber(currentUnitNumber);
      setIdOrPassport(selectedTenant.id_or_passport || '');
      setLastName(selectedTenant.last_name || '');
      setEmail(selectedTenant.email || '');
      setFirstName(selectedTenant.first_name || '');
      setStartDate(new Date(+selectedTenant.start_date * 1000) || new Date());
      setUnitNumber(currentUnitNumber || '');
      setNationality(selectedTenant.nationality || '');
      const phoneNumber = String(selectedTenant.phone);
      if (phoneNumber) {
        if (phoneNumber.startsWith('+')) {
          setPhone(phoneNumber.substring(1));
        } else {
          setPhone(phoneNumber);
        }
      }
      if (selectedTenant.images) {
        const { images } = selectedTenant;
        if (images && images.length) {
          setCurrentFile(images[0].link);
        }
      }
    }
  }, [selectedTenant]);

  const updateTenant = async () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (
      firstName &&
      lastName &&
      phone &&
      nationality &&
      idOrPassport &&
      startDate &&
      unitNumber
    ) {
      if (email !== '') {
        isValid = !ValidateEmail(email);
        message = 'Enter a valid email address';
        IziToast(message);
      } else {
        isValid = true;
      }
      if (phone.length !== 12) {
        message = 'Enter a valid phone number';
        IziToast(message);
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (isValid) {
      const otherDetails = {
        unit_number: unitNumber,
      };
      const stayDuration = 'N/A';
      const formData = new FormData();
      formData.append('id', selectedTenant.id);
      formData.append('id_or_passport', idOrPassport);
      formData.append('phone', `+${phone}`);
      formData.append('last_name', lastName);
      formData.append('first_name', firstName);
      formData.append(
        'start_date',
        Math.round(new Date(startDate).getTime() / 1000.0),
      );
      formData.append('nationality', nationality);
      if (oldUnitNumber !== unitNumber) {
        formData.append('other_details', JSON.stringify(otherDetails));
      }
      formData.append('stay_duration', stayDuration);
      formData.append('email', email);
      // attach image if any
      if (currentFile && typeof currentFile === 'object') {
        formData.append('idphoto', currentFile, currentFile.name);
      }
      const formConfig = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      IziToast('Updating...');
      try {
        const {
          data: { response },
        } = await axios.put(
          `${process.env.REACT_APP_API_URL}tenants`,
          formData,
          formConfig,
        );
        if (response.id) {
          IziToast('Tenant successfully updated');
          if (reLoadTable) reLoadTable();
          $('#tenantsModal').modal('hide');
        }
        setIsLoading(!isLoading);
      } catch (error) {
        if (error) {
          if (error.response) {
            if (error.response.data) {
              IziToast(error.response.data.error);
            }
          } else {
            IziToast(error.message);
          }
        } else {
          IziToast('An error occurred');
        }
      }
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const { type /* name */ /* size */ } = file;
      const validTypes = ['image/jpeg', 'image/png'];
      if (validTypes.includes(type)) {
        /**
         * TODO : validate duplicate upload
         * message : 'Looks like you have already uploaded this image'
         */
        setCurrentFile(file);
      } else {
        IziToast('Only .jpeg and .png files allowed');
      }
    }
  };

  return (
    <UpdateModal
      modalId="tenantsModal"
      onClick={updateTenant}
      header="Update tenant details"
    >
      <div className="row">
        <div className="col-xs-3 bm-responsive-div">
          <FilePreview
            currentFile={currentFile}
            onChange={onFileChange}
            inputId="imgTenant"
            onFileRemove={() => {
              setCurrentFile(null);
            }}
            columnWidth={12}
            caption="Upload ID/Passport Image"
          />
        </div>
        <div className="col-xs-9 bm-responsive-div">
          <div className="row">
            <TextInput
              type="text"
              placeholder="Enter first name"
              caption="First Name"
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              value={firstName}
              columnWidth={6}
              className={isSubmitting && !firstName ? 'error' : ''}
            />
            <TextInput
              type="text"
              placeholder="Enter last name"
              caption="Last Name"
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              value={lastName}
              columnWidth={6}
              className={isSubmitting && !lastName ? 'error' : ''}
            />
          </div>
          <div className="row">
            <TextInput
              type="text"
              placeholder="254712345678"
              caption="Telephone Number (254712345678)"
              onChange={(e) => {
                const numbers = /^[0-9\b]+$/;
                const { value } = e.target;
                // allows number only
                if (value === '' || numbers.test(value)) {
                  setPhone(value);
                }
              }}
              value={phone}
              columnWidth={6}
              className={isSubmitting && phone.length !== 12 ? 'error' : ''}
            />
            <TextInput
              type="text"
              placeholder="Enter ID/Passport number"
              caption="ID/Passport Number"
              onChange={(e) => {
                // const value = /^[0-9\b]+$/;
                // if (e.target.value === '' || value.test(e.target.value)) {
                setIdOrPassport(e.target.value);
                // }
              }}
              value={idOrPassport}
              columnWidth={6}
              className={isSubmitting && !idOrPassport ? 'error' : ''}
            />
          </div>
          <div className="row">
            <TextInput
              type="text"
              placeholder="Enter nationality"
              caption="Nationality"
              onChange={(e) => {
                setNationality(e.target.value);
              }}
              value={nationality}
              columnWidth={6}
              className={isSubmitting && !nationality ? 'error' : ''}
            />

            <TextInput
              type="email"
              placeholder="Enter email"
              caption="Email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
              name="email"
              columnWidth={6}
              className={
                isSubmitting && (!email && !ValidateEmail(email)) ? 'error' : ''
              }
            />
          </div>
          <div className="row">
            <DatePicker
              placeholder="Select start date"
              caption="Start Date"
              onChange={(date) => {
                return setStartDate(date);
              }}
              columnWidth={6}
              selected={startDate}
            />
            <TextInput
              type="text"
              placeholder="Enter unit number"
              caption="Unit Number"
              onChange={(e) => {
                setUnitNumber(e.target.value);
              }}
              value={unitNumber}
              columnWidth={6}
              className={isSubmitting && !unitNumber ? 'error' : ''}
            />
          </div>
        </div>
      </div>
    </UpdateModal>
  );
};

UpdateTenant.propTypes = {
  userToken: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedTenant: PropTypes.oneOfType([PropTypes.object]),
  reLoadTable: PropTypes.func.isRequired,
};

UpdateTenant.defaultProps = {
  userToken: {},
  selectedTenant: {},
};

export default UpdateTenant;

import axios from 'axios';

import UserConstants from '../constants/user';
import AlertActions from './alert';
import history from '../helpers/history';
import IsPrivateMode from '../utils/BrowserMode';

const login = (username, password) => {
  return async (dispatch) => {
    const success = (user) => {
      return {
        type: UserConstants.LOGIN_SUCCESS,
        user,
      };
    };

    const request = (user) => {
      return {
        type: UserConstants.LOGIN_REQUEST,
        user,
      };
    };

    const failure = (error) => {
      return {
        type: UserConstants.LOGIN_FAILURE,
        error,
      };
    };

    const credentials = {
      email: username,
      password,
    };
    dispatch(request(credentials));
    dispatch(AlertActions.info('Authenticating...'));
    return axios
      .post(`${process.env.REACT_APP_API_URL}login`, credentials)
      .then((response) => {
        const { data } = response;
        if (data) {
          if (data.response) {
            const user = data.response;
            const userObj = {
              token: user.token,
              type: user.type,
              id: user.id,
            };
            dispatch(success(userObj));
            if (typeof Storage !== 'undefined') {
              window.localStorage.setItem('nyumbani', JSON.stringify(userObj));
              IsPrivateMode().then((isPrivate) => {
                if (isPrivate) {
                  window.localStorage.setItem('isPrivate', 'true');
                }
              });
            }
            history.push('/');
          } else {
            dispatch(failure('We got no response'));
            dispatch(AlertActions.error('We could not log you in, try again'));
          }
        } else {
          dispatch(failure('We got no response'));
          dispatch(AlertActions.error('We could not log you in, try again'));
        }
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
        dispatch(AlertActions.error(error.toString()));
      });
  };
};

const logout = () => {
  if (typeof Storage !== 'undefined') {
    window.localStorage.clear();
  }
  history.push('/signin');
  window.location.reload();
  return {
    type: UserConstants.LOGOUT,
  };
};

const UserActions = {
  login,
  logout,
};

export default UserActions;

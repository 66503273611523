import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
    });
    this.setState({ error });
    this.setState({ info });
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { hasError, error, info } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="error-container">
          <i
            className="pe-7s-way  big-icon"
            style={{
              color: '#d73836',
            }}
          />
          <h1>500</h1>
          <strong>An Error Occurred</strong>
          <p>Something went wrong, but don’t fret it’s not your fault.</p>
          <Link
            to="/"
            className="btn btn-success nyumbani_button"
            style={{ backgroundColor: '#d73836', borderColor: '#d73836' }}
          >
            Go back to dashboard
          </Link>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ]).isRequired,
};

/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Master from '../Master';
import IziToast from '../IziToast';
import PropertyTile from '../ButtonTile';
import UnitsTable from './UnitsTable';
import InfoPanel from '../Panels/InfoPanel';
import Button from '../Buttons';
import SearchInput from '../SearchInput';
import PropertyAccess from '../../data/AccessRights';

const Units = ({ user, location: { property } }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };

  // state
  const history = useHistory();
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filteredProperties, setFilteredProperties] = useState([]);

  useEffect(() => {
    let url = `/byuser?status=active&user_id=${user.id}`;
    if (user && user.type === 'admin') {
      // eslint-disable-next-line quotes
      url = `?take=1000&where={"status":"active"}`;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}properties${url}`, requestConfig)
      .then((res) => {
        if (res.data) {
          if (res.data.response) {
            const { response } = res.data;
            // sort by name asc
            response.sort((a, b) => {
              return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
            });

            setProperties(response);
            setFilteredProperties(response);
          }
        }
      })
      .catch(() => {
        IziToast('Could not load your properties');
      });
  }, []);

  useEffect(() => {
    if (property && property.id) {
      setSelectedProperty(property);
      //  const scrollTile = window.document.querySelector('.property-tile');
      //       window.console.dir(scrollTile);
      // if (scrollTile) {
      //   scrollTile.scrollIntoView({
      //     behavior: 'smooth',
      //     block: 'end',
      //     inline: 'nearest',
      //   });
      // }
    }
  }, [property]);

  const searchProperties = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    const data = value
      ? properties.filter((item) => {
          return item.name
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase());
        })
      : properties;
    if (data.length) setFilteredProperties(data);
  };

  return (
    <Master redirectLink="units" title="Property Management" source="Unit List">
      <div className="row">
        <div className="col-md-3">
          <SearchInput
            onChange={searchProperties}
            value={searchValue}
            placeholder="Search property..."
            columnWidth={12}
          />
          <div
            style={{ maxHeight: '500px', overflowY: 'scroll', padding: '5px' }}
          >
            {filteredProperties.map((item) => {
              return (
                <PropertyTile
                  controlId={`tile_${item.id}`}
                  key={item.id}
                  content={item.name}
                  onClick={() => {
                    setSelectedProperty(item);
                  }}
                  isActive={selectedProperty.id === item.id}
                />
              );
            })}
          </div>
        </div>
        <div className="col-md-9">
          <div className="">
            {user && PropertyAccess.includes(user.type) && (
              <div style={{ marginBottom: '10px', textAlign: 'right' }}>
                <Button
                  caption="Add New Unit"
                  cssClass="btn-success"
                  onClick={() => {
                    const obj = {
                      id: selectedProperty.id,
                      name: selectedProperty.name,
                    };
                    if (typeof Storage !== 'undefined') {
                      window.localStorage.setItem(
                        'property',
                        JSON.stringify(obj),
                      );
                    }
                    history.push({
                      pathname: '/newunit',
                      property: selectedProperty,
                    });
                  }}
                  type="button"
                  isBlack={false}
                />
              </div>
            )}
          </div>
          <div className="">
            {selectedProperty ? (
              <UnitsTable userToken={userToken} property={selectedProperty} />
            ) : (
              <InfoPanel message="Select property to view units" />
            )}
          </div>
        </div>
      </div>
    </Master>
  );
};

Units.propTypes = {};

const mapStateToProps = (state) => {
  return state;
};

Units.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Units.defaultProps = {
  user: {},
  location: {},
};

export default connect(mapStateToProps)(Units);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';

import Master from '../Master';
import Paragraph from '../Paragraph';
import { FormatDate } from '../../utils/Functions';
import Button from '../Buttons';
import IziToast from '../IziToast';
import iziQuestion from '../IziToast/question';
import noImage from '../../img/no-image.png';
import UpdateTenant from './UpdateTenant';
import Collapsible from '../Panels/Collapsible';
import NextOfKin from '../NextOfKin';
import KinsTable from '../NextOfKin/KinsTable';
import PropertyAccess from '../../data/AccessRights';

const TenantProfile = ({
  location: { property, tenant, userToken, source },
  user,
}) => {
  const history = useHistory();

  // get details from local storage
  if (typeof Storage !== 'undefined') {
    if (!tenant) {
      const tenantStr = window.localStorage.getItem('tenant');
      const propStr = window.localStorage.getItem('property');
      const userStr = window.localStorage.getItem('nyumbani');
      // eslint-disable-next-line no-param-reassign
      if (tenantStr) tenant = JSON.parse(tenantStr);
      // eslint-disable-next-line no-param-reassign
      if (propStr) property = JSON.parse(propStr);
      // eslint-disable-next-line no-param-reassign
      if (userStr) userToken = JSON.parse(userStr).token;
    }

    if (!source) {
      // eslint-disable-next-line no-param-reassign
      source = window.localStorage.getItem('source') || '/';
    }
  }

  const propertyName = (property && property.name) || '';

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  // state
  const [status, setStatus] = useState('');
  const [idOrPassport, setIdOrPassport] = useState('');
  const [phone, setPhone] = useState('');
  const [currentFile, setCurrentFile] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [unitNumber, setUnitNumber] = useState('');
  const [nationality, setNationality] = useState('');
  const [nextOfKins, setNextOfKins] = useState([]);

  // copying state from props is anti-pattern but in our case we're not relying on props update
  useEffect(() => {
    if (tenant) {
      let currentUnitNumber = '';
      if (tenant.other_details) {
        const otherDetails = tenant.other_details;
        currentUnitNumber = otherDetails.unit_number;
      }

      setIdOrPassport(tenant.id_or_passport || '');
      setLastName(tenant.last_name || '');
      setEmail(tenant.email || '');
      setFirstName(tenant.first_name || '');
      setStartDate(new Date(+tenant.start_date) || new Date());
      setUnitNumber(currentUnitNumber || '');
      setStatus(tenant.status || '');
      setPhone(tenant.phone || '');
      setNationality(tenant.nationality || '');
      if (tenant.images) {
        const { images } = tenant;
        if (images && images.length) {
          setCurrentFile(images[0].link);
        }
      }
      setNextOfKins(tenant.next_of_kin);
    }
  }, []);

  const reLoadTable = () => {
    const where = [{ id: tenant && tenant.id ? tenant.id : '' }];
    const url = `${process.env.REACT_APP_API_URL}tenants?where=${JSON.stringify(
      where,
    )}`;
    axios
      .get(url, requestConfig)
      .then((resp) => {
        if (resp) {
          const result = resp.data;
          if (result) {
            const updated = result.response;
            if (updated && updated.length) {
              const data = updated[0];
              if (data) {
                // eslint-disable-next-line no-param-reassign
                tenant = data;
                let currentUnitNumber = '';
                if (data.other_details) {
                  const otherDetails = data.other_details;
                  currentUnitNumber = otherDetails.unit_number;
                }
                setIdOrPassport(data.id_or_passport || '');
                setLastName(data.last_name || '');
                setEmail(data.email || '');
                setFirstName(data.first_name || '');
                setStartDate(new Date(+data.start_date) || new Date());
                setUnitNumber(currentUnitNumber || '');
                setStatus(data.status || '');
                setPhone(data.phone || '');
                setNationality(data.nationality || '');
                if (data.images) {
                  const { images } = data;
                  if (images && images.length) {
                    setCurrentFile(images[0].link);
                  }
                }
                setNextOfKins(data.next_of_kin);
              }
            }
          }
        }
      })
      .catch((err) => {
        IziToast(err.message || 'An error occurred');
      });
  };

  return (
    <Master
      title="Property Management"
      redirectLink="tenantprofile"
      source="Tenant Profile"
    >
      <UpdateTenant
        userToken={userToken}
        reLoadTable={reLoadTable}
        selectedTenant={tenant}
      />
      <div className="row">
        <div className="col-xs-3 bm-responsive-div">
          <img
            src={currentFile || noImage}
            alt="tenant"
            className="bordered_photo"
            style={{ width: '250px', height: '200px', margin: '10px' }}
          />

          {propertyName && (
            <Paragraph
              columnWidth={12}
              content={propertyName || ''}
              caption="Property Name"
            />
          )}
        </div>
        <div className="col-xs-9 bm-responsive-div">
          {tenant && (
            <>
              <div className="row">
                <Paragraph
                  columnWidth={3}
                  content={firstName}
                  caption="First Name"
                />
                <Paragraph
                  columnWidth={3}
                  content={lastName}
                  caption="Last Name"
                />
                <Paragraph columnWidth={3} content={phone} caption="Phone" />
                <Paragraph columnWidth={3} content={email} caption="Email" />
              </div>
              <div className="row">
                <Paragraph
                  columnWidth={3}
                  content={nationality}
                  caption="Nationality"
                />
                <Paragraph
                  columnWidth={3}
                  content={FormatDate(tenant.createdat)}
                  caption="Created On"
                />
                <Paragraph
                  columnWidth={3}
                  content={idOrPassport}
                  caption="ID/Passport"
                />
                <Paragraph
                  columnWidth={3}
                  content={FormatDate(tenant.lastupdated)}
                  caption="Updated On"
                />
              </div>
              <div className="row">
                <Paragraph
                  columnWidth={3}
                  content={unitNumber}
                  caption="Unit Number"
                />
                <Paragraph
                  columnWidth={3}
                  content={FormatDate(new Date(+startDate * 1000))}
                  caption="Start Date"
                />
                <Paragraph columnWidth={3} content={status} caption="Status" />
              </div>
            </>
          )}
        </div>
        <div
          className="right"
          style={{ marginBottom: '20px', justifyContent: 'space-between' }}
        >
          <Button
            caption="<< Back To Tenant"
            cssClass="btn-success"
            onClick={() => {
              history.push({
                pathname: `/${source}`,
                property,
              });
              if (typeof Storage !== 'undefined') {
                window.localStorage.removeItem('property');
                window.localStorage.removeItem('tenant');
                window.localStorage.removeItem('source');
                window.localStorage.removeItem('unit');
              }
            }}
            type="button"
            isBlack
          />
          {tenant && user && PropertyAccess.includes(user.type) && (
            <>
              <Button
                caption="Edit Tenant"
                cssClass="btn-success"
                onClick={() => {
                  $('#tenantsModal').modal({
                    backdrop: 'static',
                    keyboard: false,
                  });
                }}
                type="button"
                isBlack
              />
            </>
          )}
        </div>
      </div>

      {tenant && (
        <div className="row">
          <Collapsible
            caption="Next Of Kin(s)"
            controlId="kins"
            columnWidth={12}
          >
            {user && PropertyAccess.includes(user.type) && (
              <NextOfKin
                tenantId={tenant.id}
                nextOfKins={nextOfKins}
                userToken={userToken}
                reLoadTable={reLoadTable}
              />
            )}
            <div className="row">
              {tenant && (
                <KinsTable
                  tenantId={tenant.id}
                  reLoadTable={reLoadTable}
                  userToken={userToken}
                  nextOfKins={nextOfKins}
                />
              )}
            </div>
          </Collapsible>
        </div>
      )}
    </Master>
  );
};

TenantProfile.propTypes = {
  userToken: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  user: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

TenantProfile.defaultProps = {
  userToken: {},
  location: {},
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(TenantProfile);

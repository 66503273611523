import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import { ExportTable, MercuryID } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import TableButton from '../Buttons/TableButton';
import iziQuestion from '../IziToast/question';
import IziToast from '../IziToast';
import UpdateKin from './UpdateKin';
import ModalButton from '../Buttons/ModalButton';
import PropertyAccess from '../../data/AccessRights';

const KinsTable = ({ reLoadTable, userToken, tenantId, nextOfKins, user }) => {
  // state
  const [selectedKin, setSelectedKin] = useState(null);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  // life-cycle hooks
  useEffect(() => {
    ExportTable('kins_table', 'properties');
  }, [nextOfKins]);

  const deleteNextOfKin = (kin) => {
    if (tenantId) {
      const index = nextOfKins.indexOf(kin);
      if (index > -1) {
        nextOfKins.splice(index, 1);
      }
      const formData = new FormData();
      formData.append('id', tenantId);
      formData.append('next_of_kin', JSON.stringify([...nextOfKins]));
      const formConfig = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      IziToast('Deleting...');
      axios
        .put(`${process.env.REACT_APP_API_URL}tenants`, formData, formConfig)
        .then((response) => {
          if (response.data.response.id) {
            IziToast('Next of kin successfully deleted');
            if (reLoadTable) reLoadTable();
          }
        })
        .catch((error) => {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                IziToast(error.response.data.error);
              }
            } else {
              IziToast(error.message);
            }
          } else {
            IziToast('An error occurred');
          }
        });
    } else {
      IziToast('Select next of kin to delete');
    }
  };

  const confirmDelete = (kin) => {
    iziQuestion(
      `Delete kin <i><b>${kin.name}</b></i> completely ?`,
      () => {
        deleteNextOfKin(kin);
      },
      undefined,
    );
  };

  return (
    <>
      <UpdateKin
        requestConfig={requestConfig}
        reLoadTable={reLoadTable}
        selectedKin={selectedKin}
        nextOfKins={nextOfKins}
        tenantId={tenantId}
      />
      {nextOfKins && nextOfKins.length ? (
        <Table tableId="kins_table" marginTop={10}>
          <thead>
            <tr>
              <th>No.</th>
              {user && PropertyAccess.includes(user.type) && (
                <>
                  <th>Edit</th>
                  <th>Delete</th>
                </>
              )}

              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Relationship</th>
            </tr>
          </thead>
          <tbody>
            {nextOfKins.map((kin, id) => {
              let index = id;
              return (
                <tr key={MercuryID()}>
                  <td>{++index}</td>
                  {user && PropertyAccess.includes(user.type) && (
                    <>
                      <td>
                        <ModalButton
                          caption="Edit"
                          onClick={() => {
                            setSelectedKin(kin);
                          }}
                          modalId="kinsModal"
                          data-backdrop="static"
                        />
                      </td>
                      <td>
                        <TableButton
                          caption="Delete"
                          onClick={() => {
                            confirmDelete(kin);
                          }}
                          buttonType="danger"
                        />
                      </td>
                    </>
                  )}
                  <td>{kin.name}</td>
                  <td>{kin.phone}</td>
                  <td>{kin.email}</td>
                  <td>{kin.relationship}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="No next of kins found" />
      )}
    </>
  );
};

KinsTable.propTypes = {
  reLoadTable: PropTypes.func.isRequired,
  userToken: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  tenantId: PropTypes.string.isRequired,
  nextOfKins: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

KinsTable.defaultProps = {
  userToken: {},
  nextOfKins: {},
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(KinsTable);

import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ children, tableId, tableName, marginTop, tableRef }) => {
  return (
    <div className="table-responsive" style={{ marginTop: `${marginTop}px` }}>
      <table
        cellPadding="1"
        cellSpacing="1"
        className="table table-bordered table-striped"
        id={tableId}
        name={tableName}
        ref={tableRef}
      >
        {children}
      </table>
    </div>
  );
};

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  tableId: PropTypes.string,
  tableName: PropTypes.string,
  marginTop: PropTypes.number,
  tableRef: PropTypes.objectOf(PropTypes.object),
};

Table.defaultProps = {
  tableId: '',
  tableName: '',
  marginTop: 1,
  tableRef: null,
};

export default Table;

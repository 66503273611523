/* eslint-disable */
/*!
 Bootstrap integration for DataTables' Buttons
 ©2015 SpryMedia Ltd - datatables.net/license
*/

import jQuery from 'jquery';

(function (c) {
  'function' === typeof define && define.amd
    ? define([
        'jquery',
        'datatables.net-bs',
        'datatables.net-buttons',
      ], function (a) {
        return c(a, window, document);
      })
    : 'object' === typeof exports
    ? (module.exports = function (a, b) {
        a || (a = window);
        if (!b || !b.fn.dataTable) b = require('datatables.net-bs')(a, b).$;
        b.fn.dataTable.Buttons || require('datatables.net-buttons')(a, b);
        return c(b, a, a.document);
      })
    : c(jQuery, window, document);
})(function (c) {
  var a = c.fn.dataTable;
  c.extend(!0, a.Buttons.defaults, {
    dom: {
      container: { className: 'dt-buttons btn-group' },
      button: { className: 'btn btn-default' },
      collection: {
        tag: 'ul',
        className: 'dt-button-collection dropdown-menu',
        button: { tag: 'li', className: 'dt-button' },
        buttonLiner: { tag: 'a', className: '' },
      },
    },
  });
  a.ext.buttons.collection.text = function (a) {
    return a.i18n('buttons.collection', 'Collection <span class="caret"/>');
  };
  return a.Buttons;
});

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import TextInput from '../TextInput';
import AlertActions from '../../actions/alert';
import Button from '../Buttons';
import RadioButton from '../RadioButton';
import AlertMessage from '../Alert';
import { ValidateEmail } from '../../utils/Functions';
import history from '../../helpers/history';

const RequestReset = ({ dispatch, alert }) => {
  //   state
  const [username, setUsername] = useState('');
  const [tokenMode, setTokenMode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const emailRef = useRef(null);

  useEffect(() => {
    dispatch(AlertActions.clear());
  }, [dispatch]);

  const onPasswordReset = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!username) {
      dispatch(AlertActions.error('Enter your resgistered email to continue'));
      emailRef.current.focus();
    } else if (!ValidateEmail(username)) {
      dispatch(AlertActions.error('Enter valid resgistered email to continue'));
      emailRef.current.focus();
    } else if (!tokenMode) {
      dispatch(
        AlertActions.error(
          'Select how you want to receive password reset code',
        ),
      );
    } else {
      dispatch(AlertActions.info('Requesting...'));
      try {
        const url = `${process.env.REACT_APP_API_URL}passforgot`;
        const {
          data: { response },
        } = await axios.post(url, {
          email: username,
          reset_option: tokenMode,
        });
        if (typeof response === 'string') {
          dispatch(AlertActions.success(response));
          //   redirect
          const redirect = { email: username, tokenMode };
          history.push({
            pathname: '/passwordreset',
            ...redirect,
          });
          //   save token locally
          if (typeof Storage !== 'undefined') {
            window.localStorage.setItem('resetToken', JSON.stringify(redirect));
          }
        } else {
          dispatch(AlertActions.error("Sorry, a problem occured. We couldn't reset your password"));
        }
      } catch (error) {
        let message = 'An error occurred';
        if (error) {
          if (error.response) {
            const { response } = error;
            if (response) {
              const { data } = response;
              if (data) {
                const { error: _error } = data;
                message = _error;
              }
            }
          }
        }
        dispatch(AlertActions.error(message));
      }
    }
  };

  return (
    <div className="login-container">
      <div className="row">
        <div className="col-md-12">
          <div className="text-center m-b-md">
            <h3>PASSWORD RECOVERY</h3>
          </div>
          <div className="hpanel">
            <div className="panel-body">
              {alert && alert.message && (
                <AlertMessage message={alert.message} alertType={alert.type} />
              )}
              <form onSubmit={onPasswordReset}>
                <TextInput
                  type="text"
                  placeholder="Enter your email"
                  caption="Enter your registered email address"
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                  value={username}
                  columnWidth={12}
                  isWrapped={false}
                  className={
                    isSubmitting && (!username || !ValidateEmail(username))
                      ? 'error'
                      : ''
                  }
                  inputRef={emailRef}
                />
                <div className="row">
                  <div className="col-md-12">
                    Select how you want to receive password reset code
                  </div>
                </div>
                <div className="row" style={{ margin: '10px' }}>
                  <div className="col-md-6">
                    <RadioButton
                      caption="Email"
                      checked={tokenMode === 'email'}
                      onClick={() => {
                        setTokenMode('email');
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <RadioButton
                      caption="SMS"
                      checked={tokenMode === 'sms'}
                      onClick={() => {
                        setTokenMode('sms');
                      }}
                    />
                  </div>
                </div>
                <Button
                  type="submit"
                  cssClass="btn-success btn-block"
                  caption="Request Password Reset"
                />
                <button
                  onClick={() => {
                    history.push('/signin');
                  }}
                  type="button"
                  title="Back To Login Page"
                  style={{
                    color: '#2083FE',
                    float: 'right',
                    marginTop: '25px',
                    border: 'none',
                    backgroundColor: 'inherit',
                  }}
                >
                  Back To Login Page
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

RequestReset.propTypes = {
  dispatch: PropTypes.func.isRequired,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

RequestReset.defaultProps = {
  alert: PropTypes.object,
};

export default connect(mapStateToProps)(RequestReset);

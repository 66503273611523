import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import { ExportTable /* , FormatDate */ } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import MiniButton from '../Buttons/MiniButton';
import iziQuestion from '../IziToast/question';
import IziToast from '../IziToast';

const MonthlyReportTable = ({ isLoading, error, arrears, user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  // refs
  const tableRef = React.useRef(null);
  // life-cycle hooks
  React.useEffect(() => {
    if (arrears.length > 0) {
      window.setTimeout(() => {
        ExportTable('arrears_table', 'Account Statement');
      });
    }
  }, [isLoading, arrears]);


  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      {arrears && arrears.length ? (
        <Table tableId="arrears_table" marginTop={10} tableRef={tableRef}>
          <thead>
            <tr>
              <th>No.</th>
              <th style={{ maxWidth: '40px' }}>Invoice Date</th>
              <th>Property</th>
              <th>Hse Unit.</th>
              <th>Invoice No.</th>
              <th>Status</th>
              <th>Description</th>
              <th>Payment Date</th>
              <th>Credit</th>
              <th>Debit</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {arrears.map((arrear, id) => {
              let index = id
              let date = arrear.date
              let payment_date = arrear.paymentDate
              let propertyName = arrear.property
              let hse_unit = arrear.houseUnit
              let invoice_number = arrear.invoiceNo
              let status = arrear.status
              let description = arrear.description
              let credit = arrear.credit
              let debit = arrear.debit
              let balance = arrear.balance < 0 ? "("+ (-arrear.balance) + ")" : arrear.balance

            
              return (
                <tr key={id}>
                  <td style={{ width: '5px' }}>{++index}</td>
                  <td>{date}</td>
                  <td>{propertyName}</td>
                  <td>{hse_unit}</td>
                  <td>{invoice_number}</td>
                  <td>{status}</td>
                  <td>{description}</td>
                  <td>{payment_date}</td>
                  <td>{credit}</td>
                  <td>{debit}</td>
                  <td>{balance}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="Seems no records found" />
      )}
    </>
  );
};

MonthlyReportTable.propTypes = {
  arrears: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

MonthlyReportTable.defaultProps = {
  error: null,
};

export default connect((state) => {
  return state;
})(MonthlyReportTable);

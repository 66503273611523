/* eslint-disable */

import jQuery from 'jquery';
/*!
 DataTables Bootstrap 3 integration
 ©2011-2015 SpryMedia Ltd - datatables.net/license
*/
(function (b) {
  'function' === typeof define && define.amd
    ? define(['jquery', 'datatables.net'], function (a) {
        return b(a, window, document);
      })
    : 'object' === typeof exports
    ? (module.exports = function (a, e) {
        a || (a = window);
        if (!e || !e.fn.dataTable) e = require('datatables.net')(a, e).$;
        return b(e, a, a.document);
      })
    : b(jQuery, window, document);
})(function (b, a, e) {
  var d = b.fn.dataTable;
  b.extend(!0, d.defaults, {
    dom:
      "<'row'<'col-sm-6'l><'col-sm-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-5'i><'col-sm-7'p>>",
    renderer: 'bootstrap',
  });
  b.extend(d.ext.classes, {
    sWrapper: 'dataTables_wrapper form-inline dt-bootstrap',
    sFilterInput: 'form-control input-sm',
    sLengthSelect: 'form-control input-sm',
    sProcessing: 'dataTables_processing panel panel-default',
  });
  d.ext.renderer.pageButton.bootstrap = function (a, h, r, m, j, n) {
    var o = new d.Api(a),
      s = a.oClasses,
      k = a.oLanguage.oPaginate,
      t = a.oLanguage.oAria.paginate || {},
      f,
      g,
      p = 0,
      q = function (d, e) {
        var l,
          h,
          i,
          c,
          m = function (a) {
            a.preventDefault();
            !b(a.currentTarget).hasClass('disabled') &&
              o.page() != a.data.action &&
              o.page(a.data.action).draw('page');
          };
        l = 0;
        for (h = e.length; l < h; l++)
          if (((c = e[l]), b.isArray(c))) q(d, c);
          else {
            g = f = '';
            switch (c) {
              case 'ellipsis':
                f = '&#x2026;';
                g = 'disabled';
                break;
              case 'first':
                f = k.sFirst;
                g = c + (0 < j ? '' : ' disabled');
                break;
              case 'previous':
                f = k.sPrevious;
                g = c + (0 < j ? '' : ' disabled');
                break;
              case 'next':
                f = k.sNext;
                g = c + (j < n - 1 ? '' : ' disabled');
                break;
              case 'last':
                f = k.sLast;
                g = c + (j < n - 1 ? '' : ' disabled');
                break;
              default:
                (f = c + 1), (g = j === c ? 'active' : '');
            }
            f &&
              ((i = b('<li>', {
                class: s.sPageButton + ' ' + g,
                id:
                  0 === r && 'string' === typeof c
                    ? a.sTableId + '_' + c
                    : null,
              })
                .append(
                  b('<a>', {
                    href: '#',
                    'aria-controls': a.sTableId,
                    'aria-label': t[c],
                    'data-dt-idx': p,
                    tabindex: a.iTabIndex,
                  }).html(f),
                )
                .appendTo(d)),
              a.oApi._fnBindAction(i, { action: c }, m),
              p++);
          }
      },
      i;
    try {
      i = b(h).find(e.activeElement).data('dt-idx');
    } catch (u) {}
    q(b(h).empty().html('<ul class="pagination"/>').children('ul'), m);
    i &&
      b(h)
        .find('[data-dt-idx=' + i + ']')
        .focus();
  };
  d.TableTools &&
    (b.extend(!0, d.TableTools.classes, {
      container: 'DTTT btn-group',
      buttons: { normal: 'btn btn-default', disabled: 'disabled' },
      collection: {
        container: 'DTTT_dropdown dropdown-menu',
        buttons: { normal: '', disabled: 'disabled' },
      },
      print: { info: 'DTTT_print_info' },
      select: { row: 'active' },
    }),
    b.extend(!0, d.TableTools.DEFAULTS.oTags, {
      collection: { container: 'ul', button: 'li', liner: 'a' },
    }));
  return d;
});

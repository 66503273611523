import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import $ from 'jquery';

import moment from 'moment';
import Master from '../Master';
import Collapsible from '../Panels/Collapsible';
import IziToast from '../IziToast';
import Select from '../Select';
import TransTable from './TransTable';
// import DatePicker from '../DatePicker';
import Button from '../Buttons';
import AlertMessage from '../Alert';
import AlertActions from '../../actions/alert';
import { DestroyTable, FormatDate } from '../../utils/Functions';

const RentRoll = ({
  user,
  dispatch,
  alert,
  location: {
    urlTransactions,
    filterProperty,
    filterTenant,
    filterStatus,
    // filterLowerDate,
    // filterUpperDate,
  },
}) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };

  //   refs
  const propertyRef = useRef(null);
  const tenantRef = useRef(null);

  // state
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  // const [lowerDate, setLowerDate] = useState(filterLowerDate || firstDay);
  // const [upperDate, setUpperDate] = useState(filterUpperDate || lastDay);
  const [propertyId, setPropertyId] = useState(filterProperty || '');
  const [properties, setProperties] = useState([]);
  const [tenantId, setTenantId] = useState(filterTenant || '');
  const [tenants, setTenants] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(!!urlTransactions);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(filterStatus || '');
  const [urlReload, setUrlReload] = useState(urlTransactions);
  const [units, setUnits] = useState([]);
  const [tenantsList, setTenantsList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);

  const PaymentStatus = [
    {
      id: 'paid',
      name: 'Paid',
    },
    {
      id: 'arrears',
      name: 'Arrears',
    },
    {
      id: 'overpayment',
      name: 'Overpayment',
    },
  ];

  useEffect(() => {
    dispatch(AlertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        let url = `/byuser?status=active&user_id=${user.id}`;
        if (user && user.type === 'admin') {
          url = '?take=1000&where={"status":"active"}&order={"name":"ASC"}';
        }
        const {
          data: { response },
        } = await axios.get(
          `${process.env.REACT_APP_API_URL}properties${url}`,
          requestConfig,
        );
        setProperties(response);
        loadUnits(response);
        if (filterProperty) setPropertyId(filterProperty);
        $(propertyRef.current)
          .select2()
          .on('change', (event) => {
            setPropertyId(event.val);
          });
      } catch (_) {
        IziToast('Could not load your properties');
      }
    })();
  }, []);

  useEffect(() => {
    filterByProperty();
    setTenantId('');
  }, [propertyId]);

  useEffect(() => {
    if (tenantId) {
      DestroyTable('transactions_table');
      const transaction = filterByTenant(transactionList);
      setTransactions(transaction);
    } else {
      setTransactions(transactionList);
    }
  }, [tenantId]);

  useEffect(() => {
    if (transactions.length > 0) {
      const sum = transactions.filter((t) => { return t.amountPaid !== '-'; })
        .reduce((acc, curr) => {
          return parseFloat(acc) + parseFloat(curr.amountPaid);
        }, 0);
      setTotal(sum || 0);
    } else {
      setTotal(0);
    }
  }, [transactions]);

  useEffect(() => {
    DestroyTable('transactions_table');
    if (status) {
      filterByStatus();
    }
  }, [status]);

  function filterByTenant(transactions_list) {
    if (tenantId !== '') {
      const transactions = transactions_list.filter((item) => {
        if (item && item.tenant) {
          return item.tenant.id === tenantId;
        }
        return undefined;
      });
      return transactions;
    }
    return transactionList;
  }

  function filterByStatus() {
    if (status && status !== '-') {
      const filtered = transactionList.filter((item) => {
        return item.status.toLowerCase() === status.toLowerCase();
      });
      setTransactions(filtered);
    } else {
      setTransactions(transactionList);
    }
  }

  function filterByProperty() {
    if (propertyId) {
      // DestroyTable('transactions_table');
      const filtered = tenantsList.filter((item) => {
        return item.unit.property_id === propertyId;
      });
      setTenants(filtered);
      // const transaction = transactionList.filter((item) => {
      //   if (item && item.property) {
      //     return item.property.id === propertyId;
      //   }
      // });
      // setTransactions(transaction);
    } else {
      setTenants(tenantsList);
      // setTransactions(transactionList);
    }
  }

  /**
   *
   * @param {[]} property
   */
  async function loadUnits(property) {
    const propertyIds = property.map((item) => {
      return item.id;
    });
    let urlUnits;
    // get units
    if (user.type === 'admin') {
      urlUnits = `${process.env.REACT_APP_API_URL}units?take=10000`;
    } else {
      urlUnits = `${process.env.REACT_APP_API_URL
      }units?where={"property_id":"in::${propertyIds.join()}"}`;
    }
    try {
      const {
        data: { response: unitsInfo },
      } = await axios.get(urlUnits, requestConfig);
      setUnits(unitsInfo);
      loadTenants(unitsInfo, property);
    } catch (_) {
      //
    }
  }

  /**
   *
   * @param {[]} unitsInfo
   * @param {[]} propertyInfo
   */
  function loadTenants(unitsInfo, propertyInfo) {
    const searchUnit = (id) => {
      return unitsInfo.find((item) => {
        return item.id === id;
      });
    };
    (async () => {
      try {
        const unitIds = unitsInfo.map((item) => {
          return item.id;
        });
        let urlTenants;
        // get tenats
        if (user.type === 'admin') {
          urlTenants = `${process.env.REACT_APP_API_URL}tenants?status=active`;
        } else {
          urlTenants = `${process.env.REACT_APP_API_URL
          }tenants?where={"unit_id":"in::${unitIds.join()}", "status":"active"}`;
        }
        const {
          data: { response },
        } = await axios.get(urlTenants, requestConfig);
        // response is tenantsInfo
        const mapped = response.map((item) => {
          const element = item;
          element.unit = searchUnit(element.unit_id);
          return element;
        });
        setTenants(mapped);
        setTenantsList(mapped);
        setTenantId(filterTenant || '');
        $(tenantRef.current)
          .select2()
          .on('change', (event) => {
            setTenantId(event.val);
          });
        if (urlTransactions) {
          reLoadTransactions(unitsInfo, propertyInfo, response);
        }
      } catch (_) {
        //
      }
    })();
  }

  /**
   *
   * @param {[]} unitsInfo
   * @param {[]} propertyInfo
   * @param {[]} tenantsInfo
   */
  async function reLoadTransactions(unitsInfo, propertyInfo, tenantsInfo) {
    if (urlTransactions) {
      let filter = '';
      try {
        const fromDate = moment(firstDay, 'YYYY-MM-DD').format('YYYY-MM-DD');
        filter = `"invoice_date":"btwn::${FormatDate(fromDate)},${FormatDate(lastDay)}"`;
        // const propertyIds = propertyInfo.map((item) => {
        //   return item.id;
        // });

        if (propertyId) {
          filter = `${filter},"payee_id":"${propertyId}"`;
        }

        // get payaments

        if (user.type !== 'admin') {
          const tenantsIds = tenants.map((item) => {
            return item.id;
          });
          // filter = `${filter},"payer_id":"in::${tenantsIds.join()}"`;
        }
        if (filter && propertyId) {
          setIsLoading(true);
          const urlReceipts = `${process.env.REACT_APP_API_URL}invoices/rentroll?where={${filter}}`;
          const {
            data: { response: receipts },
          } = await axios.get(urlReceipts, requestConfig);
          mapReloadTransactions(
            receipts,
          );
        }
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    }
  }

  /**
   * @param {[]} receipts
   */
  function mapReloadTransactions(
    receipts,
  ) {
    let mapped = (receipts);

    mapped = sortData(mapped);

    const pId = urlTransactions ? filterProperty : propertyId;
    if (pId) {
      const filtered = mapped.filter((item) => {
        if (item && item.payformatDataer_id) {
          return item.payer_id === pId;
        }
        return undefined;
      });
      setTransactions(filtered);
    } else {
      setTransactions(mapped);
    }
    setIsLoading(false);
  }

  function sortData(arr) {
    return arr.sort((a, b) => {
      return moment(b.createdat).unix() - moment(a.createdat).unix();
    });
  }

  async function onSearchTransaction() {
    let filter = '';
    if (propertyId) {
      // if (tenantId) {
      //   filter = `"payer_id":"${tenantId}"`;
      // }
      // filter by date
      const query = filter ? ',' : '';
      const fromDate = moment(firstDay, 'YYYY-MM-DD').format('YYYY-MM-DD');
      filter = `${filter}${query}"invoice_date":"btwn::${FormatDate(fromDate)},${FormatDate(lastDay)}"`;
    }
    if (!filter) {
      dispatch(AlertActions.error('Select a property to continue'));
      return;
    }
    try {
      // const propertyIds = properties.map((item) => {
      //   return item.id;
      // });
      // filter = `${filter},"payee_id":"in::${propertyIds.join()}"`;

      if (propertyId) {
        filter = `${filter},"payee_id":"${propertyId}"`;
      }

      // get payments
      if (user.type !== 'admin') {
        const tenantsIds = tenants.map((item) => {
          return item.id;
        });
        // filter = `${filter},"payer_id":"in::${tenantsIds.join()}"`;
      }
      if (filter && propertyId) {
        setIsLoading(true);
        const urlReceipts = `${process.env.REACT_APP_API_URL}invoices/rentroll?where={${filter}}`;
        setUrlReload(urlReceipts);
        const {
          data: { response },
        } = await axios.get(urlReceipts, requestConfig);
        mapTransactions(response);
        setIsLoading(false);
      }
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }

  /**
   * @param {{}} response
   */
  function mapTransactions(response) {
    const rentrollData = response;

    // filter by status
    const byStatus = status ?
      sortData(rentrollData.filter((rd) => { return rd.status === status; }))
      : sortData(rentrollData);

    // filter by property
    if (propertyId) {
      const filtered = byStatus.filter((item) => {
        if (item && item.property) {
          return item.property.id === propertyId;
        }
      });
      if (filtered) {
        setTransactions(filtered);
      }
    } else {
      setTransactions(byStatus);
    }
    setTransactionList(byStatus);
    filterByTenant(byStatus);
    setIsLoading(false);
  }

  return (
    <Master redirectLink="rentroll" title="Finance" source="Rent Roll">
      <div className="row">
        <div className="col-md-12">
          {alert && alert.message && (
            <AlertMessage
              alertType={`alert ${alert.type}`}
              message={alert.message}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <div className="row">
            <Select
              caption="Property Name"
              value={propertyId}
              onChange={(event) => {
                setPropertyId(event.target.value);
              }}
              columnWidth={5}
              inputRef={propertyRef}
            >
              <option value="">Select property name</option>
              {properties.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
            <Select
              caption="Tenant"
              value={tenantId}
              onChange={(event) => {
                setTenantId(event.target.value);
              }}
              columnWidth={5}
              inputRef={tenantRef}
            >
              <option value="">Select tenant</option>
              {tenants.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {`${item.first_name} ${item.last_name} | ${item.other_details ? item.other_details.unit_number : '--'}`}
                  </option>
                );
              })}
            </Select>
            <Select
              caption="Status"
              value={status}
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              columnWidth={2}
            >
              <option value="-">Select status</option>
              {PaymentStatus.map((payStatus) => {
                return (
                  <option value={payStatus.id} key={payStatus.id}>
                    {payStatus.name}
                  </option>
                );
              })}
            </Select>
          </div>
          <div className="row">
            {/* <DatePicker
              placeholder="Select date from"
              caption="Date From"
              onChange={(date) => {
                setLowerDate(date);
              }}
              columnWidth={2}
              selected={lowerDate}
            />
            <DatePicker
              placeholder="Select date to"
              caption="Date To  &nbsp; &nbsp;"
              onChange={(date) => {
                setUpperDate(date);
              }}
              columnWidth={2}
              selected={upperDate}
            /> */}
            <div className="col-md-3 m-t-sm">
              <h3>
                {`Date: ${moment(firstDay, 'YYYY-MM-DD').format('MMMM YYYY')}`}
              </h3>
            </div>
            <div className="col-md-3 invoice-button">
              <Button
                caption="Search"
                cssClass="btn-success"
                onClick={onSearchTransaction}
                type="button"
                isBlack={false}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="hpanel">
            <div className="panel-body">
              <span>Total Amount Paid</span>
              <br />
              <h1 className="primary-red">{`Ksh ${total.toLocaleString()}`}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <Collapsible columnWidth={12} caption="Rent Roll" controlId="rentroll">
          <TransTable
            isLoading={isLoading}
            dispatch={dispatch}
            error={error}
            transactions={transactions}
            userToken={userToken}
            urlTransactions={urlReload}
            filterProperty={propertyId}
            filterTenant={tenantId}
            filterStatus={status}
            filterLowerDate={firstDay}
            filterUpperDate={lastDay}
          />
        </Collapsible>
      </div>
    </Master>
  );
};

RentRoll.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dispatch: PropTypes.func.isRequired,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

RentRoll.defaultProps = {
  user: {},
  alert: {},
  location: {},
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(RentRoll);

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import useAxiosGet from '../../hooks/useAxiosGet';
import { ExportTable, FormatDate } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import Master from '../Master';
import Button from '../Buttons';

// eslint-disable-next-line no-unused-vars
const Occupancy = ({ location: { userToken, property } }) => {
  if (typeof Storage !== 'undefined') {
    if (!property) {
      const propStr = window.localStorage.getItem('property');
      const userStr = window.localStorage.getItem('nyumbani');
      // eslint-disable-next-line no-param-reassign
      if (propStr) property = JSON.parse(propStr);
      // eslint-disable-next-line no-param-reassign
      if (userStr) userToken = JSON.parse(userStr).token || {};
    }
  }
  const tableRef = useRef(null);
  // state
  const history = useHistory();
  const propertyId = property ? property.id : '';

  const url = `${process.env.REACT_APP_API_URL}property/occupancy?where={"id":"in::${propertyId}"}&take=1000`;
  const [data, error, isLoading] = useAxiosGet(url, []);

  let occupancy = [];
  if (data && data.response) {
    const items = Object.values(data.response);
    if (items && items.length) {
      occupancy = items[0].tenants;
    }
  }

  // life-cycle hooks
  useEffect(() => {
    window.setTimeout(() => {
      ExportTable('occupancy_table', 'occupancy');
    });
  }, [isLoading]);

  return (
    <Master
      redirectLink="occupancy"
      title="Property Management"
      source="Occupancy"
    >
      {isLoading ? (
        <InfoPanel message="Loading..." />
      ) : error ? (
        <InfoPanel message="An error occurred" hasError />
      ) : (
        <>
          {occupancy && occupancy.length > 0 ? (
            <Table tableId="occupancy_table" marginTop={10} tableRef={tableRef}>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Unit No</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Nationality</th>
                  <th>Status</th>
                  <th>Start Date</th>
                </tr>
              </thead>
              <tbody>
                {occupancy.map((tenant, id) => {
                  let index = id;
                  let unitNumber = '';
                  if (tenant.other_details) {
                    const otherDetails = tenant.other_details;
                    unitNumber = otherDetails.unit_number;
                  }
                  const startDate = new Date(+tenant.start_date * 1000);
                  return (
                    <tr key={tenant.id}>
                      <td>{++index}</td>
                      <td>{unitNumber}</td>
                      <td>{tenant.first_name}</td>
                      <td>{tenant.last_name}</td>
                      <td>{tenant.email}</td>
                      <td>{tenant.phone}</td>
                      <td>{tenant.nationality}</td>
                      <td>{tenant.status}</td>
                      <td>{FormatDate(startDate)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <InfoPanel message="Seems no tenant found" />
          )}
          <div className="right">
            <Button
              caption="<< Back To Property"
              cssClass="btn-success"
              onClick={() => {
                if (typeof Storage !== 'undefined') {
                  window.localStorage.removeItem('property');
                }
                history.push({
                  pathname: '/property',
                  property,
                });
              }}
              type="button"
              isBlack
            />
          </div>
        </>
      )}
    </Master>
  );
};

Occupancy.propTypes = {
  userToken: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  property: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Occupancy.defaultProps = {
  userToken: {},
  property: {},
  location: {},
};

export default Occupancy;

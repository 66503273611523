import React from 'react';
import PropTypes from 'prop-types';

const ActionButton = ({ caption, onClick }) => {
  return (
    <button
      className="btn btn-info btn-sm"
      style={{ backgroundColor: '#D73836' }}
      onClick={onClick}
      type="button"
    >
      {`  ${caption}`}
    </button>
  );
};

ActionButton.propTypes = {
  caption: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ActionButton;

/**
 * whatever am doing here is bad practice but i have to
 * api is not giving meaningful responses so i have
 * to use what is available for now.
 * please don't @me :(
 */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import $ from 'jquery';

import Master from '../Master';
import Select from '../Select';
import TextInput from '../TextInput';
import IziToast from '../IziToast';
import Button from '../Buttons';
import Collapsible from '../Panels/Collapsible';
import UtilityTable from './UtilityTable';
import DatePicker from '../DatePicker';
import { DestroyTable } from '../../utils/Functions';

const Utilities = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };

  //   refs
  const propertyRef = useRef(null);
  const tenantRef = useRef(null);
  // state
  const [propertyId, setPropertyId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [utilityName, setUtilityName] = useState('');
  const [utilityAmount, setUtilityAmount] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [units, setUnits] = useState([]);
  const [tenantId, setTenantId] = useState('');
  const [tenants, setTenants] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [tenantsList, setTenantsList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let url = `/byuser?status=active&user_id=${user.id}`;
        if (user && user.type === 'admin') {
          url = '?take=1000&where={"status":"active"}&order={"name":"ASC"}';
        }
        const {
          data: { response },
        } = await axios.get(
          `${process.env.REACT_APP_API_URL}properties${url}`,
          requestConfig,
        );
        setProperties(response);
        loadUnits(response);
        $(propertyRef.current)
          .select2()
          .on('change', (event) => {
            setPropertyId(event.val);
          });
      } catch (_) {
        IziToast('Could not load your properties');
      }
    })();
  }, []);

  useEffect(() => {
    filterByProperty();
    setTenantId('');
  }, [propertyId]);

  useEffect(() => {
    if (tenantId) {
      // const transaction = transactionList.filter((item) => {
      //   if (item && item.tenant) {
      //     return item.tenant.id === tenantId;
      //   }
      //   return undefined;
      // });
      // setTransactions(transaction);
    } else if (propertyId) {
      filterByProperty();
    } else {
      // setTransactions(transactionList);
    }
  }, [tenantId]);

  function filterByProperty() {
    if (propertyId) {
      const filtered = tenantsList.filter((item) => {
        return item.unit.property_id === propertyId;
      });
      setTenants(filtered);
      // const transaction = transactionList.filter((item) => {
      //   if (item && item.property) {
      //     return item.property.id === propertyId;
      //   }
      //   return undefined;
      // });
      // setTransactions(transaction);
    } else {
      setTenants(tenantsList);
      // setTransactions(transactionList);
    }
  }

  /**
   *
   * @param {[]} property
   */
  async function loadUnits(property) {
    const propertyIds = property.map((item) => {
      return item.id;
    });
    let urlUnits;
    // get units
    if (user.type === 'admin') {
      urlUnits = `${process.env.REACT_APP_API_URL}units?take=10000`;
    } else {
      urlUnits = `${
        process.env.REACT_APP_API_URL
      }units?take=10000&where={"property_id":"in::${propertyIds.join()}"}`;
    }
    try {
      const {
        data: { response: unitsInfo },
      } = await axios.get(urlUnits, requestConfig);
      setUnits(unitsInfo);
      loadTenants(unitsInfo, property);
    } catch (_) {
      //
    }
  }

  /**
   *
   * @param {[]} unitsInfo
   */
  function loadTenants(unitsInfo) {
    function searchUnit(id) {
      return unitsInfo.find((item) => {
        return item.id === id;
      });
    }
    (async () => {
      try {
        const unitIds = unitsInfo.map((item) => {
          return item.id;
        });
        let urlTenants;
        // get tenats
        if (user.type === 'admin') {
          urlTenants = `${process.env.REACT_APP_API_URL}tenants?take=10000`;
        } else {
          urlTenants = `${
            process.env.REACT_APP_API_URL
          }tenants?take=10000&where={"unit_id":"in::${unitIds.join()}"}`;
        }
        const {
          data: { response },
        } = await axios.get(urlTenants, requestConfig);
        const mapped = response.map((item) => {
          const element = item;
          element.unit = searchUnit(element.unit_id);
          return element;
        });
        setTenants(mapped);
        setTenantsList(mapped);
        $(tenantRef.current)
          .select2()
          .on('change', (event) => {
            setTenantId(event.val);
          });
      } catch (_) {
        //
      }
    })();
  }

  const saveUtility = () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (utilityName && utilityAmount && tenantId && invoiceDate) {
      if (parseFloat(utilityAmount) <= 0) {
        message = 'Enter valid utility amount';
        IziToast(message);
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }

    if (isValid) {
      const saveObject = {
        tenant_id: tenantId,
        created_by: user.id,
        name: utilityName,
        amount: Number(utilityAmount),
        date: new Date(invoiceDate).getTime(),
      };
      IziToast('Saving...');
      axios
        .post(
          `${process.env.REACT_APP_API_URL}utilities`,
          saveObject,
          requestConfig,
        )
        .then((response) => {
          if (response.data.response.id) {
            IziToast('Utility successfully saved');
            resetFields();
          }
          DestroyTable('utilities_table');
          setIsLoading(!isLoading);
        })
        .catch((error) => {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                IziToast(error.response.data.error);
              }
            }
          } else {
            IziToast('An error occurred');
          }
        });
    }
  };

  const resetFields = () => {
    // setPropertyId('');
    setTenantId('');
    // setUtilityName('');
    setUtilityAmount('');
    setIsSubmitting(false);
    // setInvoiceDate(new Date());
  };

  return (
    <Master redirectLink="utilities" title="Finance" source="Utilities">
      <div className="row">
        <Select
          caption="Select Property"
          value={propertyId}
          onChange={(event) => {
            setPropertyId(event.target.value);
          }}
          columnWidth={4}
          className={
            isSubmitting && tenantId === 'property' && !propertyId
              ? 'error'
              : ''
          }
          inputRef={propertyRef}
        >
          <option value="">Select property name</option>
          {properties.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </Select>
        <Select
          caption="Select Tenant"
          value={tenantId}
          onChange={(event) => {
            setTenantId(event.target.value);
          }}
          columnWidth={4}
          className={isSubmitting && !tenantId ? 'error' : ''}
          inputRef={tenantRef}
        >
          <option value="">Select Tenant</option>
          {tenants.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {`${item.first_name} ${item.last_name} | ${item.other_details ? item.other_details.unit_number : '--'}`}
              </option>
            );
          })}
        </Select>
        <TextInput
          type="text"
          placeholder="Enter utility amount"
          caption="Utility Amount"
          onChange={(e) => {
            const value = /^[0-9\b]+$/;
            // allows number only
            if (e.target.value === '' || value.test(e.target.value)) {
              setUtilityAmount(e.target.value);
            }
          }}
          value={utilityAmount}
          columnWidth={2}
          className={
            isSubmitting && (!utilityAmount || parseFloat(utilityAmount) <= 0)
              ? 'error'
              : ''
          }
        />
        <DatePicker
          placeholder="Select date"
          caption="Date Recorded"
          onChange={(date) => {
            return setInvoiceDate(date);
          }}
          columnWidth={2}
          selected={invoiceDate}
        />
      </div>
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter utility name"
          caption="Utility Name"
          onChange={(e) => {
            setUtilityName(e.target.value);
          }}
          value={utilityName}
          columnWidth={6}
          className={isSubmitting && !utilityName ? 'error' : ''}
        />
        <div className="col-md-3 m-t-md">
          <p>
            <b>
              Note: Utility items created get attached to the next invoice created by the system.
            </b>
          </p>
        </div>
        <div className="col-md-2 inline-right-button">
          <Button
            caption="Create Utility"
            cssClass="btn-success"
            onClick={saveUtility}
            type="button"
            isBlack={false}
            minWidth={190}
          />
        </div>
      </div>
      <Collapsible
        caption="Utility List"
        columnWidth={12}
        controlId="utility_list"
      >
        <UtilityTable reLoadTable={isLoading} requestConfig={requestConfig} />
      </Collapsible>
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

Utilities.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Utilities.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(Utilities);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { ExportTable, FormatDate } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import InvoiceStatus from '../../data/InvoiceStatus';
import MiniButton from '../Buttons/MiniButton';
import IziToast from '../IziToast';
import iziResendInvoice from '../IziToast/ResendInvoice';

const InvoiceTable = ({ isLoading, error, invoices }) => {
  // life-cycle hooks
  const user = JSON.parse(window.localStorage.getItem('nyumbani'));
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${user.token}`,
      'Content-Type': 'application/json',
    },
  };

  useEffect(() => {
    ExportTable('invoices_table', 'invoices');
  }, [isLoading, invoices]);

  async function onSendInvoice(invoice) {
    const SMSbody = {
      id: invoice.id,
      send_sms: true,
      invoice_type: 'rent',
      changelog: [{
        user: {
          id: user.id,
          type: user.type,
        },
        action: 'Re-sending invoice via SMS',
        timestamp: new Date().getTime(),
      }],
    };

    const Emailbody = {
      id: invoice.id,
      send_email: true,
      invoice_type: 'rent',
      changelog: [{
        user: {
          id: user.id,
          type: user.type,
        },
        action: 'Re-sending invoice via SMS',
        timestamp: new Date().getTime(),
      }],
    };

    iziResendInvoice(
      'Resend this invoice  as ?',
      () => {
        resendInvoiceAsSMS(SMSbody, requestConfig);
      },
      () => {
        resendInvoiceASEmail(Emailbody, requestConfig);
      },
      undefined,
    );
  }

  async function resendInvoiceAsSMS(body, requestConfig) {
    const {
      data,
    } = await axios.put(
      `${process.env.REACT_APP_API_URL}invoices`,
      body,
      requestConfig,
    );
    if (data) {
      IziToast('Successfully sent an Invoice SMS', 'green');
    } else {
      IziToast('Error sending Invoice SMS', 'red');
    }
  }

  async function resendInvoiceASEmail(body, requestConfig) {
    const {
      data,
    } = await axios.put(
      `${process.env.REACT_APP_API_URL}invoices`,
      body,
      requestConfig,
    );
    if (data) {
      IziToast('Successfully sent an Invoice Email', 'green');
    } else {
      IziToast('Error sending Invoice Email', 'red');
    }
  }

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      {invoices && invoices.length ? (
        <Table tableId="invoices_table" marginTop={10}>
          { JSON.parse(window.localStorage.getItem('nyumbani')).type == 'admin' ? (
            <thead>
              <tr>
                <th>No.</th>
                <th>Invoice Date</th>
                <th>Property</th>
                <th>Unit No.</th>
                <th>Name</th>
                <th>Amount</th>
                <th>Invoice No.</th>
                <th>Particular</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
          )
            : (
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Invoice Date</th>
                  <th>Property</th>
                  <th>Unit No.</th>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Invoice No.</th>
                  <th>Particular</th>
                  <th>Status</th>
                </tr>
              </thead>
            )}
          <tbody>
            {invoices.map((invoice, id) => {
              let index = id;
              let propertyName = '';
              let unitNumber = '';
              let tenantName = '';
              let particular = '-';
              if (invoice.reference_data) {
                const { reference_data: referenceData } = invoice;
                if (referenceData) {
                  particular = referenceData.info;
                }
              }

              const invoiceNo = `INV-${String(invoice.id)
                .substring(0, 10)
                .toUpperCase()}`;

              if (invoice.property) {
                const { property } = invoice;
                if (property) {
                  propertyName = property.name;
                }
              }

              if (invoice.tenant) {
                const { tenant } = invoice;
                if (tenant) {
                  tenantName = `${tenant.first_name} ${tenant.last_name}`;
                  if (tenant.other_details) {
                    unitNumber = tenant.other_details.unit_number || '';
                  }
                }
              }

              return (
                JSON.parse(window.localStorage.getItem('nyumbani')).type === 'admin' ? (
                  <tr key={invoice.id}>
                    <td>{++index}</td>
                    <td>{FormatDate(invoice.invoice_date)}</td>
                    <td>{propertyName}</td>
                    <td>{unitNumber}</td>
                    <td>{tenantName}</td>
                    <td>{invoice.total_amount}</td>
                    <td>{invoiceNo}</td>
                    <td>{particular}</td>
                    <td>{InvoiceStatus(invoice.status) || ''}</td>
                    <td>
                      <MiniButton
                        caption="Resend Invoice"
                        onClick={() => {
                          onSendInvoice(invoice);
                        }}
                        buttonType="danger"
                      />
                    </td>
                  </tr>
                )
                  : (
                    <tr key={invoice.id}>
                      <td>{++index}</td>
                      <td>{FormatDate(invoice.invoice_date)}</td>
                      <td>{propertyName}</td>
                      <td>{unitNumber}</td>
                      <td>{tenantName}</td>
                      <td>{invoice.total_amount}</td>
                      <td>{invoiceNo}</td>
                      <td>{particular}</td>
                      <td>{InvoiceStatus(invoice.status) || ''}</td>
                    </tr>
                  )
              );
            })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="Seems no invoices found" />
      )}
    </>
  );
};

InvoiceTable.propTypes = {
  invoices: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

InvoiceTable.defaultProps = {
  error: null,
};

export default InvoiceTable;

import { useState, useEffect } from 'react';
import axios from 'axios';
import store from '../helpers/store';

/**
 * @name useAxiosGet
 * @description Makes a GET request to a HTTP server
 * @param {String} url The endpoint url
 */
const useAxiosGet = (url, dependency = []) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // grab current state
  const state = store.getState();
  // get the JWT token out of it
  const authToken = state.user.token;
  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      try {
        const result = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setData(result.data);
      } catch (err) {
        setError(err);
      }
      setIsLoading(false);
    };
    getData();
  }, dependency);
  return [data, error, isLoading];
};

export default useAxiosGet;

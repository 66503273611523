import React from 'react';

import './style.css';

const Spinner = () => {
  return (
    <div id="loader">
      <svg
        id="spinner"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 80 80"
      >
        <defs>
          <path id="a" d="M0 0h40v40H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <circle cx="40" cy="40" r="38" stroke="#ffc8bd" strokeWidth="2.817" />
          <g transform="translate(40)">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a" />
            </mask>
            <circle
              cy="40"
              r="38"
              stroke="#e21f38"
              strokeWidth="2.817"
              mask="url(#b)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Spinner;

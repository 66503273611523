import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import Master from '../Master';
import Collapsible from '../Panels/Collapsible';
import { ExportTable, FormatDate } from '../../utils/Functions';
import InfoPanel from '../Panels/InfoPanel';
import Table from '../Table';

const AuditLogs = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };

  // state
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}logs?take=1000&order={"timestamp":"DESC"}`;
        const {
          data: { response },
        } = await axios.get(url, requestConfig);
        setLogs(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setHasError(true);
      }
      return () => {};
    })();
  }, []);

  useEffect(() => {
    window.setTimeout(() => {
      ExportTable('logs_table', 'logs');
    });
  }, [isLoading]);

  return (
    <Master
      redirectLink="auditlogs"
      title="Settings & Configuration"
      source="Audit Logs"
    >
      <Collapsible
        caption="System Logs List"
        columnWidth={12}
        controlId="logs_list"
      >
        {isLoading ? (
          <InfoPanel message="Loading..." />
        ) : hasError ? (
          <InfoPanel message="An error occurred" hasError />
        ) : (
          <>
            {logs.length > 0 ? (
              <Table tableId="logs_table" marginTop={10}>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Time/Date</th>
                    <th>Action</th>
                    <th>Meta</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log, id) => {
                    let index = id;
                    return (
                      <tr key={log.timestamp}>
                        <td>{++index}</td>
                        <td style={{ minWidth: '130px' }}>
                          {FormatDate(log.timestamp, true)}
                        </td>
                        <td>{log.message}</td>
                        <td>{JSON.stringify(log.meta)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <InfoPanel message="No Logs Found" />
            )}
          </>
        )}
      </Collapsible>
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

AuditLogs.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

AuditLogs.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(AuditLogs);

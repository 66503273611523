import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import TextInput from '../TextInput';
import { ValidateEmail } from '../../utils/Functions';
import Button from '../Buttons';
import IziToast from '../IziToast';

const NextOfKin = ({ tenantId, userToken, reLoadTable, nextOfKins }) => {
  const [phone, setPhone] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [relationship, setRelationship] = useState('');
  const [email, setEmail] = useState('');

  const saveNextOfKin = () => {
    setIsSubmitting(true);

    let isValid = false;
    let message;
    if (tenantId && name && relationship && phone && email) {
      if (!ValidateEmail(email)) {
        isValid = false;
        message = 'Enter a valid email address';
        IziToast(message);
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }

    if (isValid) {
      let kinData;
      const kinObj = {
        name,
        email,
        phone,
        relationship,
      };
      if (nextOfKins && nextOfKins.length) {
        kinData = [...nextOfKins, kinObj];
      } else {
        kinData = [kinObj];
      }

      const formData = new FormData();
      formData.append('id', tenantId);
      formData.append('next_of_kin', JSON.stringify(kinData));
      const formConfig = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      IziToast('Saving...');
      axios
        .put(`${process.env.REACT_APP_API_URL}tenants`, formData, formConfig)
        .then((response) => {
          if (response.data.response.id) {
            IziToast('Next of kin successfully saved');
            if (reLoadTable) reLoadTable();
            resetFields();
          }
        })
        .catch((error) => {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                IziToast(error.response.data.error);
              }
            } else {
              IziToast(error.message);
            }
          } else {
            IziToast('An error occurred');
          }
        });
    }
  };

  const resetFields = () => {
    setName('');
    setRelationship('');
    setPhone('');
    setIsSubmitting(false);
    setEmail('');
  };

  return (
    <div className="row">
      <TextInput
        type="text"
        placeholder="Enter kin name"
        caption="Kin Name"
        onChange={(event) => {
          setName(event.target.value);
        }}
        value={name}
        columnWidth={3}
        className={isSubmitting && !name ? 'error' : ''}
      />
      <TextInput
        type="text"
        placeholder="Enter phone number"
        caption="Phone Number"
        onChange={(e) => {
          const value = /^[0-9\b]+$/;
          // allows number only
          if (e.target.value === '' || value.test(e.target.value)) {
            setPhone(e.target.value);
          }
        }}
        value={phone}
        columnWidth={2}
        className={isSubmitting && !phone ? 'error' : ''}
      />
      <TextInput
        type="text"
        placeholder="Enter email"
        caption="Email"
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        value={email}
        name="email"
        columnWidth={2}
        className={
          isSubmitting && (!email || !ValidateEmail(email)) ? 'error' : ''
        }
      />
      <TextInput
        type="text"
        placeholder="Enter relationship"
        caption="Relationship"
        onChange={(event) => {
          setRelationship(event.target.value);
        }}
        value={relationship}
        columnWidth={3}
        className={isSubmitting && !relationship ? 'error' : ''}
      />
      <div style={{ marginTop: '15px' }}>
        <Button
          caption="Save Next of Kin"
          cssClass="btn-success"
          onClick={saveNextOfKin}
          type="button"
          isBlack={false}
        />
      </div>
    </div>
  );
};

NextOfKin.propTypes = {
  tenantId: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  nextOfKins: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  userToken: PropTypes.string.isRequired,
  reLoadTable: PropTypes.func.isRequired,
};

NextOfKin.defaultProps = {
  nextOfKins: {},
};

export default NextOfKin;

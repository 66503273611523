import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import useAxiosGet from '../../hooks/useAxiosGet';
import { ExportTable, FormatDate } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import ButtonLink from '../Buttons/ButtonLink';

const TenantsTable = ({ userToken, property }) => {
  // state
  const history = useHistory();
  const propertyId = property ? property.id : '';

  const url = `${process.env.REACT_APP_API_URL}property/occupancy?where={"id":"in::${propertyId}"}&take=1000`;

  const [data, error, isLoading] = useAxiosGet(url, [property]);

  let occupancy = [];

  if (data && data.response) {
    const items = Object.values(data.response);
    if (items && items.length) {
      occupancy = items[0].tenants;
    }
  }

  // life-cycle hooks
  useEffect(() => {
    ExportTable('tenants_table', 'tenants');
  }, [isLoading]);

  function saveLocalStorage(tenant) {
    const obj = {
      id: property.id,
      name: property.name,
    };
    if (typeof Storage !== 'undefined') {
      window.localStorage.setItem('property', JSON.stringify(obj));
      window.localStorage.setItem('source', 'tenants');
      window.localStorage.setItem('tenant', JSON.stringify(tenant));
    }
    history.push({
      pathname: '/tenantprofile',
      tenant,
      property: obj,
      userToken,
    });
  }

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      {occupancy && occupancy.length ? (
        <Table tableId="tenants_table" marginTop={10}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Unit No.</th>
              <th>Tenant</th>
              <th>ID/Passport No.</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Start Date</th>
            </tr>
          </thead>
          <tbody>
            {occupancy.map((tenant, id) => {
              let index = id;
              const otherDetails = tenant.other_details;
              let unitNumber;
              if (otherDetails) {
                unitNumber = otherDetails.unit_number;
              }
              return (
                <tr key={tenant.id}>
                  <td>{++index}</td>
                  <td>{unitNumber}</td>
                  <td>
                    <ButtonLink
                      caption={`${tenant.first_name} ${tenant.last_name}`}
                      onClick={() => {
                        saveLocalStorage(tenant);
                      }}
                    />
                  </td>
                  <td>{tenant.id_or_passport}</td>
                  <td>{tenant.email}</td>
                  <td>{tenant.phone}</td>
                  <td>{tenant.status}</td>
                  <td>
                    {FormatDate(
                      new Date(+tenant.start_date * 1000).toISOString(),
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="Seems no tenants found" />
      )}
    </>
  );
};

TenantsTable.propTypes = {
  userToken: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  property: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

TenantsTable.defaultProps = {
  userToken: {},
  property: {},
};

export default TenantsTable;

import React from 'react';
import PropTypes from 'prop-types';

const TableButton = ({ caption, onClick, buttonType }) => {
  let btnStyle;
  let iconStyle;
  if (buttonType === 'danger') {
    btnStyle = 'btn btn-danger btn-sm';
    iconStyle = 'fa fa-trash-o';
  } else {
    btnStyle = 'btn btn-info btn-sm';
    iconStyle = 'fa fa-paste';
  }
  return (
    <button
      className={btnStyle}
      style={{ backgroundColor: buttonType === 'danger' ? '' : '#333333' }}
      onClick={onClick}
      type="button"
    >
      <i className={iconStyle} />
      {`  ${caption}`}
    </button>
  );
};

TableButton.propTypes = {
  caption: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonType: PropTypes.string.isRequired,
};

export default TableButton;

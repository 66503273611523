import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavigationLink = ({ caption, navigation, name, isActive, linkRef }) => {
  const onClick = () => {
    if (linkRef) {
      if (linkRef.current) {
        const { current } = linkRef;

        if (current.firstElementChild) {
          const anchorTag = current.firstElementChild;
          if (anchorTag) {
            anchorTag.setAttribute('aria-expanded', true);
          }
        }

        if (current.parentNode) {
          const { parentNode } = current;
          if (parentNode) {
            parentNode.classList.add('in');
          }

          if (parentNode.parentElement) {
            const { parentElement } = parentNode;
            if (parentElement) {
              parentElement.classList.add('active');
            }
          }
        }
      }
    }
  };

  return (
    <li ref={linkRef} title={name} className={isActive ? 'active' : ''}>
      <Link to={navigation} name={name} onClick={onClick}>
        {caption}
      </Link>
    </li>
  );
};

NavigationLink.propTypes = {
  caption: PropTypes.string.isRequired,
  navigation: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  linkRef: PropTypes.objectOf(PropTypes.object),
};

NavigationLink.defaultProps = {
  isActive: false,
  linkRef: null,
};

export default NavigationLink;

import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="error-container">
      <i className="pe-7s-way text-success big-icon" />
      <h1>404</h1>
      <strong>Resource Not Found</strong>
      <p>
        The resource you are looking for has been removed, had its name changed,
        or is temporarily unavailable.
      </p>
      <Link to="/" className="btn btn-xs btn-success">
        Go back to dashboard
      </Link>
    </div>
  );
};

export default NotFound;

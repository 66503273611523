import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  ExportTable,
  FormatDate,
  CapitalizeFirstLetter,
} from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';

const TransTable = ({ isLoading, error, transactions }) => {
  // life-cycle hooks
  useEffect(() => {
    ExportTable('transactions_table', 'transactions');
  }, [transactions, isLoading]);

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      {transactions && transactions.length ? (
        <Table tableId="transactions_table" marginTop={10}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Date</th>
              <th>Name</th>
              <th>MPESA-ID</th>
              <th>Phone No.</th>
              <th>Property</th>
              <th>Hse. Unit </th>
              <th>Particular</th>
              <th>Amount(Kshs)</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, id) => {
              let index = id;
              let propertyName = '';
              let unitNumber = '';
              let tenantName = '';
              let tenantPhone = '';
              let transactionType = '';
              let paymentCode = '-';
              const { amount, createdat } = transaction;
              if (transaction.purpose) {
                transactionType = CapitalizeFirstLetter(transaction.purpose);
              }
              if (transaction.property) {
                const { property } = transaction;
                if (property) {
                  propertyName = property.name;
                }
              }
              if (transaction.tenant) {
                const { tenant } = transaction;
                if (tenant) {
                  tenantName = `${tenant.first_name} ${tenant.last_name}`;
                  tenantPhone = tenant.phone;
                  if (tenant.other_details) {
                    unitNumber = tenant.other_details.unit_number || '';
                  }
                }
              }
              if (transaction.reference_data) {
                const { reference_data: referenceData } = transaction;
                if (referenceData) {
                  paymentCode = referenceData.payment_code;
                }
              }
              return (
                <tr key={transaction.id}>
                  <td>{++index}</td>
                  <td>{FormatDate(createdat)}</td>
                  <td>{tenantName}</td>
                  <td>{paymentCode || '-'}</td>
                  <td>{tenantPhone}</td>
                  <td>{propertyName}</td>
                  <td>{unitNumber}</td>
                  <td>{transactionType}</td>
                  <td>{amount}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="Seems no transaction found" />
      )}
    </>
  );
};

TransTable.propTypes = {
  transactions: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

TransTable.defaultProps = {
  error: null,
};

export default TransTable;

/*eslint-disable*/

import React from 'react';
import { Route } from 'react-router-dom';

export const Unguarded = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <React.Fragment>
        <Component {...props} />
      </React.Fragment>
    )}
  />
);

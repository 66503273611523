import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';

import UpdateModal from '../Modals/UpdateModal';
import IziToast from '../IziToast';
import TextInput from '../TextInput';

const UpdateUtility = ({
  requestConfig,
  reLoadTable,
  selectedUtility,
  user,
}) => {
  // state
  const [utilityName, setUtilityName] = useState('');
  const [utilityAmount, setUtilityAmount] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // copying state from props is anti-pattern but in our case we're not relying on props update
  useEffect(() => {
    if (selectedUtility) {
      setUtilityName(selectedUtility.name);
      setUtilityAmount(selectedUtility.amount);
    }
  }, [selectedUtility]);

  const updateUtility = () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (utilityName && utilityAmount && Number.isNaN(utilityAmount) === false) {
      isValid = true;
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (isValid) {
      const putObject = {
        id: selectedUtility.id,
        name: utilityName,
        amount: Number(utilityAmount),
        updated_by: user.id,
      };
      IziToast('Updating...');
      axios
        .put(
          `${process.env.REACT_APP_API_URL}utilities`,
          putObject,
          requestConfig,
        )
        .then(() => {
          // api doesn't give us status code or useful messages
          // if (response.data.code === 200) {
          IziToast('Utility successfully updated');
          $('#utilityModal').modal('hide');
          // }
          // setIsLoading(!isLoading);
          if (reLoadTable) reLoadTable();
        })
        .catch((error) => {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                IziToast(error.response.data.error);
              }
            }
          } else {
            IziToast('An error occurred');
          }
        });
    }
  };

  return (
    <UpdateModal
      modalId="utilityModal"
      onClick={updateUtility}
      header="Update Utility"
    >
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter utility name"
          caption="Utility Name"
          onChange={(event) => {
            setUtilityName(event.target.value);
          }}
          value={utilityName}
          columnWidth={8}
          className={isSubmitting && !utilityName ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="Enter utility amount"
          caption="Utility Amount"
          onChange={(e) => {
            const value = /^[0-9\b]+$/;
            // allows number only
            if (e.target.value === '' || value.test(e.target.value)) {
              setUtilityAmount(e.target.value);
            }
          }}
          value={utilityAmount}
          columnWidth={4}
          className={isSubmitting && !utilityAmount ? 'error' : ''}
        />
      </div>
    </UpdateModal>
  );
};

UpdateUtility.propTypes = {
  requestConfig: PropTypes.objectOf(PropTypes.object),
  selectedUtility: PropTypes.oneOfType([PropTypes.object]),
  reLoadTable: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

UpdateUtility.defaultProps = {
  requestConfig: {},
  selectedUtility: {},
  user: {},
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(UpdateUtility);

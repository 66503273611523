import React from 'react';
import PropTypes from 'prop-types';

const InfoPanel = ({ message, hasError }) => {
  const panelClass = hasError ? 'hpanel hred contact-panel' : 'hpanel';
  return (
    <div
      className="col-lg-12"
      style={{ textAlign: 'center', marginTop: '20px' }}
    >
      <div className={panelClass}>
        <div className="panel-body">
          <h3>{message}</h3>
        </div>
      </div>
    </div>
  );
};

InfoPanel.propTypes = {
  message: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
};

InfoPanel.defaultProps = {
  hasError: false,
};

export default InfoPanel;

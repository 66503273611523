// react
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// npm styles
import '../node_modules/izitoast/dist/css/iziToast.css';
import 'react-datepicker/dist/react-datepicker.css';

// Vendor styles
import './vendor/fontawesome/css/font-awesome.css';
import './vendor/metisMenu/metisMenu.css';
import './vendor/animate/animate.css';
import './vendor/bootstrap/dist/css/bootstrap.css';
import './vendor/datatables.net-bs/css/dataTables.bootstrap.min.css';
import './vendor/select2/select2.css';
import './vendor/select2-bootstrap/select2-bootstrap.css';

// App styles
import './fonts/pe-icon-7-stroke/css/pe-icon-7-stroke.css';
import './fonts/pe-icon-7-stroke/css/helper.css';

// css
import './styles/index.css';

// Vendor scripts
import './vendor/slimScroll/slimscroll';
import './vendor/metisMenu/metisMenu';
import './vendor/bootstrap/dist/js/bootstrap';
import './vendor/jqueryui/jqueryui';
import './vendor/datatables/media/js/jquery.dataTables.min';
import './vendor/datatables.net-bs/js/dataTables.bootstrap.min';
import './vendor/pdfmake/pdfmake';
import './vendor/pdfmake/vfs_fonts';
import './vendor/datatables.net-buttons/js/buttons.html5.min';
import './vendor/datatables.net-buttons/js/buttons.print.min';
import './vendor/datatables.net-buttons/js/dataTables.buttons.min';
import './vendor/datatables.net-buttons-bs/js/buttons.bootstrap.min';
import './vendor/select2/select2';

// app
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './helpers/store';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  window.document.querySelector('#root'),
);

serviceWorker.unregister();

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import axios from 'axios';
import InfoPanel from '../Panels/InfoPanel';
import './style.css';
import history from '../../helpers/history';

const Charges = ({ user, properties }) => {
  if (!user || !user.token) {
    history.push('/signin');
  }
  // token
  const userToken = user ? user.token : {};

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = React.useRef(null);

  useEffect(() => {
    // executed when component mounted
    isMounted.current = true;
    return () => {
      // executed when unmount
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (properties.length) {
          let url;
          if (user.type === 'admin') {
            url = `${process.env.REACT_APP_API_URL}paymentcodes?take=1000&order={"name":"ASC"}`;
          } else {
            const propertyIds = properties.map((item) => {
              return item.id;
            });
            if (propertyIds.length > 0) {
              url = `${
                process.env.REACT_APP_API_URL
              }paymentcodes?take=1000&where={"property_id":"in::${propertyIds.join()}"}&order={"name":"ASC"}`;
            } else {
              url = undefined;
            }
          }
          const {
            data: { response },
          } = await axios.get(url, requestConfig);
          if (isMounted.current) {
            setPayments(response);
            setIsLoading(false);
          }
        }
      } catch (error) {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
      return () => {};
    })();
  }, []);

  let component = null;
  if (isLoading) {
    component = <InfoPanel message="Loading..." />;
  } else if (payments.length) {
    component = (
      <table className="charges">
        <thead>
          <tr>
            <th>Charge</th>
            <th>Ac/No</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((receipt) => {
            return (
              <tr key={receipt.id}>
                <td>{receipt.name}</td>
                <td>{receipt.code}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  } else {
    component = <InfoPanel message="no charges found" />;
  }

  return (
    <div className="col-lg-3">
      <div className="hpanel">
        <div className="panel-heading">Extra Charges Table</div>
        <div className="panel-body list">
          <div className="table-responsive project-list">{component}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

Charges.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps)(Charges);

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import IziToast from '../IziToast';
import Master from '../Master';
import TextInput from '../TextInput';
import MonthDays from '../../data/MonthDays';
import YesNo from '../../data/YesNo';
import Select from '../Select';
import Button from '../Buttons';
import FilePreview from '../FileUploader/FilePreview';
import Paragraph from '../Paragraph';

const NewUnit = ({ user, location: { property } }) => {
  // get details from local storage
  if (typeof Storage !== 'undefined') {
    if (!property) {
      const propStr = window.localStorage.getItem('property');
      // eslint-disable-next-line no-param-reassign
      if (propStr) property = JSON.parse(propStr);
    }
  }
  const history = useHistory();
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };

  // refs
  const propertyRef = useRef(null);
  // state

  const [unitsNumber, setUnitsNumber] = useState('');
  const [propertyId, setPropertyId] = useState(property ? property.id : '');
  const [description, setDescription] = useState('');
  const [unitType, setUnitType] = useState('');
  const [unitRent, setUnitRent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentFile, setCurrentFile] = useState(null);
  const [bathrooms, setBathrooms] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [parking, setParking] = useState('');
  const [rentDeposit, setRentDeposit] = useState('');
  const [rentDueDate, setRentDueDate] = useState('');
  const [rentDeadline, setRentDeadline] = useState('');
  const [properties, setProperties] = useState([]);
  const [propertyName, setPropertyName] = useState(
    property ? property.name : '',
  );
  useEffect(() => {
    let url = `/byuser?status=active&user_id=${user.id}`;
    if (user && user.type === 'admin') {
      // eslint-disable-next-line quotes
      url = `?take=1000&where={"status":"active"}`;
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}properties${url}&order={"name": "ASC"}`,
        requestConfig,
      )
      .then((res) => {
        setProperties(res.data.response);
      })
      .catch(() => {
        IziToast('Could not load your properties');
      });
  }, []);

  const saveUnit = () => {
    setIsSubmitting(true);

    let isValid = false;
    let message;
    if (
      propertyId &&
      bathrooms &&
      bedrooms &&
      description &&
      unitRent &&
      unitType &&
      rentDeposit &&
      unitsNumber &&
      rentDueDate &&
      rentDeadline &&
      parking
    ) {
      isValid = true;
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (isValid) {
      const otherDetails = {
        rent_due: rentDueDate,
        deposit: rentDeposit,
        rent_deadline: rentDeadline,
        number: unitsNumber,
      };

      const formData = new FormData();
      formData.append('user_id', user.id);
      formData.append('property_id', propertyId);
      formData.append('type', unitType);
      formData.append('description', description);
      formData.append('bedrooms', bedrooms);
      formData.append('bathrooms', bathrooms);
      formData.append('parking', parking);
      formData.append('rent', unitRent);
      formData.append('other_details', JSON.stringify(otherDetails));
      // attach image if any
      if (currentFile) {
        formData.append(currentFile.name, currentFile, currentFile.name);
      }

      const formConfig = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      IziToast('Saving...');
      axios
        .post(`${process.env.REACT_APP_API_URL}units`, formData, formConfig)
        .then((response) => {
          if (response.data.response.id) {
            IziToast('Unit successfully saved');
            resetFields();
          }
          setIsLoading(!isLoading);
        })
        .catch((error) => {
          if (error) {
            if (error.response) {
              if (error.response.data) {
                IziToast(error.response.data.error);
              }
            } else {
              IziToast(error.message);
            }
          } else {
            IziToast('An error occurred');
          }
        });
    }
  };

  const resetFields = () => {
    setUnitsNumber('');
    setPropertyId('');
    setDescription('');
    setBathrooms('');
    setBedrooms('');
    setUnitType('');
    setUnitRent('');
    setRentDeposit('');
    setIsSubmitting(false);
    setCurrentFile(null);
    setRentDueDate('');
    setRentDeadline('');
    setParking('');
    setPropertyName('');
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const { type /* name */ /* size */ } = file;
      const validTypes = ['image/jpeg', 'image/png'];
      if (validTypes.includes(type)) {
        /**
         * TODO : validate duplicate upload
         * message : 'Looks like you have already uploaded this image'
         */
        setCurrentFile(file);
      } else {
        IziToast('Only .jpeg and .png files allowed');
      }
    }
  };

  return (
    <Master
      title="Property Management"
      redirectLink="newunit"
      source="Add New Unit"
    >
      <div className="col-xs-3 bm-responsive-div">
        <FilePreview
          currentFile={currentFile}
          onChange={onFileChange}
          inputId="imgUnit"
          onFileRemove={() => {
            setCurrentFile(null);
          }}
          columnWidth={12}
        />
        {propertyName && (
          <Paragraph
            columnWidth={12}
            content={propertyName || ''}
            caption="Property Name"
          />
        )}
      </div>
      <div className="col-xs-9 bm-responsive-div">
        <div className="row">
          <Select
            caption="Select Property"
            value={propertyId}
            onChange={(event) => {
              setPropertyId(event.target.value);
              setPropertyName(
                event.target.options[event.target.selectedIndex].text,
              );
            }}
            columnWidth={8}
            className={isSubmitting && !propertyId ? 'error' : ''}
            inputRef={propertyRef}
          >
            <option value="">Select property name</option>
            {properties.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Select>
          <TextInput
            type="text"
            placeholder="Enter number"
            caption="Bathrooms"
            onChange={(e) => {
              const value = /^[0-9\b]+$/;
              // allows number only
              if (e.target.value === '' || value.test(e.target.value)) {
                setBathrooms(e.target.value);
              }
            }}
            value={bathrooms}
            columnWidth={2}
            className={isSubmitting && !bathrooms ? 'error' : ''}
          />
          <TextInput
            type="text"
            placeholder="Enter number"
            caption="Bedrooms"
            onChange={(e) => {
              const value = /^[0-9\b]+$/;
              // allows number only
              if (e.target.value === '' || value.test(e.target.value)) {
                setBedrooms(e.target.value);
              }
            }}
            value={bedrooms}
            columnWidth={2}
            className={isSubmitting && !bedrooms ? 'error' : ''}
          />
        </div>
        <div className="row">
          <TextInput
            type="text"
            placeholder="Enter unit description"
            caption="Unit Description"
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            value={description}
            columnWidth={12}
            className={isSubmitting && !description ? 'error' : ''}
          />
        </div>
        <div className="row">
          <TextInput
            type="text"
            placeholder="Enter unit type"
            caption="Unit Type"
            onChange={(event) => {
              setUnitType(event.target.value);
            }}
            value={unitType}
            columnWidth={6}
            className={isSubmitting && !unitType ? 'error' : ''}
          />

          <TextInput
            type="text"
            placeholder="Enter rent"
            caption="Rent Per Month"
            onChange={(e) => {
              const value = /^[0-9\b]+$/;
              // allows number only
              if (e.target.value === '' || value.test(e.target.value)) {
                setUnitRent(e.target.value);
              }
            }}
            value={unitRent}
            columnWidth={3}
            className={isSubmitting && !unitRent ? 'error' : ''}
          />
          <TextInput
            type="text"
            placeholder="Enter amount"
            caption="Rent Deposit"
            onChange={(e) => {
              const value = /^[0-9\b]+$/;
              // allows number only
              if (e.target.value === '' || value.test(e.target.value)) {
                setRentDeposit(e.target.value);
              }
            }}
            value={rentDeposit}
            columnWidth={3}
            className={isSubmitting && !rentDeposit ? 'error' : ''}
          />
        </div>
        <div className="row">
          <Select
            caption="Rent Due Date"
            value={rentDueDate}
            onChange={(event) => {
              setRentDueDate(event.target.value);
            }}
            columnWidth={2}
            className={isSubmitting && !rentDueDate ? 'error' : ''}
          >
            <option value="">Select</option>
            {MonthDays.map((item) => {
              return (
                <option value={item.id} key={item}>
                  {item}
                </option>
              );
            })}
          </Select>
          <Select
            caption="Rent Deadline"
            value={rentDeadline}
            onChange={(event) => {
              setRentDeadline(event.target.value);
            }}
            columnWidth={2}
            className={isSubmitting && !rentDeadline ? 'error' : ''}
          >
            <option value="">Select</option>
            {MonthDays.map((item) => {
              return (
                <option value={item.id} key={item}>
                  {item}
                </option>
              );
            })}
          </Select>
          <TextInput
            type="text"
            placeholder="Enter number"
            caption="Number Of Units"
            onChange={(e) => {
              const value = /^[0-9\b]+$/;
              // allows number only
              if (e.target.value === '' || value.test(e.target.value)) {
                setUnitsNumber(e.target.value);
              }
            }}
            value={unitsNumber}
            columnWidth={2}
            className={isSubmitting && !unitsNumber ? 'error' : ''}
          />
          <Select
            caption="Parking Available ?"
            value={parking}
            onChange={(event) => {
              setParking(event.target.value);
            }}
            columnWidth={3}
            className={isSubmitting && !parking ? 'error' : ''}
          >
            <option value="">Select</option>
            {YesNo.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
      <div className="right">
        <Button
          caption="<< Back To Unit"
          cssClass="btn-success"
          onClick={() => {
            history.push({
              pathname: 'units',
              property,
            });
            if (typeof Storage !== 'undefined') {
              window.localStorage.removeItem('property');
            }
          }}
          type="button"
          isBlack
        />
        <Button
          caption="Create New Unit"
          cssClass="btn-success"
          onClick={saveUnit}
          type="button"
          isBlack={false}
        />
      </div>
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

NewUnit.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

NewUnit.defaultProps = {
  user: {},
  location: {},
};

export default connect(mapStateToProps)(NewUnit);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';

import UpdateModal from '../Modals/UpdateModal';
import IziToast from '../IziToast';
import TextInput from '../TextInput';
import CheckBox from '../CheckBox';

const UpdateCharges = ({
  requestConfig,
  reLoadTable,
  selectedCharge,
  user,
}) => {
  // state
  const [chargeName, setChargeInfo] = useState('');
  const [chargeAmount, setChargeAmount] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [description, setDescription] = useState('');
  const [paymentCode, setPaymentCode] = useState('');
  const [sendEmail, setSendEmail] = useState(false);
  const [sendSms, setSendSms] = useState(false);

  // copying state from props is anti-pattern but in our case we're not relying on props update
  useEffect(() => {
    if (selectedCharge) {
      const { reference_data: referenceData } = selectedCharge;
      if (referenceData) {
        setChargeInfo(referenceData.info);
        setDescription(referenceData.description || '');
        setPaymentCode(referenceData.payment_code);
      }

      setChargeAmount(selectedCharge.total_amount);
    }
  }, [selectedCharge]);

  const updateCharges = async () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (chargeName && chargeAmount) {
      isValid = true;
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (parseFloat(chargeAmount) <= 0) {
      message = 'Enter valid charge code';
      IziToast(message);
      isValid = false;
    } else {
      isValid = true;
    }
    if (isValid) {
      const changeLog = [
        {
          user: {
            id: user.id,
            type: user.type,
          },
          action: 'Updating invoice',
          timestamp: Math.round(new Date().getTime() / 1000.0),
        },
      ];
      const referenceData = {
        info: chargeName || '',
        deduct: false,
        description,
        payment_code: paymentCode,
      };
      const amount = parseFloat(chargeAmount);
      const chargesObject = {
        id: selectedCharge.id,
        invoice_type: 'charges',
        total_amount: amount,
        amount_due: amount,
        balance: amount,
        reference_data: referenceData,
        changelog: changeLog,
        send_email: sendEmail,
        send_sms: sendSms,
      };
      IziToast('Updating...');
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}invoices`,
          chargesObject,
          requestConfig,
        );
        // api doesn't give us status code or useful messages
        // if (response.data.code === 200) {
        IziToast('Charge successfully updated');
        $('#chargesModal').modal('hide');
        // }
        // setIsLoading(!isLoading);
        if (reLoadTable) reLoadTable();
      } catch (error) {
        if (error) {
          if (error.response) {
            if (error.response.data) {
              IziToast(error.response.data.error);
            }
          }
        } else {
          IziToast('An error occurred');
        }
      }
    }
  };

  return (
    <UpdateModal
      modalId="chargesModal"
      onClick={updateCharges}
      header="Update extra charges"
    >
      <div className="row">
        <TextInput
          type="text"
          placeholder="Info"
          caption="Info"
          onChange={() => {
            // setChargeInfo(event.target.value);
          }}
          value={chargeName}
          columnWidth={10}
          className={isSubmitting && !chargeName ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="Enter amount"
          caption="Charge Amount"
          onChange={(e) => {
            const value = /^[0-9\b]+$/;
            // allows number only
            if (e.target.value === '' || value.test(e.target.value)) {
              setChargeAmount(e.target.value);
            }
          }}
          value={chargeAmount}
          columnWidth={2}
          className={
            (isSubmitting && !chargeAmount) || parseFloat(chargeAmount) <= 0
              ? 'error'
              : ''
          }
        />
      </div>
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter description (optional)"
          caption="Description (optional)"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          value={description}
          columnWidth={12}
          // className={isSubmitting && !description ? 'error' : ''}
        />
      </div>
      <div className="row">
        <div className="col-md-6">
          <CheckBox
            caption="Send Email"
            checked={sendEmail}
            onClick={() => {
              setSendEmail(!sendEmail);
            }}
          />
          <CheckBox
            caption="Send SMS"
            checked={sendSms}
            onClick={() => {
              setSendSms(!sendSms);
            }}
          />
        </div>
      </div>
    </UpdateModal>
  );
};

UpdateCharges.propTypes = {
  requestConfig: PropTypes.objectOf(PropTypes.object),
  selectedCharge: PropTypes.oneOfType([PropTypes.object]),
  reLoadTable: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

UpdateCharges.defaultProps = {
  requestConfig: {},
  selectedCharge: {},
  user: {},
};

export default connect((state) => {
  return state;
})(UpdateCharges);

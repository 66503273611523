import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';

import { connect } from 'react-redux';

import MonthDays from '../../data/MonthDays';
import UpdateModal from '../Modals/UpdateModal';
import IziToast from '../IziToast';
import TextInput from '../TextInput';
import Select from '../Select';
import FilePreview from '../FileUploader/FilePreview';
import YesNo from '../../data/YesNo';

const UpdateUnit = ({ user, reLoadTable, selectedUnit }) => {
  // get details from local storage
  if (typeof Storage !== 'undefined') {
    if (!user) {
      const userStr = window.localStorage.getItem('nyumbani');
      if (userStr) {
        // eslint-disable-next-line no-param-reassign
        user = JSON.parse(userStr);
      }
    }
  }
  const userToken = user.token;
  // state
  const [unitsNumber, setUnitsNumber] = useState('');
  const [description, setDescription] = useState('');
  const [unitType, setUnitType] = useState('');
  const [unitRent, setUnitRent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentFile, setCurrentFile] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [parking, setParking] = useState('');
  const [rentDeposit, setRentDeposit] = useState('');
  const [rentDueDate, setRentDueDate] = useState('');
  const [rentDeadline, setRentDeadline] = useState('');

  // copying state from props is anti-pattern but in our case we're not relying on props update
  useEffect(() => {
    if (selectedUnit) {
      let currentDeposit = '';
      let currentRentDue = '';
      let currentRentDeadline = '';
      let currentUnits = '';
      if (selectedUnit.other_details) {
        const otherDetails = selectedUnit.other_details;
        currentDeposit = otherDetails.deposit;
        currentRentDue = otherDetails.rent_due;
        currentRentDeadline = otherDetails.rent_deadline;
        currentUnits = otherDetails.number;
        if (currentRentDue.length < 1) {
          currentRentDue = `0${currentRentDue}`;
        }
        if (currentRentDeadline < 1) {
          currentRentDeadline = `0${currentRentDeadline}`;
        }
      }
      setUnitType(selectedUnit.type || '');
      setDescription(selectedUnit.description || '');
      setBathrooms(selectedUnit.bathrooms || '');
      setBedrooms(selectedUnit.bedrooms || '');
      setParking(selectedUnit.parking || '');
      setRentDeposit(currentDeposit || '');
      setRentDueDate(currentRentDue || '');
      setRentDeadline(currentRentDeadline || '');
      setUnitsNumber(currentUnits || '');
      setUnitRent(selectedUnit.rent || '');
      if (selectedUnit.images) {
        const { images } = selectedUnit;
        if (images && images.length) {
          setCurrentFile(images[0].link);
        }
      }
    }
  }, [selectedUnit]);

  const updateUnit = async () => {
    setIsSubmitting(true);

    let isValid = false;
    let message;
    if (
      bathrooms &&
      bedrooms &&
      description &&
      unitRent &&
      unitType &&
      rentDeposit &&
      unitsNumber &&
      rentDueDate &&
      rentDeadline &&
      parking
    ) {
      isValid = true;
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (isValid) {
      const otherDetails = {
        rent_due: rentDueDate,
        deposit: rentDeposit,
        rent_deadline: rentDeadline,
        number: unitsNumber,
      };
      const formData = new FormData();
      formData.append('id', selectedUnit.id);
      formData.append('type', unitType);
      formData.append('description', description);
      formData.append('bedrooms', bedrooms);
      formData.append('bathrooms', bathrooms);
      formData.append('parking', parking);
      formData.append('rent', unitRent);
      formData.append('other_details', JSON.stringify(otherDetails));
      // attach image if any
      if (currentFile && typeof currentFile === 'object') {
        formData.append(currentFile.name, currentFile, currentFile.name);
      }

      const formConfig = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      IziToast('Updating...');
      try {
        const {
          data: { response },
        } = await axios.put(
          `${process.env.REACT_APP_API_URL}units`,
          formData,
          formConfig,
        );
        if (response.id) {
          IziToast('Unit successfully updated');
        }
        setIsLoading(!isLoading);
        if (reLoadTable) reLoadTable();
        $('#unitsModal').modal('hide');
      } catch (error) {
        if (error) {
          IziToast(error.message);
        } else {
          IziToast('An error occurred');
        }
      }
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const { type /* name */ /* size */ } = file;
      const validTypes = ['image/jpeg', 'image/png'];
      if (validTypes.includes(type)) {
        /**
         * TODO : validate duplicate upload
         * message : 'Looks like you have already uploaded this image'
         */
        setCurrentFile(file);
      } else {
        IziToast('Only .jpeg and .png files allowed');
      }
    }
  };

  return (
    <UpdateModal
      modalId="unitsModal"
      onClick={updateUnit}
      header="Update unit details"
    >
      <div className="row">
        <div className="col-xs-4 bm-responsive-div">
          <FilePreview
            currentFile={currentFile}
            onChange={onFileChange}
            inputId="imgUnit"
            onFileRemove={() => {
              setCurrentFile('');
            }}
            columnWidth={12}
          />
        </div>
        <div className="col-xs-8 bm-responsive-div">
          <div className="row">
            <TextInput
              type="text"
              placeholder="Enter unit type"
              caption="Unit Type"
              onChange={(event) => {
                setUnitType(event.target.value);
              }}
              value={unitType}
              columnWidth={6}
              className={isSubmitting && !unitType ? 'error' : ''}
            />
            <TextInput
              type="text"
              placeholder="Enter number"
              caption="Bathrooms"
              onChange={(e) => {
                const value = /^[0-9\b]+$/;
                // allows number only
                if (e.target.value === '' || value.test(e.target.value)) {
                  setBathrooms(e.target.value);
                }
              }}
              value={bathrooms}
              columnWidth={3}
              className={isSubmitting && !bathrooms ? 'error' : ''}
            />
            <TextInput
              type="text"
              placeholder="Enter number"
              caption="Bedrooms"
              onChange={(e) => {
                const value = /^[0-9\b]+$/;
                // allows number only
                if (e.target.value === '' || value.test(e.target.value)) {
                  setBedrooms(e.target.value);
                }
              }}
              value={bedrooms}
              columnWidth={3}
              className={isSubmitting && !bedrooms ? 'error' : ''}
            />
          </div>
          <div className="row">
            <TextInput
              type="text"
              placeholder="Enter unit description"
              caption="Unit Description"
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              value={description}
              columnWidth={12}
              className={isSubmitting && !description ? 'error' : ''}
            />
          </div>
          <div className="row">
            <TextInput
              type="text"
              placeholder="Enter rent"
              caption="Rent Per Month"
              onChange={(e) => {
                const value = /^[0-9\b]+$/;
                // allows number only
                if (e.target.value === '' || value.test(e.target.value)) {
                  setUnitRent(e.target.value);
                }
              }}
              value={unitRent}
              columnWidth={4}
              className={isSubmitting && !unitRent ? 'error' : ''}
            />
            <TextInput
              type="text"
              placeholder="Enter amount"
              caption="Rent Deposit"
              onChange={(e) => {
                const value = /^[0-9\b]+$/;
                // allows number only
                if (e.target.value === '' || value.test(e.target.value)) {
                  setRentDeposit(e.target.value);
                }
              }}
              value={rentDeposit}
              columnWidth={4}
              className={isSubmitting && !rentDeposit ? 'error' : ''}
            />
            <Select
              caption="Rent Due Date"
              value={rentDueDate || ''}
              onChange={(event) => {
                setRentDueDate(event.target.value);
              }}
              columnWidth={4}
              className={isSubmitting && !rentDueDate ? 'error' : ''}
            >
              <option value="">Select</option>
              {MonthDays.map((item) => {
                return (
                  <option value={item.id} key={item}>
                    {item}
                  </option>
                );
              })}
            </Select>
          </div>
          <div className="row">
            <Select
              caption="Rent Deadline"
              value={rentDeadline || ''}
              onChange={(event) => {
                setRentDeadline(event.target.value);
              }}
              columnWidth={4}
              className={isSubmitting && !rentDeadline ? 'error' : ''}
            >
              <option value="">Select</option>
              {MonthDays.map((item) => {
                return (
                  <option value={item.id} key={item}>
                    {item}
                  </option>
                );
              })}
            </Select>
            <TextInput
              type="text"
              placeholder="Enter number"
              caption="Number Of Units"
              onChange={(e) => {
                const value = /^[0-9\b]+$/;
                // allows number only
                if (e.target.value === '' || value.test(e.target.value)) {
                  setUnitsNumber(e.target.value);
                }
              }}
              value={unitsNumber || ''}
              columnWidth={4}
              className={isSubmitting && !unitsNumber ? 'error' : ''}
            />
            <Select
              caption="Parking Available ?"
              value={parking || ''}
              onChange={(event) => {
                setParking(event.target.value);
              }}
              columnWidth={4}
              className={isSubmitting && !parking ? 'error' : ''}
            >
              <option value="">Select</option>
              {YesNo.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
    </UpdateModal>
  );
};

UpdateUnit.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  selectedUnit: PropTypes.oneOfType([PropTypes.object]),
  reLoadTable: PropTypes.func.isRequired,
};

UpdateUnit.defaultProps = {
  selectedUnit: {},
};

export default connect((state) => {
  return state;
})(UpdateUnit);

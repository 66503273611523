import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';

import UpdateModal from '../Modals/UpdateModal';
import IziToast from '../IziToast';
import TextInput from '../TextInput';
import UserTypes from '../../data/UserTypes';
import Select from '../Select';
import { ValidateEmail } from '../../utils/Functions';

const UpdateUser = ({ requestConfig, reLoadUsers, selectedUser }) => {
  // state
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [userType, setUserType] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);

  // copying state from props is anti-pattern but in our case we're not relying on props update
  useEffect(() => {
    if (selectedUser) {
      setFirstName(selectedUser.first_name);
      setLastName(selectedUser.last_name);
      setEmail(selectedUser.email);
      setUserType(selectedUser.type);
      const phoneNumber = String(selectedUser.phone);
      if (phoneNumber) {
        if (phoneNumber.startsWith('+')) {
          setPhone(phoneNumber.substring(1));
        } else {
          setPhone(phoneNumber);
        }
      }
    }
  }, [selectedUser]);
  // refs
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const passwordRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const userTypeRef = useRef(null);

  const updateUser = async () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (firstName && lastName && phone && email && userType) {
      if (!ValidateEmail(email)) {
        isValid = false;
        message = 'Enter a valid email address';
        IziToast(message);
        emailRef.current.focus();
      } else {
        isValid = true;
      }
      if (phone.length !== 12) {
        message = 'Enter a valid phone number';
        IziToast(message);
        phoneRef.current.focus();
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      isValid = false;
      message = 'Enter the red marked fields';
      IziToast(message);
    }
    if (isValid) {
      let userObject;
      if (password) {
        userObject = {
          type: userType,
          first_name: firstName,
          last_name: lastName,
          email,
          phone: `+${phone}`,
          password,
          id: selectedUser.id,
        };
      } else {
        userObject = {
          type: userType,
          first_name: firstName,
          last_name: lastName,
          email,
          phone: `+${phone}`,
          id: selectedUser.id,
        };
      }
      IziToast('Updating...');
      try {
        const {
          // eslint-disable-next-line no-unused-vars
          data: { response },
        } = await axios.put(
          `${process.env.REACT_APP_API_URL}user`,
          userObject,
          requestConfig,
        );
        IziToast('User successfully updated');
        $('#userModal').modal('hide');
        if (reLoadUsers) reLoadUsers();
      } catch (error) {
        if (error) {
          if (error.response) {
            if (error.response.data) {
              IziToast(error.response.data.error);
            }
          }
        } else {
          IziToast('An error occurred');
        }
      }
    }
  };

  return (
    <UpdateModal
      modalId="userModal"
      onClick={updateUser}
      header="Update user profile"
    >
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter first name"
          caption="First Name"
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
          value={firstName}
          columnWidth={4}
          inputRef={firstNameRef}
          className={isSubmitting && !firstName ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="Enter last name"
          caption="Last Name"
          onChange={(event) => {
            setLastName(event.target.value);
          }}
          value={lastName}
          columnWidth={4}
          inputRef={lastNameRef}
          className={isSubmitting && !lastName ? 'error' : ''}
        />
        <TextInput
          type="text"
          placeholder="254712345678"
          caption="Telephone Number (254712345678)"
          pattern="[0-9]*"
          onChange={(e) => {
            const numbers = /^[0-9\b]+$/;
            const { value } = e.target;
            // allows number only
            if (value === '' || numbers.test(value)) {
              setPhone(value);
            }
          }}
          value={phone}
          columnWidth={4}
          inputRef={phoneRef}
          className={isSubmitting && phone.length !== 12 ? 'error' : ''}
        />
      </div>
      <div className="row">
        <TextInput
          type="text"
          placeholder="Enter email"
          caption="Email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          value={email}
          columnWidth={4}
          inputRef={emailRef}
          className={
            isSubmitting && (!email || !ValidateEmail(email)) ? 'error' : ''
          }
        />
        <Select
          caption="User Type"
          value={userType}
          onChange={(event) => {
            setUserType(event.target.value);
          }}
          columnWidth={4}
          inputRef={userTypeRef}
          className={isSubmitting && !userType ? 'error' : ''}
        >
          <option value="">Select</option>
          {UserTypes.map((item) => {
            return <option key={item.id}>{item.name}</option>;
          })}
        </Select>
        <TextInput
          type="password"
          caption="Enter password"
          placeholder="******"
          required=""
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          columnWidth={4}
          inputRef={passwordRef}
        />
      </div>
    </UpdateModal>
  );
};

UpdateUser.propTypes = {
  requestConfig: PropTypes.objectOf(PropTypes.object),
  selectedUser: PropTypes.oneOfType([PropTypes.object]),
  reLoadUsers: PropTypes.func.isRequired,
};

UpdateUser.defaultProps = {
  requestConfig: {},
  selectedUser: {},
};

export default UpdateUser;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Picker from 'react-datepicker';

import './style.css';

const DatePicker = ({
  name,
  onChange,
  placeholder: placeholderText,
  caption,
  columnWidth,
  isWrapped,
  inputRef,
  className,
  selected,
}) => {
  const colWidth = `col-md-${columnWidth}`;
  const inputClass = className
    ? `date-input form-control ${className}`
    : 'date-input form-control';
  const Input = (
    <div className="input-group date">
      <label className="control-label">{caption}</label>
      <Picker
        placeholderText={placeholderText}
        title={caption}
        onChange={onChange}
        name={name}
        ref={inputRef}
        selected={selected}
        className={inputClass}
        dateFormat="dd-MM-yyyy"
        // calendarClassName={inputClass}
        showPopperArrow
        showMonthDropdown
        todayButton="Today"
        isClearable
        shouldCloseOnSelect
      />
    </div>
  );

  if (isWrapped) {
    return <div className={colWidth}>{Input}</div>;
  }
  return Input;
};

DatePicker.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  caption: PropTypes.string,
  columnWidth: PropTypes.number.isRequired,
  isWrapped: PropTypes.bool,
  inputRef: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  selected: PropTypes.objectOf(PropTypes.object),
};

DatePicker.defaultProps = {
  placeholder: '',
  caption: '',
  isWrapped: true,
  inputRef: null,
  className: '',
  name: '',
  selected: new Date(),
};

export default DatePicker;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import $ from 'jquery';
import Master from '../Master';
import Collapsible from '../Panels/Collapsible';
import IziToast from '../IziToast';
import Select from '../Select';
import InvoiceTable from './InvoiceTable';
import DatePicker from '../DatePicker';
import Button from '../Buttons';
import AlertMessage from '../Alert';
import AlertActions from '../../actions/alert';
import { FormatDate, DestroyTable } from '../../utils/Functions';
import PaymentStatus from '../../data/PaymentStatus';

const Invoices = ({ user, dispatch, alert }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };

  //   refs
  const propertyRef = useRef(null);
  const tenantRef = useRef(null);

  // state
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [lowerDate, setLowerDate] = useState(firstDay);
  const [upperDate, setUpperDate] = useState(lastDay);
  const [propertyId, setPropertyId] = useState('');
  const [properties, setProperties] = useState([]);
  const [tenantId, setTenantId] = useState('');
  const [tenants, setTenants] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [units, setUnits] = useState([]);
  const [tenantsList, setTenantsList] = useState([]);
  const [receiptList, setTransactionList] = useState([]);

  useEffect(() => {
    dispatch(AlertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        let url = `/byuser?status=active&user_id=${user.id}`;
        if (user && user.type === 'admin') {
          url = '?take=1000&where={"status":"active"}&order={"name":"ASC"}';
        }
        const {
          data: { response },
        } = await axios.get(
          `${process.env.REACT_APP_API_URL}properties${url}`,
          requestConfig,
        );
        setProperties(response);
        loadUnits(response);
        $(propertyRef.current)
          .select2()
          .on('change', (event) => {
            setPropertyId(event.val);
          });
      } catch (_) {
        IziToast('Could not load your properties');
      }
    })();
  }, []);

  useEffect(() => {
    filterByProperty();
    setTenantId('');
  }, [propertyId]);

  useEffect(() => {
    if (tenantId) {
      DestroyTable('invoices_table');
      const transaction = receiptList.filter((item) => {
        if (item && item.tenant) {
          return item.tenant.id === tenantId;
        }
        return undefined;
      });
      setInvoices(transaction);
    } else if (propertyId) {
      filterByProperty();
    } else {
      setInvoices(receiptList);
    }
  }, [tenantId]);

  useEffect(() => {
    if (invoices.length > 0) {
      const sum = invoices.reduce((acc, curr) => {
        return parseFloat(acc) + parseFloat(curr.total_amount);
      }, 0);
      setTotal(sum);
    } else {
      setTotal(0);
    }
  }, [invoices]);

  function filterByProperty() {
    if (propertyId) {
      DestroyTable('invoices_table');
      const filtered = tenantsList.filter((item) => {
        return item.unit.property_id === propertyId;
      });
      setTenants(filtered);
      const transaction = receiptList.filter((item) => {
        if (item && item.property) {
          return item.property.id === propertyId;
        }
        return undefined;
      });
      setInvoices(transaction);
    } else {
      setTenants(tenantsList);
      setInvoices(receiptList);
    }
  }

  /**
   *
   * @param {[]} property
   */
  async function loadUnits(property) {
    const propertyIds = property.map((item) => {
      return item.id;
    });
    let urlUnits;
    // get units
    if (user.type === 'admin') {
      urlUnits = `${process.env.REACT_APP_API_URL}units?take=10000`;
    } else {
      urlUnits = `${
        process.env.REACT_APP_API_URL
      }units?take=10000&where={"property_id":"in::${propertyIds.join()}"}`;
    }
    try {
      const {
        data: { response: unitsInfo },
      } = await axios.get(urlUnits, requestConfig);
      setUnits(unitsInfo);
      loadTenants(unitsInfo);
    } catch (_) {
      //
    }
  }

  /**
   *
   * @param {[]} unitsInfo
   */
  function loadTenants(unitsInfo) {
    function findUnit(id) {
      return unitsInfo.find((item) => {
        return item.id === id;
      });
    }
    (async () => {
      try {
        const unitIds = unitsInfo.map((item) => {
          return item.id;
        });
        let urlTenants;
        // get tenats
        if (user.type === 'admin') {
          urlTenants = `${process.env.REACT_APP_API_URL}tenants?take=10000`;
        } else {
          urlTenants = `${
            process.env.REACT_APP_API_URL
          }tenants?take=10000&where={"unit_id":"in::${unitIds.join()}"}`;
        }
        const {
          data: { response },
        } = await axios.get(urlTenants, requestConfig);
        const mapped = response.map((item) => {
          const element = item;
          element.unit = findUnit(element.unit_id);
          return element;
        });
        setTenants(mapped);
        setTenantsList(mapped);
        $(tenantRef.current)
          .select2()
          .on('change', (event) => {
            setTenantId(event.val);
          });
      } catch (_) {
        //
      }
    })();
  }

  function findTenant(id) {
    return tenantsList.find((item) => {
      return item.id === id;
    });
  }

  function findProperty(id) {
    return properties.find((property) => {
      return property.id === id;
    });
  }

  async function onSearchInvoice() {
    let filter = '';
    if (tenantId) {
      filter = `"payer_id":"${tenantId}"`;
    }
    if (status) {
      const query = filter ? ',' : '';
      filter = `${filter}${query}"status":"${status}"`;
    }
    if (!lowerDate || !upperDate) {
      // no date filter
      dispatch(AlertActions.error('Select both date filters to continue'));
      return;
    }
    if (lowerDate && upperDate) {
      // filter by date
      const query = filter ? ',' : '';
      if (lowerDate && upperDate) {
        filter = `${filter}${query}"invoice_date":"btwn::${FormatDate(
          lowerDate,
        )},${FormatDate(upperDate)}"`;
      }
    }
    if (!filter) {
      dispatch(AlertActions.error('Select invoice filter to continue'));
      return;
    }
    try {
      setIsLoading(true);
      let urlInvoices;
      if (user.type === 'admin') {
        urlInvoices = `${process.env.REACT_APP_API_URL}invoices?where={${filter}}`;
      } else {
        const propertyIds = properties.map((item) => {
          return item.id;
        });
        filter = `${filter},"payee_id":"in::${propertyIds.join()}"`;
        urlInvoices = `${process.env.REACT_APP_API_URL}invoices?where={${filter}}`;
      }
      const {
        data: { response },
      } = await axios.get(urlInvoices, requestConfig);
      mapInvoices(response);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }

  function mapInvoices(charges) {
    let sum = 0;
    const mapped = charges.map((invoice) => {
      const element = invoice;
      element.tenant = findTenant(element.payer_id);
      element.property = findProperty(element.payee_id);
      sum += element.total_amount;
      return element;
    });
    if (propertyId) {
      const filtered = mapped.filter((item) => {
        if (item && item.property) {
          return item.property.id === propertyId;
        }
        return undefined;
      });
      setInvoices(filtered);
    } else {
      setInvoices(mapped);
    }
    setIsLoading(false);
    setTransactionList(mapped);
    setTotal(sum);
  }

  return (
    <Master redirectLink="invoices" title="Finance" source="Invoices">
      <div className="row">
        <div className="col-md-12">
          {alert && alert.message && (
            <AlertMessage
              alertType={`alert ${alert.type}`}
              message={alert.message}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <div className="row">
            <Select
              caption="Property Name"
              value={propertyId}
              onChange={(event) => {
                setPropertyId(event.target.value);
              }}
              columnWidth={5}
              inputRef={propertyRef}
            >
              <option value="">Select property name</option>
              {properties.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
            <Select
              caption="Tenant"
              value={tenantId}
              onChange={(event) => {
                setTenantId(event.target.value);
              }}
              columnWidth={5}
              inputRef={tenantRef}
            >
              <option value="">Select tenant</option>
              {tenants.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {`${item.first_name} ${item.last_name} | ${item.other_details ? item.other_details.unit_number : '--' }`}
                  </option>
                );
              })}
            </Select>
            <Select
              caption="Status"
              value={status}
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              columnWidth={2}
            >
              <option value="">Select status</option>
              {PaymentStatus.map((payStatus) => {
                return (
                  <option value={payStatus.id} key={payStatus.id}>
                    {payStatus.name}
                  </option>
                );
              })}
            </Select>
          </div>
          <div className="row">
            <DatePicker
              placeholder="Select date from"
              caption="Date From"
              onChange={(date) => {
                return setLowerDate(date);
              }}
              columnWidth={2}
              selected={lowerDate}
            />
            <DatePicker
              placeholder="Select date to"
              caption="Date To  &nbsp; &nbsp;"
              onChange={(date) => {
                return setUpperDate(date);
              }}
              columnWidth={2}
              selected={upperDate}
            />
            <div className="col-md-3 invoice-button">
              <Button
                caption="Search"
                cssClass="btn-success"
                onClick={onSearchInvoice}
                type="button"
                isBlack={false}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="hpanel">
            <div className="panel-body">
              <span>Search Total</span>
              <br />
              <h1 className="primary-red">{`Ksh ${total.toLocaleString()}`}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <Collapsible columnWidth={12} caption="Invoices" controlId="invoices">
          <InvoiceTable
            isLoading={isLoading}
            error={error}
            invoices={invoices}
          />
        </Collapsible>
      </div>
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

Invoices.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dispatch: PropTypes.func.isRequired,
  alert: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Invoices.defaultProps = {
  user: {},
  alert: {},
};

export default connect(mapStateToProps)(Invoices);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import useAxiosGet from '../../hooks/useAxiosGet';
import { ExportTable } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';

const UnitsTable = ({ userToken, property }) => {
  // state
  const history = useHistory();
  const propertyId = property ? property.id : '';
  const where = [
    { property_id: propertyId, status: 'available' },
    { property_id: propertyId, status: 'occupied' },
  ];

  const url = `${process.env.REACT_APP_API_URL}units?where=${JSON.stringify(
    where,
  )}`;

  const [data, error, isLoading] = useAxiosGet(url, [property]);

  // life-cycle hooks
  useEffect(() => {
    ExportTable('units_table', 'units');
  }, [isLoading]);

  function saveLocalStorage() {
    const obj = {
      id: property.id,
      name: property.name,
    };
    if (typeof Storage !== 'undefined') {
      window.localStorage.setItem('property', JSON.stringify(obj));
      window.localStorage.setItem('source', 'units');
    }
  }

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : error ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      {data && data.response && data.response.length ? (
        <Table tableId="units_table" marginTop={10}>
          <thead>
            <tr>
              <th>No.</th>
              <th>More</th>
              <th>Type</th>
              <th>Rent</th>
              <th>Parking</th>
              <th>Bathrooms</th>
              <th>Bedrooms</th>
              <th>Deposit</th>
              <th>Status</th>
              <th>Total Units</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.response &&
              data.response.map((unitItem, id) => {
                let index = id;
                let deposit = '';
                // eslint-disable-next-line no-unused-vars
                let rentDue = '';
                // eslint-disable-next-line no-unused-vars
                let rentDeadline = '';
                let totalUnits = '';
                if (unitItem.other_details) {
                  const otherDetails = unitItem.other_details;
                  deposit = otherDetails.deposit;
                  rentDue = otherDetails.rent_due;
                  rentDeadline = otherDetails.rent_deadline;
                  totalUnits = otherDetails.number;
                }
                return (
                  <tr key={unitItem.id}>
                    <td>{++index}</td>
                    <td style={{ textAlign: 'center' }}>
                      <button
                        className="btn btn-circle"
                        type="button"
                        onClick={() => {
                          saveLocalStorage();
                          if (typeof Storage !== 'undefined') {
                            window.localStorage.setItem(
                              'unit',
                              JSON.stringify(unitItem),
                            );
                          }
                          history.push({
                            pathname: '/unitprofile',
                            unit: unitItem,
                            property,
                            userToken,
                            source: 'units',
                          });
                        }}
                        style={{
                          backgroundColor: '#333333',
                        }}
                      >
                        <i className="fa fa-info" style={{ color: '#fff' }} />
                      </button>
                    </td>
                    <td>{unitItem.type}</td>
                    <td>{unitItem.rent}</td>
                    <td>{unitItem.parking}</td>
                    <td>{unitItem.bathrooms}</td>
                    <td>{unitItem.bedrooms}</td>
                    <td>{deposit}</td>
                    <td>{unitItem.status}</td>
                    <td>{totalUnits}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : (
        <InfoPanel message="Seems no units found" />
      )}
    </>
  );
};

UnitsTable.propTypes = {
  userToken: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  property: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

UnitsTable.defaultProps = {
  userToken: {},
  property: {},
};

export default UnitsTable;

/**
 * whatever am doing here is bad practice but i have to
 * api is not giving meaningful responses so i have
 * to use what is available for now.
 * please don't @me :(
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import { ExportTable, FormatDate } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import TableButton from '../Buttons/TableButton';
import iziQuestion from '../IziToast/question';
import IziToast from '../IziToast';
import UpdateCharges from './UpdateCharges';
import ModalButton from '../Buttons/ModalButton';

const ChargesTable = ({ reLoadTable, requestConfig, user }) => {
  // state
  const [isReLoading, setIsReloading] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const [properties, setProperties] = useState([]);
  const [charges, setCharges] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // life-cycle hooks
  useEffect(() => {
    if (properties.length > 0) {
      loadCharges(properties);
    }
  }, [reLoadTable, isReLoading]);

  useEffect(() => {
    (async () => {
      try {
        let urlProperty;
        if (user.type === 'admin') {
          urlProperty = `${process.env.REACT_APP_API_URL}properties?where={"status":"active"}&order={"name": "ASC"}`;
        } else {
          urlProperty = `${process.env.REACT_APP_API_URL}properties/byuser?user_id=${user.id}&take=1000&where={"status":"active"}&order={"name": "ASC"}`;
        }
        const {
          data: { response: propertyInfo },
        } = await axios.get(urlProperty, requestConfig);
        setProperties(propertyInfo);
        loadCharges(propertyInfo);
      } catch (error) {
        IziToast('An error occurred');
        setHasError(true);
        setIsLoading(false);
      }
    })();
  }, []);

  /**
   *
   * @param {[]} propertyInfo
   */
  async function loadCharges(propertyInfo) {
    try {
      let url;
      if (user.type === 'admin') {
        url = `${process.env.REACT_APP_API_URL}paymentcodes?take=1000&order={"name":"ASC"}`;
      } else {
        const filtered = propertyInfo.map((item) => {
          return item.id;
        });
        if (filtered.length > 0) {
          url = `${
            process.env.REACT_APP_API_URL
          }paymentcodes?take=1000&where={"property_id":"in::${filtered.join()}"}&order={"name":"ASC"}`;
        } else {
          url = undefined;
        }
      }
      const {
        data: { response },
      } = await axios.get(url, requestConfig);
      if (response) {
        const mapped = response.map((item) => {
          const charge = item;
          charge.property = findProperty(item.property_id);
          return charge;
        });
        setCharges(mapped);
        setIsLoading(false);
      }
    } catch (error) {
      IziToast('An error occurred');
      setHasError(true);
      setIsLoading(false);
    }
    function findProperty(id) {
      return propertyInfo.find((property) => {
        return property.id === id;
      });
    }
  }

  useEffect(() => {
    ExportTable('charges_table', 'charges');
  }, [isLoading]);

  const tableReload = () => {
    setIsReloading(!isReLoading);
  };

  const deleteCharge = (charge) => {
    if (charge && charge.id) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}paymentcodes?id=${charge.id}`,
          requestConfig,
        )
        .then((res) => {
          if (res.data.response) {
            setIsReloading(!isReLoading);
            IziToast('Selected charge code successfully deleted');
          } else {
            IziToast('Selected charge code not deleted');
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response) {
              if (err.response.data) {
                IziToast(err.response.data.error || 'An error occurred');
              }
            }
          } else {
            IziToast('An error occurred');
          }
        });
    } else {
      IziToast('Select charge code to delete');
    }
  };

  const confirmDelete = (charge) => {
    iziQuestion(
      `Delete charge code <i><b>${charge.name}</b></i> completely ?`,
      () => {
        deleteCharge(charge);
      },
      undefined,
    );
  };

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : hasError ? (
    <InfoPanel message="An error occurred" hasError />
  ) : (
    <>
      <UpdateCharges
        requestConfig={requestConfig}
        reLoadTable={tableReload}
        selectedCharge={selectedCharge}
      />
      {charges.length > 0 ? (
        <>
          <Table tableId="charges_table" marginTop={10}>
            <thead>
              <tr>
                <th>No.</th>
                <th>Edit</th>
                <th>Delete</th>
                <th>Property</th>
                <th>Description</th>
                <th>Name</th>
                <th>Code</th>
                <th>Created On</th>
                <th>Updated On</th>
              </tr>
            </thead>
            <tbody>
              {charges.map((charge, id) => {
                let index = id;
                return (
                  <tr key={charge.id}>
                    <td>{++index}</td>
                    <td>
                      <ModalButton
                        caption="Edit"
                        onClick={() => {
                          setSelectedCharge(charge);
                        }}
                        modalId="chargesModal"
                      />
                    </td>
                    <td>
                      <TableButton
                        caption="Delete"
                        onClick={() => {
                          confirmDelete(charge);
                        }}
                        buttonType="danger"
                      />
                    </td>
                    <td>{charge.property ? charge.property.name : '-'}</td>
                    <td>{charge.description}</td>
                    <td>{charge.name}</td>
                    <td>{charge.code}</td>
                    <td>{FormatDate(charge.createdat)}</td>
                    <td>{FormatDate(charge.lastupdated)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <>
          {' '}
          <InfoPanel message="No Data Found" />
        </>
      )}
    </>
  );
};

ChargesTable.propTypes = {
  reLoadTable: PropTypes.bool.isRequired,
  requestConfig: PropTypes.objectOf(PropTypes.object),
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

ChargesTable.defaultProps = {
  requestConfig: {},
};

export default connect((state) => {
  return state;
})(ChargesTable);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import $ from 'jquery';

import { ExportTable, FormatDate } from '../../utils/Functions';
import Table from '../Table';
import InfoPanel from '../Panels/InfoPanel';
import iziQuestion from '../IziToast/question';
import IziToast from '../IziToast';
import UpdateCharges from './UpdateCharges';
import MiniButton from '../Buttons/MiniButton';

const ChargesTable = ({
  isLoading,
  requestConfig,
  user,
  charges,
  hasError,
  onReload,
}) => {
  // state
  const [isReLoading, setIsReloading] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null);

  useEffect(() => {
    ExportTable('charges_table', 'charges');
  }, [isLoading, charges]);

  function onInvoice(charge) {
    let tenantName;
    if (charge.tenant) {
      const { tenant } = charge;
      if (tenant) {
        tenantName = `${tenant.first_name} ${tenant.last_name}`;
      }
    }
    iziQuestion(
      `Invoice tenant <i><b>${tenantName}</b></i> ?`,
      () => {
        invoiceTenant();
      },
      undefined,
    );
    async function invoiceTenant() {
      const changeLog = [
        {
          user: {
            id: user.id,
            type: user.type,
          },
          action: 'Emailing invoice',
          timestamp: Math.round(new Date().getTime() / 1000.0),
        },
      ];
      const chargesObject = {
        id: charge.id,
        send_email: true,
        invoice_type: 'charges',
        changelog: changeLog,
      };
      IziToast('Updating...');
      try {
        const {
          data: { response },
        } = await axios.put(
          `${process.env.REACT_APP_API_URL}invoices`,
          chargesObject,
          requestConfig,
        );
        if (response.id) {
          IziToast('Extra charge(s) successfully invoiced');
        }
        onReload();
      } catch (error) {
        if (error) {
          if (error.response) {
            if (error.response.data) {
              IziToast(error.response.data.error);
            }
          }
        } else {
          IziToast('An error occurred');
        }
      }
    }
  }

  function onCancelInvoice(charge) {
    let tenantName;
    if (charge.tenant) {
      const { tenant } = charge;
      if (tenant) {
        tenantName = `${tenant.first_name} ${tenant.last_name}`;
      }
    }
    iziQuestion(
      `Cancel invoice from <i><b>${tenantName}</b></i> ?`,
      () => {
        cancelInvoice();
      },
      undefined,
    );
    async function cancelInvoice() {
      const changeLog = [
        {
          user: {
            id: user.id,
            type: user.type,
          },
          action: 'Cancelling invoice',
          timestamp: Math.round(new Date().getTime() / 1000.0),
        },
      ];
      const chargesObject = {
        id: charge.id,
        status: 'cancelled',
        invoice_type: 'charges',
        changelog: changeLog,
      };
      IziToast('Cancelling...');
      try {
        const {
          data: { response },
        } = await axios.put(
          `${process.env.REACT_APP_API_URL}invoices`,
          chargesObject,
          requestConfig,
        );
        if (response.id) {
          IziToast('Extra charge(s) successfully Cancelled');
        }
        setIsReloading(!isReLoading);
      } catch (error) {
        if (error) {
          if (error.response) {
            if (error.response.data) {
              IziToast(error.response.data.error);
            }
          }
        } else {
          IziToast('An error occurred');
        }
      }
    }
  }

  return isLoading ? (
    <InfoPanel message="Loading..." />
  ) : hasError ? (
    <InfoPanel message="An error occurred" hasError />
  ) : charges.length > 0 ? (
    <>
      <UpdateCharges
        requestConfig={requestConfig}
        reLoadTable={onReload}
        selectedCharge={selectedCharge}
      />
      <Table tableId="charges_table" marginTop={10}>
        <thead>
          <tr>
            <th>No.</th>
            <th>Date</th>
            <th>Property</th>
            <th>Hse. Unit</th>
            <th>Tenant</th>
            {/* <th>Payment Code</th> */}
            <th>Total Penalties</th>
            <th>Balance</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {charges.map((charge, id) => {
            let index = id;
            let propertyName = '-';
            let unitNumber = '-';
            let tenantName = '-';
            // let paymentCode = '-';
            if (charge.property) {
              const { property } = charge;
              if (property) {
                propertyName = property.name;
              }
            }
            if (charge.tenant) {
              const { tenant } = charge;
              if (tenant) {
                tenantName = `${tenant.first_name} ${tenant.last_name}`;
                if (tenant.other_details) {
                  unitNumber = tenant.other_details.unit_number || '';
                }
              }
            }
            if (charge.reference_data) {
              const { reference_data: referenceData } = charge;
              if (referenceData.payment_code) {
                // paymentCode = referenceData.payment_code;
              }
            }
            return (
              <tr key={charge.id}>
                <td>{++index}</td>
                <td>{FormatDate(charge.invoice_date)}</td>
                <td>{propertyName}</td>
                <td>{unitNumber}</td>
                <td>{tenantName}</td>
                {/* <td>{paymentCode}</td> */}
                <td>{charge.total_amount}</td>
                <td>{charge.balance}</td>
                <td>{charge.status}</td>
                <td>
                  <MiniButton
                    caption="Invoice"
                    onClick={() => {
                      onInvoice(charge);
                    }}
                    buttonType="danger"
                  />
                  <MiniButton
                    caption="Edit"
                    onClick={() => {
                      setSelectedCharge(charge);
                      $('#chargesModal').modal('show');
                    }}
                    buttonType="info"
                  />
                  <MiniButton
                    caption="Cancel"
                    onClick={() => {
                      onCancelInvoice(charge);
                    }}
                    buttonType=""
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  ) : (
    <InfoPanel message="Seems no transaction found" />
  );
};

ChargesTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  requestConfig: PropTypes.objectOf(PropTypes.object),
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onReload: PropTypes.func.isRequired,
  charges: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ChargesTable.defaultProps = {
  requestConfig: {},
};

export default connect((state) => {
  return state;
})(ChargesTable);

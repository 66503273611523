import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Tenancy = ({ caption }) => {
  return (
    <div className="col-lg-3">
      <div className="hpanel">
        <div className="panel-heading">&nbsp;</div>
        <div className="panel-body list">
          <div className="table-responsive project-list">
            <div className="panel-body h-200">
              <h1 className="tile-amount">{caption}</h1>
              <div className="card-row row">
                <h1 className="pull-left card-body-text">Tenants</h1>
                <div className="pull-right">
                  <i className="pe-7s-users fa-4x" />
                </div>
              </div>
              <div className="row card-row card-footer">
                <span className="stats-tile-caption">Incoming vs Outgoing</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Tenancy.propTypes = {
  caption: PropTypes.string.isRequired,
};

export default Tenancy;

import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({
  name,
  value,
  onChange,
  type,
  placeholder,
  caption,
  columnWidth,
  isWrapped,
  inputRef,
  className,
  readOnly = false,
}) => {
  const colWidth = `col-md-${columnWidth}`;
  const Input = (
    <div className="form-group">
      <label className="control-label" htmlFor={name}>
        {caption}
      </label>
      <input
        autoComplete="new-password"
        type={type}
        placeholder={placeholder}
        title={caption}
        onChange={onChange}
        value={value}
        name={name}
        ref={inputRef}
        readOnly={readOnly}
        className={`form-control ${className}`}
      />
    </div>
  );

  if (isWrapped) {
    return <div className={colWidth}>{Input}</div>;
  }
  return Input;
};

TextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  caption: PropTypes.string,
  columnWidth: PropTypes.number.isRequired,
  isWrapped: PropTypes.bool,
  inputRef: PropTypes.objectOf(PropTypes.object),
  className: PropTypes.string,
  readOnly: PropTypes.bool,
};

TextInput.defaultProps = {
  placeholder: '',
  caption: '',
  isWrapped: true,
  inputRef: null,
  className: '',
  readOnly: false,
  name: '',
};

export default TextInput;

import React from 'react';
import PropTypes from 'prop-types';

const ModalButton = ({ caption, onClick, buttonType, modalId }) => {
  let btnStyle;
  let iconStyle;
  if (buttonType === 'danger') {
    btnStyle = 'btn btn-danger btn-sm';
    iconStyle = 'fa fa-trash-o';
  } else {
    btnStyle = 'btn btn-info btn-sm';
    iconStyle = 'fa fa-paste';
  }
  return (
    <button
      className={btnStyle}
      style={{ backgroundColor: buttonType === 'danger' ? '' : '#333333' }}
      onClick={onClick}
      type="button"
      data-toggle="modal"
      data-backdrop="static"
      data-target={`#${modalId}`}
    >
      <i className={iconStyle} />
      {`  ${caption}`}
    </button>
  );
};

ModalButton.propTypes = {
  caption: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonType: PropTypes.string,
  modalId: PropTypes.string.isRequired,
};

ModalButton.defaultProps = {
  buttonType: 'info',
};

export default ModalButton;

import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const RadioButton = ({ caption, checked, onClick }) => {
  return (
    /*eslint-disable */
    <label className="container">
      {caption}
      <input type="radio" checked={checked} onChange={onClick} name="radio" />
      <span className="checkmark" />
    </label>
  );
};

RadioButton.propTypes = {
  caption: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

RadioButton.defaultProps = {
  caption: '',
  checked: false,
  onClick: undefined,
};

export default RadioButton;

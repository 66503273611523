/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const ButtonTile = ({ content, onClick, isActive, controlId, controlRef }) => {
  const divStyle = isActive ? 'button-tile button-tile-active' : 'button-tile';
  return (
    <div onClick={onClick} id={controlId} className={divStyle} ref={controlRef}>
      <span>{content}</span>
    </div>
  );
};

ButtonTile.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  content: PropTypes.string,
  controlId: PropTypes.string,
  controlRef: PropTypes.objectOf(PropTypes.object),
};

ButtonTile.defaultProps = {
  onClick: null,
  isActive: false,
  content: '',
  controlId: '',
  controlRef: null,
};
export default ButtonTile;

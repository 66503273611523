import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import $ from "jquery";

import IziToast from "../IziToast";
import Master from "../Master";
import TextInput from "../TextInput";
import CheckBox from "../CheckBox";
import Platforms from "../../data/Platforms";
import BankCodes from "../../data/BankCodes";
import Select from "../Select";
import Button from "../Buttons";
import PropertyTable from "./PropertyTable";
import Currency from "../../data/Currency";
import FilePreview from "../FileUploader/FilePreview";

const NewProperty = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  };
  const history = useHistory();
  // refs
  const landlordRef = React.useRef(null);
  const bankNameRef = React.useRef(null);
  const bankCodeRef = React.useRef(null);
  // state
  const [bankAccountName, setBankAccountName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankTelephoneNumber, setBankTelephoneNumber] = useState("");
  const [isEquityAccount, setIsEquityAccount] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [propertyCode, setPropertyCode] = useState("");
  const [description, setDescription] = useState("");
  const [paymentPlatform, setPaymentPlatform] = useState(Platforms[0].id);
  const [locationAddress, setLocationAddress] = useState("");
  const [propertyUnits, setPropertyUnits] = useState("");
  const [currency, setCurrency] = useState(Currency[0].id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentFile, setCurrentFile] = useState(null);
  const [landLords, setLandLords] = useState([]);
  const [landLordId, setLandLordId] = useState("");

  useEffect(() => {
    if (landLords.length > 0) setLandLordId(landLords[0].id);
  }, [landLords]);

  useEffect(() => {
    (async () => {
      try {
        let url;
        switch (user.type) {
          case "admin":
            url = `${process.env.REACT_APP_API_URL}users?type=landlord&status=active`;
            break;
          case "landlord":
          case "agent":
            url = `${process.env.REACT_APP_API_URL}user`;
            break;
          default:
            break;
        }
        const {
          data: { response },
        } = await axios.get(url, requestConfig);
        if (user.type === "admin") {
          const urlAgent = `${process.env.REACT_APP_API_URL}users?type=agent&status=active`;
          const {
            data: { response: agents },
          } = await axios.get(urlAgent, requestConfig);
          const concatinated = agents.concat(response);
          setLandLords(concatinated);
        }
        if (user.type === "landlord" || user.type === "agent") {
          setLandLords([response]);
        }
        $(landlordRef.current)
          .select2()
          .on("change", (event) => {
            const { val: agentId } = event;
            setLandLordId(agentId);
          });
        $(bankNameRef.current)
          .select2()
          .on("change", (event) => {
            const { val: bankName } = event;
            setBankAccountName(bankName);
          });
        $(bankCodeRef.current)
          .select2()
          .on("change", (event) => {
            const { val: bankCode } = event;
            setBankCode(bankCode);
          });
      } catch (error) {
        //
      }
    })();
  }, []);

  const saveProperty = async () => {
    setIsSubmitting(true);
    let isValid = false;
    let message;
    if (
      propertyName &&
      propertyCode &&
      description &&
      propertyUnits &&
      locationAddress &&
      currency &&
      accountNumber &&
      bankAccountName &&
      bankAccountNumber &&
      bankCode &&
      bankTelephoneNumber &&
      landLordId
    ) {
      isValid = true;
    } else {
      isValid = false;
      message = "Enter the red marked fields";
      IziToast(message);
    }
    if (isValid) {
      const paymentType = "rent";
      const tenantPaymentInfo = [
        {
          payment_info: { account: accountNumber, platform: paymentPlatform },
          payment_type: paymentType,
        },
      ];
      const landlordPaymentInfo = [
        {
          payment_info: {
            account: bankAccountNumber,
            platform: 'pesalink',
            bank_name: bankAccountName,
            code: bankCode,
            phone: bankTelephoneNumber,
            is_equity_account: isEquityAccount,
          },
          payment_type: paymentType,
        },
      ];
      const formData = new FormData();
      formData.append("user_id", landLordId);
      formData.append("name", propertyName);
      formData.append("property_code", propertyCode);
      formData.append("description", description);
      formData.append("address", locationAddress);
      formData.append("units", propertyUnits);
      formData.append("other_details", `{ "currency": "${currency}" }`);
      formData.append("tenant_payment_details", JSON.stringify(tenantPaymentInfo));
      formData.append("landlord_payment_details", JSON.stringify(landlordPaymentInfo));
      // attach image if any
      if (currentFile) {
        formData.append(currentFile.name, currentFile, currentFile.name);
      }
      const formConfig = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      IziToast("Saving...");
      try {
        const {
          data: { response },
        } = await axios.post(
          `${process.env.REACT_APP_API_URL}properties`,
          formData,
          formConfig
        );
        if (response.propertySaved.id) {
          IziToast("Property successfully saved");
          resetFields();
        }
        setIsLoading(!isLoading);
      } catch (error) {
        if (error) {
          if (error.response) {
            if (error.response.data) {
              IziToast(error.response.data.error);
            }
          } else {
            IziToast(error.message);
          }
        } else {
          IziToast("An error occurred");
        }
      }
    }
  };

  const resetFields = () => {
    setBankAccountName("");
    setBankAccountNumber("");
    setBankCode("");
    setBankTelephoneNumber("");
    setIsEquityAccount(false);
    setAccountNumber("");
    setPropertyName("");
    setPropertyCode("");
    setDescription("");
    setPaymentPlatform(Platforms[0].id);
    setLocationAddress("");
    setPropertyUnits("");
    setCurrency(Currency[0].id);
    setIsSubmitting(false);
    setCurrentFile(null);
    setLandLordId("");
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const { type /* name */ /* size */ } = file;
      const validTypes = ["image/jpeg", "image/png"];
      if (validTypes.includes(type)) {
        /**
         * TODO : validate duplicate upload
         * message : 'Looks like you have already uploaded this image'
         */
        setCurrentFile(file);
      } else {
        IziToast("Only .jpeg and .png files allowed");
      }
    }
  };

  return (
    <Master
      redirectLink="newproperty"
      title="Property Management"
      source="New Property"
    >
      <div className="col-xs-3 bm-responsive-div">
        <FilePreview
          currentFile={currentFile}
          onChange={onFileChange}
          inputId="imgProperty"
          onFileRemove={() => {
            setCurrentFile(null);
          }}
          columnWidth={12}
        />
      </div>
      <div className="col-xs-9 bm-responsive-div">
        <div className="row">
          <Select
            caption="Landlord/Agent"
            value={landLordId}
            onChange={(event) => {
              setLandLordId(event.target.value);
            }}
            columnWidth={6}
            className={isSubmitting && !landLordId ? "error" : ""}
            inputRef={landlordRef}
          >
            {user.type === "admin" && (
              <option value="">Select property landlord/agent</option>
            )}
            {landLords.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {`${item.email} | ${item.first_name} ${item.last_name}`}
                </option>
              );
            })}
          </Select>
          <TextInput
            type="text"
            placeholder="Enter property name"
            caption="Property Name"
            onChange={(event) => {
              setPropertyName(event.target.value);
            }}
            value={propertyName}
            columnWidth={6}
            className={isSubmitting && !propertyName ? "error" : ""}
          />
        </div>
        <div className="row">
          <TextInput
            type="text"
            placeholder="Enter property code"
            caption="Property Code"
            onChange={(event) => {
              setPropertyCode(event.target.value);
            }}
            value={propertyCode}
            columnWidth={6}
            className={isSubmitting && !propertyCode ? "error" : ""}
          />
        </div>
        <div className="row">
          <TextInput
            type="text"
            placeholder="Enter property description"
            caption="Property Description"
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            value={description}
            columnWidth={12}
            className={isSubmitting && !description ? "error" : ""}
          />
        </div>
        <div className="row">
          <TextInput
            type="text"
            placeholder="Enter area, town"
            caption="Area, Town"
            onChange={(event) => {
              setLocationAddress(event.target.value);
            }}
            value={locationAddress}
            columnWidth={9}
            className={isSubmitting && !locationAddress ? "error" : ""}
          />
          <TextInput
            type="text"
            placeholder="Enter units"
            caption="Units"
            onChange={(e) => {
              const value = /^[0-9\b]+$/;
              // allows number only
              if (e.target.value === "" || value.test(e.target.value)) {
                setPropertyUnits(e.target.value);
              }
            }}
            value={propertyUnits}
            name="phone"
            columnWidth={3}
            className={isSubmitting && !propertyUnits ? "error" : ""}
          />
        </div>
        <div className="row">
          <Select
            caption="Currency"
            value={currency}
            onChange={(event) => {
              setCurrency(event.target.value);
            }}
            columnWidth={3}
            className={isSubmitting && !currency ? "error" : ""}
          >
            {Currency.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Select>
          <Select
            caption="Payment Platform"
            value={paymentPlatform}
            onChange={(event) => {
              setPaymentPlatform(event.target.value);
            }}
            columnWidth={3}
            className={isSubmitting && !paymentPlatform ? "error" : ""}
          >
            {Platforms.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Select>
          <TextInput
            type="text"
            placeholder="Enter paybill number"
            caption="Paybill Number"
            onChange={(e) => {
              const value = /^[0-9\b]+$/;
              // allows number only
              if (e.target.value === "" || value.test(e.target.value)) {
                setAccountNumber(e.target.value);
              }
            }}
            value={accountNumber}
            columnWidth={6}
            className={isSubmitting && !accountNumber ? "error" : ""}
          />
        </div>
        <div className="row">
          <Select
            caption="Enter bank account name"
            value={bankAccountName}
            onChange={(event) => {
              setBankAccountName(event.target.value);
            }}
            columnWidth={6}
            className={isSubmitting && !bankCode ? "error" : ""}
            inputRef={bankNameRef}
          >
            <option value="">Select bank account name</option>
            {BankCodes.map((item) => {
              return (
                <option value={item.name} key={item.name}>
                  {item.name}
                </option>
              );
            })}
          </Select>
          <Select
            caption="Bank Code"
            value={bankCode}
            onChange={(event) => {
              setBankCode(event.target.value);
            }}
            columnWidth={6}
            className={isSubmitting && !bankCode ? "error" : ""}
            inputRef={bankCodeRef}
          >
            <option value="">Select bank code</option>
            {BankCodes.map((item) => {
              return (
                <option value={item.code} key={item.code}>
                  { `${item.code} - ${item.name}` }
                </option>
              );
            })}
          </Select>
          <TextInput
            type="text"
            placeholder="Enter bank account number"
            caption="Bank Account Number"
            onChange={(e) => {
              const value = /^[0-9\b]+$/;
              // allows number only
              if (e.target.value === "" || value.test(e.target.value)) {
                setBankAccountNumber(e.target.value);
              }
            }}
            value={bankAccountNumber}
            columnWidth={6}
            className={isSubmitting && !bankAccountNumber ? "error" : ""}
          />
          <TextInput
            type="text"
            placeholder="Enter telephone number linked to account"
            caption="Linked Telephone Number"
            onChange={(e) => {
              setBankTelephoneNumber(e.target.value);
            }}
            value={bankTelephoneNumber}
            columnWidth={6}
            className={isSubmitting && !bankTelephoneNumber ? "error" : ""}
          />
          <div className="col-md-6">
            <label className="control-label" htmlFor="">
              Is it an Equity Bank account?
              <div
                className="row"
                style={{ marginLeft: "3px", paddingTop: "10px" }}
              >
                <CheckBox
                  caption="YES"
                  checked={isEquityAccount}
                  onClick={() => {
                    setIsEquityAccount(true);
                  }}
                />
                <CheckBox
                  caption="NO"
                  checked={!isEquityAccount}
                  onClick={() => {
                    setIsEquityAccount(!isEquityAccount);
                  }}
                />
              </div>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="right form-group">
              <Button
                caption="<< Back To Properties"
                cssClass="btn-success"
                onClick={() => {
                  history.push("/property");
                }}
                type="button"
                isBlack
              />
              <Button
                caption="Save Property"
                cssClass="btn-success"
                onClick={saveProperty}
                type="button"
                isBlack={false}
              />
            </div>
          </div>
        </div>
      </div>

      <PropertyTable
        reLoadTable={isLoading}
        userToken={userToken}
        userInfo={user}
      />
    </Master>
  );
};

const mapStateToProps = (state) => {
  return state;
};

NewProperty.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

NewProperty.defaultProps = {
  user: {},
};

export default connect(mapStateToProps)(NewProperty);
